import React, { useEffect, useRef, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import { uploadIcon } from "../assets/img";
import FormComp from "../components/AddNewProductComps/FormComp";
import ImageUplodeComp from "../components/AddNewProductComps/ImageUplodeComp";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useLazyAttributeListQuery,
  useLazyCategoriesQuery,
  useLazyCategoryActiveViewQuery,
  useLazyCategoryViewQuery,
  useLazyColorsListAllQuery,
  useLazyDecorativeListQuery,
  useLazyHsnAllListQuery,
  useLazyProductViewQuery,
  useLazyTagsListQuery,
  useProductCreateMutation,
  useProductGenerateVarientMutation,
  useProductStoreVarientMutation,
  useProductUpdateMutation,
  useProductUpdateVarientMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const AddNewProductScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selColors, setSelColors] = useState([]);
  const vendor = location?.state?.type;
  const vendor2 = location?.state?.path;
  const vendor3 = location?.state?.place;

  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [decorativeList, setDecorativeList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [decorativeID, setDecorativeID] = useState([]);
  const [decorativeName, setDecorativeName] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [country, setCountry] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [hsnCodeErr, setHsnCodeErr] = useState(false);
  const [hsnName, setHsnName] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [tagsEventList, setEventTagsList] = useState([]);
  const [tagsIconList, setIconTagsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsName, setTagsName] = useState([]);
  const [tagsEvent, setTagsEvent] = useState([]);
  const [tagsEventName, setTagsEventName] = useState([]);
  const [tagsIcon, setTagsIcon] = useState([]);
  const [tagsIconName, setTagsIconName] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [colorsID, setColorsID] = useState([]);
  const [colorsName, setColorsName] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [varientShow, setVarientShow] = useState(false);
  const [sizes, setSizes] = useState([{ value: "" }]);
  const [materials, setMeterials] = useState([{ value: "" }]);
  const [styles, setStyles] = useState([{ value: "" }]);
  const [allVarients, setAllVarients] = useState([]);
  const [catogoryName, setCatogoryName] = useState({});
  const [primaryCategoryId, setPrimaryCategoryId] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [primaryCategoryIdErr, setPrimaryCategoryIdErr] = useState(false);
  const [secondaryCategoryIdErr, setSecondaryCategoryIdErr] = useState(false);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [productInformationErr, setProductInformationErr] = useState(false);
  const [smallimage, setSmallImage] = useState([]);
  const [smallMockimage, setSmallMockImage] = useState([]);
  const [pointsArr, setPointsArr] = useState([{ value: "" }]);

  const [categoryID, setCategoryID] = useState("");
  const [catogory, setCatogory] = useState("");
  const [name, setName] = useState("");
  const [mrp, setMrp] = useState("");
  const [decorationMethod, setDecorationMethod] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPackageSize, setProductPackageSize] = useState("");
  const [gendor, setGendor] = useState("");
  const [gendorErr, setGendorErr] = useState("");
  const [otherProInfo, setOtherProInfo] = useState("");
  const [productPackageSizeErr, setProductPackageSizeErr] = useState(false);
  const [productDescriptionErr, setProductDescriptionErr] = useState(false);
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [decorationMethodErr, setDecorationMethodErr] = useState(false);
  const [mrpErr, setMrpErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [tagErr, setTagErr] = useState(false);
  const [tagVal, setTagVal] = useState("");
  const [tagEventErr, setTagEventErr] = useState(false);
  const [tagEventVal, setTagEventVal] = useState("");
  const [tagIconErr, setTagIconErr] = useState(false);
  const [tagIconVal, setTagIconVal] = useState("");
  const [productImg, setProductImg] = useState("");
  const [productImgErr, setProductImgErr] = useState(false);
  const [morkupImg, setMorkupImg] = useState("");
  const [morkupImgErr, setMorkupImgErr] = useState(false);
  const [colorsImg, setColorsImg] = useState("");
  const [colorImgErr, setColorImgErr] = useState(false);
  const [addVarientErr, setAddVarientErr] = useState(false);
  const [addVarients, setAddVarients] = useState("");
  const [colorVarientErr, setColorVarientErr] = useState(false);
  const [sizeVarientErr, setSizeVarientErr] = useState(false);
  const [styleVarientErr, setStyleVarientErr] = useState(false);
  const [matVarientErr, setMatVarientErr] = useState(false);
  const [colorVarient, setColorVarient] = useState("");
  const [sizeVarient, setSizeVarient] = useState("");
  const [styleVarient, setStyleVarient] = useState("");
  const [matVarient, setMatVarient] = useState("");
  const [btn, setBtn] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(0);
  const [listAttributes, setListAttributes] = useState([]);
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [attributesName, setAttributesName] = useState(["Color"]);

  const [submitData, setSubmitData] = useState({});
  const [viewData, setViewData] = useState({});
  const [varientsList, setVarientList] = useState([]);

  // RTK QUERY
  const [listCategory] = useLazyCategoriesQuery();
  const [viewCategory] = useLazyCategoryActiveViewQuery();
  const [decorativeListApi] = useLazyDecorativeListQuery();
  const [hsnListApi] = useLazyHsnAllListQuery();
  const [tagsListApi] = useLazyTagsListQuery();
  const [colorsListApi] = useLazyColorsListAllQuery();
  const [attributesApi] = useLazyAttributeListQuery();

  const [productCreateApi] = useProductCreateMutation();
  const [productUpdateApi] = useProductUpdateMutation();
  const [productViewApi] = useLazyProductViewQuery();
  const [productGenerateVarientApi] = useProductGenerateVarientMutation();
  const [productStoreVarientApi] = useProductStoreVarientMutation();
  const [productUpdateVarientApi] = useProductUpdateVarientMutation();

  // GET PARENT CATEGORY LIST
  const getParentCategoryList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = res?.categories?.filter((i) => i?.status == 1);
          setParentCategoryList(temp);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  // GET DECORATIVE METHOD
  const getDecorativeList = () => {
    decorativeListApi()
      .unwrap()
      .then((res) => {
        setDecorativeList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET HSN LIST
  const getHsnList = () => {
    hsnListApi()
      .unwrap()
      .then((res) => {
        setHsnList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET TAG LIST
  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setTagsList(res?.normalTag);
        setEventTagsList(res?.eventTag);
        setIconTagsList(res?.iconTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET COLORS
  const getColors = () => {
    colorsListApi()
      .unwrap()
      .then((res) => {
        let temp = [];
        res?.data?.map((item, ind) => {
          temp.push({
            ...item,
            image: "",
          });
        });
        setColorsList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAttributes = () => {
    attributesApi()
      .unwrap()
      .then((res) => {
        setAttributesList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getParentCategoryList();
    getDecorativeList();
    getHsnList();
    getTagsList();
    getColors();
    getAttributes();
  }, []);

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, ind, index, data) => {
    if (type == "category") {
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      setSubCategory(id);
      onSubCatSelect(id, ind, index, data);
    }
  };

  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }
  };

  // VIEW CHID CATEGORY API
  const getView = (id, ind, index, type, data) => {
    viewCategory(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, ind) => {
    setSearchSubValueCategory((val) => ({ ...val, [ind]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[ind]", temp[ind], subCategoryLists[ind]);
    temp[ind] = subCategoryLists[ind];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    getCategoryId(subItem?.id, "sub_category", indx, ind, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));
    if (ind == 0) {
      setSecondaryCategoryId(subItem?.id);
    } else {
      setCategoryID(subItem?.id);
    }

    setProductSuplierCode(subItem?.code);
  };

  // VARIENT COMBINATION STATUC CHANGE
  const deleteVarient = (ind, index, status, type) => {
    let temp = [...varientsList];
    if (type == "parent") {
      // FOR PARENT
      let change_status;
      if (status == 1) {
        change_status = 0;
      } else {
        change_status = 1;
      }

      let tempx = temp[ind].childs;

      for (let i = 0; i < tempx?.length; i++) {
        tempx[i] = {
          ...tempx[i],
          status: change_status,
        };
      }

      temp[ind] = {
        ...temp[ind],
        status: change_status,
        childs: tempx,
      };
      setVarientList(temp);
    } else {
      // FOR CHILD
      let tempx = temp[ind].childs;
      let tem = [...tempx];

      let change_status;
      if (status == 1) {
        change_status = 0;
      } else {
        change_status = 1;
      }

      let obj = {
        ...tem[index],
        status: change_status,
      };
      tem[index] = obj;
      tempx = tem;
      temp[ind]["childs"] = tempx;
      setVarientList(temp);
    }
  };

  // PRODUCT CREATE
  const submitHandle = () => {
    let check;

    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }
    // console.log("check", check);

    if (
      primaryCategoryId?.length == 0 ||
      secondaryCategoryId?.length == 0 ||
      categoryID?.length == 0 ||
      name?.length == 0 ||
      gendor?.length == 0 ||
      country?.length == 0 ||
      productPackageSize?.length == 0 ||
      mrp?.length == 0 ||
      hsnCode?.length == 0 ||
      productDescription?.length == 0 ||
      tagVal?.length == 0 ||
      tagEventVal?.length == 0 ||
      tagIconVal?.length == 0 ||
      decorationMethod?.length == 0 ||
      productImg?.length == 0 ||
      // ||
      // morkupImg?.length == 0
      // ||
      // otherProInfo?.length == 0
      // ||
      check == true
    ) {
      setProductInformationErr(true);
      setPrimaryCategoryIdErr(true);
      setSecondaryCategoryIdErr(true);
      setGendorErr(true);
      setCategoryIDErr(true);
      setNameErr(true);
      setCountryErr(true);
      setProductDescriptionErr(true);
      setProductPackageSizeErr(true);
      setMrpErr(true);
      setHsnCodeErr(true);
      setTagErr(true);
      setTagEventErr(true);
      setTagIconErr(true);
      setDecorationMethodErr(true);
      setProductImgErr(true);
      // setMorkupImgErr(true);
      // setOtherProInfoErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("primary_category_id", primaryCategoryId);
      formdata.append("secondary_category_id", secondaryCategoryId);
      formdata.append("category_id", categoryID);
      formdata.append("name", name);
      formdata.append("product_for", gendor);
      formdata.append("country_origin", country);
      formdata.append("package_size", productPackageSize);
      formdata.append("mrp", mrp);
      formdata.append("hsn_id", hsnCode);
      formdata.append("description", productDescription);

      let tag_temp = [...tags, ...tagsEvent, ...tagsIcon];

      tag_temp?.map((item, ind) => {
        formdata.append(`tag_ids[${ind}]`, item);
      });

      decorativeID?.map((item, ind) => {
        formdata.append(`decoration_ids[${ind}]`, item);
      });

      if (productInformation?.length > 0) {
        productInformation?.map((item, ind) => {
          formdata.append(`attribute_varients[${ind}][attribute_id]`, item?.id);
          formdata.append(`attribute_varients[${ind}][value]`, item?.value);
        });
      }

      let smallimageArr = [];

      smallimage?.map((item, ind) => {
        if (item?.image?.name) {
          smallimageArr.push(item);
        }
      });

      if (smallimageArr?.length > 0) {
        smallimageArr?.map((item, ind) => {
          formdata.append(`product_images[${ind}][image]`, item?.image);
          formdata.append(`product_images[${ind}][description]`, "");
          formdata.append(
            `product_images[${ind}][is_default]`,
            item?.is_default
          );
        });
      }

      let smallMockimageArr = [];

      smallMockimage?.map((item, ind) => {
        if (item?.image?.name) {
          smallMockimageArr.push(item);
        }
      });

      if (smallMockimageArr?.length > 0) {
        smallMockimageArr?.map((item, ind) => {
          formdata.append(`morkup_images[${ind}][image]`, item?.image);
          formdata.append(`morkup_images[${ind}][description]`, "");
          formdata.append(
            `morkup_images[${ind}][is_default]`,
            item?.is_default
          );
        });
      }

      let pointsArr_ = [];

      pointsArr?.map((item, ind) => {
        if (item?.value) {
          pointsArr_.push(item);
        }
      });

      pointsArr_?.map((item, ind) => {
        formdata.append(`description_points[${ind}]`, item?.value);
      });

      dispatch(saveLoader(true));
      setBtn(true);
      if (viewData?.id) {
        productUpdateApi({ payload: formdata, id: viewData?.id })
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            setSubmitBtn(1);
            setSubmitData(res?.data);
            // console.log("res", res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      } else {
        productCreateApi(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            setSubmitBtn(1);
            setSubmitData(res?.data);
            toast.success(res?.message);
            // console.log("res", res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const [stylesErr, setStylesErr] = useState(false);
  const [materialsErr, setMaterialsErr] = useState(false);
  const [sizesErr, setSizesErr] = useState(false);
  const [colosErr, setColosErr] = useState(false);
  const [varientsCheck, setVarientsCheck] = useState("");

  // COMBINATE GENERATE
  const varientCombination = () => {
    let check;
    let sizeCheck;
    let colorCheck;
    let styleCheck;
    let materialCheck;

    sizes?.map((item, ind) => {
      sizeCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    colorsID?.map((item, ind) => {
      colorCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    materials?.map((item, ind) => {
      materialCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    styles?.map((item, ind) => {
      styleCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    colorsData?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    if (
      // (!attributesName?.includes("Varient") && varientsCheck?.length == 0) ||
      // (attributesName?.includes("Color") && colorVarient?.length == 0) ||
      colorVarient?.length == 0 ||
      // (attributesName?.includes("Size") && sizeVarient?.length == 0) ||
      // (attributesName?.includes("Materials") && matVarient?.length == 0) ||
      // (attributesName?.includes("Style") && styleVarient?.length == 0) ||
      (attributesName?.includes("Size") && sizeCheck == true) ||
      (attributesName?.includes("Materials") && materialCheck == true) ||
      // (attributesName?.includes("Color") && colorCheck == true) ||
      (attributesName?.includes("Style") && styleCheck == true) ||
      check == true
    ) {
      // !attributesName?.includes("Varient") && setAddVarientErr(true);
      setColorVarientErr(true);
      // attributesName?.includes("Color") && setColorVarientErr(true);
      // attributesName?.includes("Size") && setSizeVarientErr(true);
      // attributesName?.includes("Materials") && setMatVarientErr(true);
      // attributesName?.includes("Style") && setStyleVarientErr(true);
      attributesName?.includes("Size") && setSizesErr(true);
      attributesName?.includes("Materials") && setMaterialsErr(true);
      // attributesName?.includes("Color") && setColosErr(true);
      attributesName?.includes("Style") && setStylesErr(true);
      setColorImgErr(true);
    } else {
      let formdata = new FormData();
      let emptyArr = [];
      if (colorsID?.length > 0) {
        colorsID?.map((item, ind) => {
          formdata.append(`master_varients[colors][${ind}]`, item?.id);
        });
      } else {
        formdata.append(`master_varients[colors][${0}]`, []);
      }

      if (!sizeCheck && sizes?.length > 0) {
        sizes?.map((item, ind) => {
          formdata.append(`master_varients[sizes][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[sizes][${0}]`, []);
      }

      if (!materialCheck && materials?.length > 0) {
        materials?.map((item, ind) => {
          formdata.append(`master_varients[materials][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[materials][${0}]`, []);
      }

      if (!styleCheck && styles?.length > 0) {
        styles?.map((item, ind) => {
          formdata.append(`master_varients[styles][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[styles][${0}]`, []);
      }

      formdata.append("product_id", submitData?.id ? submitData?.id : " ");

      dispatch(saveLoader(true));
      setBtn(true);
      productGenerateVarientApi(formdata)
        .unwrap()
        .then((res) => {
          let attribute_variations = res?.attribute_variations;
          let temp = [];
          let arr = [];

          for (let n in attribute_variations) {
            temp.push(n);
          }
          temp.map((item, ind) => {
            let obj = {
              [item]: item,
              parent_name: item,
              childs: attribute_variations[item],
            };

            arr.push(obj);

            // console.log("obj", obj);
          });

          // console.log("arr", arr);
          let childs = [];
          arr.map((item, ind) => {
            childs.push({
              ...item,
              status: 1,
              childs: item?.childs?.map((el) => {
                var o = Object.assign({}, el);
                o.status = 1;
                return o;
              }),
            });
          });

          // var arrOfObj = [{name: 'eve'},{name:'john'},{name:'jane'}];
          // const result = arrOfObj.map((name) => {
          //     name["isActive"] = true;
          //     return name;
          // });
          // console.log(result);

          console.log("productGenerateVarientApi", childs);

          setVarientList(childs);
          setVarientShow(true);
          setSubmitBtn(2);
          dispatch(saveLoader(false));
          setBtn(false);
          toast.success(res?.message);
          // console.log("res", res);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // console.log("colorsData", colorsData);

  const colorRep = useRef();

  // COMBINATION STORE
  const submitVarient = () => {
    let check;
    colorsData?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    if (check) {
      setColorImgErr(true);
      colorRep.current.focus();
    } else {
      let formdata = new FormData();

      let emptyArr = [];
      if (colorsID?.length > 0) {
        colorsID?.map((item, ind) => {
          formdata.append(`master_varients[colors][${ind}]`, item?.id);
        });
      } else {
        formdata.append(`master_varients[colors][${0}]`, emptyArr);
      }

      if (sizes?.length > 0) {
        sizes?.map((item, ind) => {
          formdata.append(`master_varients[sizes][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[sizes][${0}]`, emptyArr);
      }

      if (materials?.length > 0) {
        materials?.map((item, ind) => {
          formdata.append(`master_varients[materials][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[materials][${0}]`, emptyArr);
      }

      if (styles?.length > 0) {
        styles?.map((item, ind) => {
          formdata.append(`master_varients[styles][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[styles][${0}]`, emptyArr);
      }

      let colorsDataArr = [];

      colorsData?.map((item, ind) => {
        if (item?.image?.name) {
          colorsDataArr.push(item);
        }
      });

      if (colorsDataArr?.length > 0) {
        colorsDataArr?.map((item, ind) => {
          formdata.append(
            `color_images[${ind}][color_id]`,
            item?.color_id ? item?.color_id : item?.id
          );
          formdata.append(`color_images[${ind}][image]`, item?.image);
          formdata.append(`color_images[${ind}][description]`, "");
        });
      }

      // console.log("colorsDataArr", colorsDataArr);

      varientsList?.map((item, ind) => {
        formdata.append(`product_skus[${ind}][parent_sku]`, item?.parent_name);
        formdata.append(`product_skus[${ind}][status]`, item?.status);
        item?.childs?.map((child, inx) => {
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][color_id]`,
            child?.color_id
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][sku_name]`,
            child?.sku_name
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][sku_code]`,
            child?.sku_code
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][status]`,
            child?.status
          );

          // formdata.append(
          //   `product_skus[${item?.parent_name}][${inx}][color_id]`,
          //   child?.color_id
          // );
          // formdata.append(
          //   `product_skus[${item?.parent_name}][${inx}][sku_name]`,
          //   child?.sku_name
          // );
          // formdata.append(
          //   `product_skus[${item?.parent_name}][${inx}][sku_code]`,
          //   child?.sku_code
          // );
          // formdata.append(
          //   `product_skus[${item?.parent_name}][${inx}][status]`,
          //   child?.status
          // );
        });
      });

      dispatch(saveLoader(true));
      setBtn(true);
      if (viewData?.id) {
        formdata.append("product_id", viewData?.id);
        productUpdateVarientApi(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            toast.success(res?.message);
            // console.log("res", res);
            navigate(-1);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      } else {
        formdata.append("product_id", submitData?.id);
        productStoreVarientApi(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            // console.log("res", res);
            toast.success(res?.message);
            navigate(-1);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  // console.log("varientsList", varientsList);
  // console.log("colorsData", colorsData);

  const getViewProduct = () => {
    productViewApi(location?.state?.data?.id)
      .unwrap()
      .then((res) => {
        let data = res?.data;
        setViewData(res?.data);
        setCountry(data?.country_origin);
        setMrp(data?.mrp);
        setProductDescription(data?.description);
        setProductPackageSize(data?.package_size);
        setName(data?.name);
        setGendor(data?.product_for);

        let tagsName = [];
        let tagsID = [];
        let hsnsName = [];
        let hsnsCode = [];
        let DecorationName = [];
        let DecorationID = [];
        let productAttributesName = [];
        let productAttributesID = [];
        let productImagesArr = [];
        let morkupImagesArr = [];
        let colName = [];
        let colID = [];
        let childs = [];
        let colorsDataArr = [];

        data?.category?.parentCategory?.map((item, ind) => {
          if (item?.icon) {
            setCatogory(item?.name);
          }
        });

        data?.tags?.map((item, ind) => {
          tagsName.push(item?.name);
          tagsID.push(item?.id);
          setTagVal(item?.id);
        });

        data?.productDecorations?.map((item, ind) => {
          DecorationName.push(item?.name);
          DecorationID.push(item?.id);
          setDecorationMethod(item?.id);
        });

        hsnsName.push(data?.hsn?.code);
        hsnsCode.push(data?.hsn?.id);

        data?.productAttributes?.map((item, ind) => {
          productAttributesName.push(item?.attribute_name);
          productAttributesID.push({
            id: item?.attribute_id,
            // attribute_id: item?.attribute_id,
            title: item?.attribute_name,
            value: item?.value,
          });
        });

        data?.productImage?.map((item, ind) => {
          productImagesArr.push(item?.image_url);
          setProductImg(item?.image_url);
        });

        data?.morkupImage?.map((item, ind) => {
          morkupImagesArr.push(item?.image_url);
          setMorkupImg(item?.image_url);
        });

        data?.colors?.map((item, ind) => {
          colName.push(item?.name);
        });

        let childData = [];
        data?.product_parent_skus?.map((item, ind) => {
          item?.product_child_skus?.map((chi, inx) => {
            childData.push({
              color_id: chi?.color_id,
              sku_code: chi?.variant_sku,
              sku_name: chi?.variant_name,
              status: chi?.status,
              id: chi?.id,
              variant_sku: item?.variant_sku,
            });
          });

          childs.push({
            [item?.variant_sku]: item?.variant_sku,
            parent_name: item?.variant_sku,
            childs: childData.filter(
              (val) => val.variant_sku == item?.variant_sku
            ),
          });
        });

        data?.colorImages?.map((item, ind) => {
          let finder = data?.colors?.find((col) => col?.id == item?.color_id);
          colorsDataArr.push({
            color_id: item?.id,
            id: finder?.id,
            code: finder?.code,
            hex_code: finder?.hex_code,
            name: finder?.name,
            image: item?.image_url,
            status: finder?.status,
            description: item?.description,
            image_url: item?.image_url,
          });
        });

        // console.log("childs", childs);

        let subCategoryListArr = [];

        data?.category?.parentCategory?.map((item, ind) => {
          if (item?.code) {
            setProductSuplierCode(item?.code);
          }

          if (!item?.icon) {
            subCategoryListArr.push({
              ...item,
              category: item,
            });
          }
        });

        subCategoryListArr.push({
          icon: data?.category.icon,
          id: data?.category.id,
          name: data?.category.name,
          status: data?.category.status,
          category: {
            ...data?.category,
          },
        });

        if (data?.sizes?.length > 0) {
          if (data?.sizes?.[0]?.value) {
            setSizes(data?.sizes);
          } else {
            setSizes([]);
          }
        }

        if (data?.materials?.length > 0) {
          if (data?.materials?.[0]?.value) {
            setMeterials(data?.materials);
          } else {
            setMeterials([]);
          }
        }

        if (data?.styles?.length > 0) {
          if (data?.styles?.[0]?.value) {
            setStyles(data?.styles);
          } else {
            setMeterials([]);
          }
        }

        let catArrs = data?.category?.parentCategory;

        setPrimaryCategoryId(catArrs?.[0]?.id);
        setSecondaryCategoryId(catArrs?.[1]?.id);
        setCategoryID(data?.category?.id);
        setColorsID(data?.colors);
        setColorsName(colName);
        setSmallImage(productImagesArr);
        setSmallMockImage(morkupImagesArr);
        setProductInformation(productAttributesID);
        setDynamicAttr(productAttributesName);
        setDecorativeID(DecorationID);
        setDecorativeName(DecorationName);
        setTagsName(tagsName);
        setTags(tagsID);
        setHsnName(hsnsName);
        setHsnCode(hsnsCode);
        setColorsData(colorsDataArr);
        setSubCategoryList(subCategoryListArr);

        subCategoryListArr?.map((item, ind) => {
          setCatogoryName((val) => ({
            ...val,
            [item?.id]: item?.name,
          }));
        });

        if (childs?.length > 0) {
          setVarientShow(true);
          setVarientList(childs);
        }

        // data?.category
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.type == "edit") {
      getViewProduct();
    }
  }, []);

  useEffect(() => {
    if (attributesName?.length == 0) {
      setVarientsCheck("");
    }
  }, [attributesName]);

  // console.log("location", location);

  const onAddPoints = () => {
    let tmp = [...pointsArr];
    tmp.push({ value: "" });
    setPointsArr([...tmp]);
  };

  const onDeletePoints = (ind) => {
    let tmp = [...pointsArr];
    tmp.splice(ind, 1);
    setPointsArr([...tmp]);
  };

  const handlePoints = (event, ind) => {
    let temp = [...pointsArr];

    temp[ind] = {
      value: event,
    };

    setPointsArr(temp);
  };

  return (
    <div className="dashRightView p-5 home_section trans">
      <ProductSearchHeader />
      <p
        className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5 d-flex align-items-center gap-1"
        role={"button"}
      >
        <p onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </p>{" "}
        Add New Library
      </p>
      <div className="d-flex as-jb w-100 mt-5 res-flex gap-5">
        <FormComp
          setVarientsCheck={setVarientsCheck}
          varientsCheck={varientsCheck}
          colorsData={colorsData}
          setColorsData={setColorsData}
          selColors={selColors}
          setSelColors={setSelColors}
          vendor={vendor}
          parentCategoryList={parentCategoryList}
          getCategoryId={getCategoryId}
          subCategoryList={subCategoryList}
          setSubCategoryList={setSubCategoryList}
          subCategoryLists={subCategoryLists}
          setSubCategoryLists={setSubCategoryLists}
          setParentCategory={setParentCategory}
          decorativeList={decorativeList}
          decorativeID={decorativeID}
          setDecorativeID={setDecorativeID}
          decorativeName={decorativeName}
          setDecorativeName={setDecorativeName}
          country={country}
          setCountry={setCountry}
          hsnList={hsnList}
          hsnCode={hsnCode}
          setHsnCode={setHsnCode}
          hsnName={hsnName}
          setHsnName={setHsnName}
          tagsList={tagsList}
          tagsEventList={tagsEventList}
          tagsIconList={tagsIconList}
          tags={tags}
          setTags={setTags}
          tagsName={tagsName}
          setTagsName={setTagsName}
          tagsEvent={tagsEvent}
          setTagsEvent={setTagsEvent}
          tagsEventName={tagsEventName}
          setTagsEventName={setTagsEventName}
          tagsIcon={tagsIcon}
          setTagsIcon={setTagsIcon}
          tagsIconName={tagsIconName}
          setTagsIconName={setTagsIconName}
          colorsList={colorsList}
          colorsID={colorsID}
          setColorsID={setColorsID}
          colorsName={colorsName}
          setColorsName={setColorsName}
          varientCombination={varientCombination}
          varientShow={varientShow}
          setVarientList={setVarientList}
          sizes={sizes}
          setSizes={setSizes}
          materials={materials}
          setMeterials={setMeterials}
          styles={styles}
          setStyles={setStyles}
          allVarients={allVarients}
          deleteVarient={deleteVarient}
          catogoryName={catogoryName}
          setCatogoryName={setCatogoryName}
          searchValueSubCategory={searchValueSubCategory}
          setSearchSubValueCategory={setSearchSubValueCategory}
          productSuplierCode={productSuplierCode}
          setProductSuplierCode={setProductSuplierCode}
          onSubCategorySelect={onSubCategorySelect}
          categoryID={categoryID}
          setCategoryID={setCategoryID}
          primaryCategoryIdErr={primaryCategoryIdErr}
          secondaryCategoryIdErr={secondaryCategoryIdErr}
          name={name}
          setName={setName}
          mrp={mrp}
          setMrp={setMrp}
          decorationMethod={decorationMethod}
          setDecorationMethod={setDecorationMethod}
          productDescription={productDescription}
          setProductDescription={setProductDescription}
          productPackageSize={productPackageSize}
          setProductPackageSize={setProductPackageSize}
          productPackageSizeErr={productPackageSizeErr}
          setProductPackageSizeErr={setProductPackageSizeErr}
          productDescriptionErr={productDescriptionErr}
          otherProInfoErr={otherProInfoErr}
          otherProInfo={otherProInfo}
          setOtherProInfo={setOtherProInfo}
          setProductDescriptionErr={setProductDescriptionErr}
          decorationMethodErr={decorationMethodErr}
          setDecorationMethodErr={setDecorationMethodErr}
          mrpErr={mrpErr}
          setMrpErr={setMrpErr}
          nameErr={nameErr}
          setNameErr={setNameErr}
          countryErr={countryErr}
          setCountryErr={setCountryErr}
          categoryIDErr={categoryIDErr}
          setCategoryIDErr={setCategoryIDErr}
          setGendor={setGendor}
          gendor={gendor}
          gendorErr={gendorErr}
          secondaryCategoryId={secondaryCategoryId}
          setSecondaryCategoryId={setSecondaryCategoryId}
          primaryCategoryId={primaryCategoryId}
          setPrimaryCategoryId={setPrimaryCategoryId}
          submitHandle={submitHandle}
          attributesList={attributesList}
          productInformation={productInformation}
          setProductInformation={setProductInformation}
          productInformationErr={productInformationErr}
          varientsList={varientsList}
          submitVarient={submitVarient}
          setTagVal={setTagVal}
          setTagEventVal={setTagEventVal}
          setTagIconVal={setTagIconVal}
          tagVal={tagVal}
          tagEventVal={tagEventVal}
          tagIconVal={tagIconVal}
          hsnCodeErr={hsnCodeErr}
          tagErr={tagErr}
          tagEventErr={tagEventErr}
          tagIconErr={tagIconErr}
          btn={btn}
          submitBtn={submitBtn}
          catogory={catogory}
          setCatogory={setCatogory}
          listAttributes={listAttributes}
          setListAttributes={setListAttributes}
          dynamicAttr={dynamicAttr}
          setDynamicAttr={setDynamicAttr}
          colorVarientErr={colorVarientErr}
          setColorVarientErr={setColorVarientErr}
          sizeVarientErr={sizeVarientErr}
          setSizeVarientErr={setSizeVarientErr}
          styleVarientErr={styleVarientErr}
          setStyleVarientErr={setStyleVarientErr}
          matVarientErr={matVarientErr}
          setMatVarientErr={setMatVarientErr}
          colorVarient={colorVarient}
          setColorVarient={setColorVarient}
          sizeVarient={sizeVarient}
          setSizeVarient={setSizeVarient}
          styleVarient={styleVarient}
          setStyleVarient={setStyleVarient}
          matVarient={matVarient}
          setMatVarient={setMatVarient}
          addVarientErr={addVarientErr}
          setAddVarientErr={setAddVarientErr}
          addVarients={addVarients}
          setAddVarients={setAddVarients}
          setAttributesName={setAttributesName}
          attributesName={attributesName}
          viewData={viewData}
          stylesErr={stylesErr}
          materialsErr={materialsErr}
          sizesErr={sizesErr}
          colosErr={colosErr}
          submitData={submitData}
          onAddPoints={onAddPoints}
          onDeletePoints={onDeletePoints}
          handlePoints={handlePoints}
          pointsArr={pointsArr}
        />
        <ImageUplodeComp
          selColors={colorsName}
          vendor={vendor}
          vendor2={vendor2}
          setSmallImage={setSmallImage}
          setSmallMockImage={setSmallMockImage}
          smallimage={smallimage}
          smallMockimage={smallMockimage}
          productImg={productImg}
          setProductImg={setProductImg}
          productImgErr={productImgErr}
          setProductImgErr={setProductImgErr}
          morkupImg={morkupImg}
          setMorkupImg={setMorkupImg}
          morkupImgErr={morkupImgErr}
          setMorkupImgErr={setMorkupImgErr}
          colorsData={colorsData}
          setColorsData={setColorsData}
          colorImg={colorsImg}
          setColorsImg={setColorsImg}
          colorImgErr={colorImgErr}
          setColorImgErr={setColorImgErr}
          viewData={viewData}
          colorRep={colorRep}
          submitData={submitData}
        />
      </div>
      {/* <div className="d-flex as-jb w-100 h-50 mt-5">
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
          onClick={() =>
            navigate("/vendor-details", {
              state: { form: "vendor", type: vendor },
            })
          }>
          Back
        </button>
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() =>
            navigate("/vendor-details", {
              state: { form: "vendor", type: vendor },
            })
          }>
          Submit
        </button>
      </div> */}
    </div>
  );
};

export default AddNewProductScreen;
