import React, { useEffect, useState } from "react";
import {
  collaborateList,
  colorsAndSize,
  size_comp,
  static_attributes,
} from "../../../redux/api/DummyJson";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Close, Search, CircleOutlined, Circle } from "@mui/icons-material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { danger_sm, product } from "../../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import VariationsPopUpCopy2 from "../../Popup/VariationsPopUpCopy2";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-hot-toast";

const EstimateEditTableComp = ({
  poppupAddressToggle,
  poppupImageToggle,
  location,
  setLocation,
  setProductData,
  productData,
  artWorkHandler,
  viewData,
  qtyUpdate,
  discount_value,
  setdiscount_value,
  discount_percent,
  setdiscount_percent,
  net_price,
  setnet_price,
  sub_total,
  setsub_total,
  total,
  settotal,
  tax_value,
  settax_value,
  tax_sum_value,
  settax_sum_value,
  igst,
  cgst,
  sgst,
  quantity,
  setquantity,
  margin_percent,
  setmargin_percent,
  estimateUpdate,
  margin_percents,
  btns,
  retail_price,
  setretail_price,
  AddCharges,
  setAddCharges,
  colorsName,
  setColorsName,
  colorsID,
  setColorsID,
  setColorAndSize,
  colorAndSize,
  productPriceData,
  product_skuVarient,
  type,
  price_per_pack,
  setprice_per_pack,
  reqBtn,
  setReqBtn,
  discountType,
  setBeforeDiscount,
  beforeDiscount,
  afterDiscount,
  setAfterDiscount,
  pricePerPack,
  setPricePerPack,
}) => {
  const navigate = useNavigate();
  const locationNavi = useLocation();
  const projectData = locationNavi?.state?.list;
  const selector = useSelector((state) => state.colorSize);
  const [toggle, setToggle] = useState(1);
  const [dropDown, setDropDown] = useState(undefined);
  const [radioBtn, setRadioBtn] = useState(1);
  const [boxRadioBtn, setBoxRadioBtn] = useState(1);
  const [variationType, setVariationsType] = useState("project-colors");
  const [Variations, setVariations] = useState(false);
  const [colorsShow, setColorsShow] = useState(false);
  const [addBreakdown, setAddBreakdown] = useState([""]);
  const [AddRunCharge, setAddRunCharge] = useState([""]);
  const [international, setInternational] = useState([]);
  const [chargeIn, setChargeIn] = useState([]);
  const [AddFixedCharge, setAddFixedCharge] = useState([""]);
  const [inboundFreight, setInboundFreight] = useState([""]);
  const [brokerage, setBrokerage] = useState([""]);
  const [addFixedChargeDec, setAddFixedChargeDec] = useState([""]);
  const [addRunChargeDec, setAddRunChargeDec] = useState([""]);
  const [decoration, setDecoration] = useState([1, 2]);
  const [ticketStatus, setTicketStatus] = useState(1);
  const [addItemLoc, setAddItemLoc] = useState([""]);
  const [addColor, setAddColor] = useState([]);
  const [run, setRun] = useState({});
  const [fixed, setFixed] = useState({});
  const [decorationData, setDecorationData] = useState({
    0: {
      0: { text: "Imprint Cost", select: 1 },
      1: { text: "Setup Cost", select: 1 },
    },
  });
  const [addFixedChargeDec2, setAddFixedChargeDec2] = useState([""]);
  const [addRunChargeDec2, setAddRunChargeDec2] = useState([""]);
  const [addItemLoc2, setAddItemLoc2] = useState([""]);

  const [addLocation, setAddLocation] = useState([""]);

  const [addSize, setAddSize] = useState([]);
  const [newQty, setNewQty] = useState("");

  //Brokerage
  const addBrokerageClick = () => {
    let temp = [...brokerage];
    temp.push({ warn: "" });
    setBrokerage(temp);
  };

  const toggleShowPopup = (type) => {
    setVariations(!Variations);
  };

  const ColorSlect = (event) => {
    let temp = [...colorsName];
    let finder = temp?.find((i) => i?.id == event?.id);
    if (finder?.id) {
      let final = temp?.filter((i) => i?.id !== finder?.id);
      setColorsName(final);
    } else {
      temp.push(event);
      setColorsName(temp);
    }

    let tempx = [...colorsID];
    let inx = tempx?.indexOf(event?.id);
    if (tempx?.includes(event?.id)) {
      tempx.splice(inx, 1);
    } else {
      tempx.push(event?.id);
    }
    setColorsID(tempx);
  };

  const onColors = (e) => {
    setColorsName(e.target.value);
    if (colorsName.length >= 2) {
      setColorsShow(true);
    } else {
      if (colorsName.length >= 0) setColorsShow(false);
    }
  };

  // ADD CHARGES COMMON

  const addCharges = (num) => {
    let temp = [...AddCharges];

    temp.push({
      name: "",
      net_price: "",
      margin: "",
      final_amount: "",
      client_price: "",
    });
    setAddCharges(temp);
    setDropDown(undefined);
  };

  const removeCharge = (ind) => {
    let temp = [...AddCharges];

    temp.splice(ind, 1);
    calculationFun(net_price, temp);
    setAddCharges(temp);
  };

  const addInternaional = (num) => {
    let temp = [...international];

    temp.push(num);
    setInternational(temp);
    setDropDown(undefined);
  };

  const handleDiscount = (value) => {
    calculationFun(net_price, AddCharges);
    setdiscount_percent(value);
  };

  const calculationFun = (base_price, runcharges) => {
    let return_values = {};
    return_values.net_price = base_price;

    return_values.retail_price = return_values.net_price;
    return_values.sub_total = +return_values.retail_price;
    return_values.total = +return_values.retail_price;

    runcharges.map((item, ind) => {
      if (item?.client_price == 1) {
        // return_values.sub_total += +item?.final_amount;
        // return_values.total += +item?.final_amount;
      } else if (item?.client_price == 2) {
        return_values.sub_total += +item?.final_amount;
      }
    });

    let tex_percen;

    if (igst) {
      tex_percen = igst;
    } else {
      tex_percen = +cgst + +sgst;
    }

    // if (tex_percen && projectData?.tax_type == 1) {
    if (tex_percen) {
      let tex = Number((+return_values?.sub_total * +tex_percen) / 100).toFixed(
        2
      );
      let final = Number(+tex + +return_values?.sub_total).toFixed(2);
      settax_sum_value(+final);
      settax_value(+tex);
    } else {
      settax_sum_value(+return_values?.sub_total);
    }

    setnet_price(+base_price);
    setsub_total(+return_values?.sub_total);
    setretail_price(+return_values?.retail_price);
    settotal(+return_values?.total);

    return return_values;
  };

  const handleChangeRun = (event, ind, type) => {
    let temp = [...AddCharges];

    if (type == "net_price") {
      let margin = temp[ind].margin ? temp[ind].margin : 0;

      let sum = (+event * +margin) / 100;
      let total_ = +event + +sum;
      let total = +quantity * +event;

      temp[ind] = {
        ...temp[ind],
        [type]: event,
        margin: +quantity,
        final_amount: total,
      };
    } else if (type == "margin") {
      let net_prices = temp[ind].net_price ? temp[ind].net_price : 0;

      let sum = (+event * +net_prices) / 100;
      let total_ = +net_prices + +sum;
      let total = +quantity * +event;

      temp[ind] = {
        ...temp[ind],
        [type]: event,
        final_amount: total,
      };
    } else if (type == "client_price") {
      temp[ind] = {
        ...temp[ind],
        margin: +quantity,
        [type]: event,
      };
    } else {
      temp[ind] = {
        ...temp[ind],
        margin: +quantity,
        [type]: event,
      };
    }

    // console.log("arr", arr);

    if (type !== "name") {
      let per_pack_ = +pricePerPack;
      let base_price = 0;

      temp?.map((item, ind) => {
        if (+item?.client_price == 1) {
          per_pack_ += +item?.net_price;
        }
      });

      let productDistributorPrice_ = getPrices(
        quantity,
        viewData?.productPrice?.productDistributorPrice
      );

      if (beforeDiscount && afterDiscount) {
        let total_amount = 0;
        let afterDiscount_ = 0;
        let beforeDiscount_ = 0;
        let originalPrice = +per_pack_;
        let discountPercentage = +beforeDiscount;
        let discountedPrice = originalPrice * (1 - discountPercentage / 100);
        let discountedPrice_ = Number(discountedPrice).toFixed(2);
        afterDiscount_ = +discountedPrice_;
        beforeDiscount_ = +discountPercentage;
        total_amount = +discountedPrice_ * +quantity;

        let c11 = +afterDiscount_;
        // let a11 = +productMspPrice_;
        let a11 = +productDistributorPrice_;
        let b11 = ((c11 - a11) * 100) / a11;
        let margin_ = Number(b11).toFixed(2);
        base_price = Number(total_amount).toFixed(2);
        setBeforeDiscount(beforeDiscount_);
        setAfterDiscount(afterDiscount_);
        setmargin_percent(margin_);
        if (+margin_ >= +viewData?.productPrice?.msp_percentage) {
          setReqBtn(2);
        } else {
          setReqBtn(1);
        }
      } else {
        let c11 = +per_pack_;
        let a11 = +productDistributorPrice_;
        let b11 = ((c11 - a11) * 100) / a11;
        let margin_percent_ = Number(b11).toFixed(2);
        setmargin_percent(margin_percent_);
        if (+margin_percent_ >= +viewData?.productPrice?.msp_percentage) {
          setReqBtn(2);
        } else {
          setReqBtn(1);
        }
        base_price = +per_pack_ * +quantity;
      }

      calculationFun(base_price, temp);
      setprice_per_pack(per_pack_);
    }
    setAddCharges(temp);
  };

  const getPrices = (qty, price_data) => {
    if (+qty >= 1 && +qty <= 25) {
      return price_data?.for_25;
    } else if (+qty >= 26 && +qty <= 50) {
      return price_data?.for_50;
    } else if (+qty >= 51 && +qty <= 100) {
      return price_data?.for_100;
    } else if (+qty >= 101 && +qty <= 150) {
      return price_data?.for_150;
    } else if (+qty >= 151 && +qty <= 250) {
      return price_data?.for_250;
    } else if (+qty >= 251 && +qty <= 500) {
      return price_data?.for_500;
    } else if (+qty >= 501 && +qty <= 1000) {
      return price_data?.for_1000;
    } else if (+qty > 1000) {
      return price_data?.for_1000;
    } else {
      return price_data?.for_25;
    }
  };

  // QTY ADD OR SELECT
  const handleQty = (event, type) => {
    let base_price = 0;
    let per_pack_ = 0;
    let per__pack_ = 0;
    let margin_percent_ = 0;
    let runcharges = [];

    if (type == "add") {
      if (+event?.length == 0) {
        toast.error("Please Enter QTY");
        return;
      }

      let obj = productPriceData;
      let margin_val = +margin_percent;
      margin_percent_ = +margin_percent;

      let value = getPrices(event, obj);

      let base_price_sum = (value * +margin_val) / 100 + value;
      let base_price_qty = Math.round(base_price_sum) * +event;
      // base_price = +base_price_qty;
      per_pack_ = +value;
      per__pack_ = +value;

      AddCharges?.map((item, ind) => {
        if (+item?.client_price == 1) {
          per_pack_ += +item?.net_price;
        }

        let obj = { ...item };
        obj["margin"] = +event;
        obj["final_amount"] = +item?.net_price * +event;
        runcharges.push(obj);
      });
      base_price = +per_pack_ * +event;
    } else {
      let finder = productData?.projectPresentationPrice?.find(
        (i) => i?.qty == +event
      );

      let value = getPrices(event, productPriceData);

      if (!value) {
        toast.error("Price for the selected quantity is not available.");
        return;
      }

      if (finder?.id) {
        margin_percent_ = +finder?.margin;
        per_pack_ = +finder?.amount;
        per__pack_ = +finder?.amount;
        base_price = +finder?.amount * +event;

        finder?.run_charge?.map((item, ind) => {
          let obj = {
            name: item?.name,
            margin: +event,
            name: item?.name,
            net_price: +item?.value,
            final_amount: +event * +item?.value,
            client_price: 2,
          };
          runcharges.push(obj);
        });
      }
    }

    let productDistributorPrice_ = getPrices(
      event,
      viewData?.productPrice?.productDistributorPrice
    );

    if (beforeDiscount && afterDiscount) {
      let total_amount = 0;
      let afterDiscount_ = 0;
      let beforeDiscount_ = 0;
      let originalPrice = +per_pack_;
      let discountPercentage = +beforeDiscount;
      let discountedPrice = originalPrice * (1 - discountPercentage / 100);
      let discountedPrice_ = Number(discountedPrice).toFixed(2);
      afterDiscount_ = +discountedPrice_;
      beforeDiscount_ = +discountPercentage;
      total_amount = +discountedPrice_ * +event;

      let c11 = +afterDiscount_;
      // let a11 = +productMspPrice_;
      let a11 = +productDistributorPrice_;
      let b11 = ((c11 - a11) * 100) / a11;
      let margin_ = Number(b11).toFixed(2);
      base_price = Number(total_amount).toFixed(2);
      setBeforeDiscount(beforeDiscount_);
      setAfterDiscount(afterDiscount_);
    } else {
      let c11 = +per_pack_;
      // let a11 = +productMspPrice_;
      let a11 = +productDistributorPrice_;
      let b11 = ((c11 - a11) * 100) / a11;
      margin_percent_ = Number(b11).toFixed(2);
    }

    // console.log("base_price", base_price);

    if (+margin_percent_ >= +viewData?.productPrice?.msp_percentage) {
      setReqBtn(2);
    } else {
      setReqBtn(1);
    }

    setPricePerPack(per__pack_);
    setprice_per_pack(per_pack_);
    setmargin_percent(margin_percent_);
    calculationFun(base_price, runcharges);
    setAddCharges(runcharges);
    setquantity(+event);
    setNewQty("");
    setDropDown(null);
  };

  // console.log("AddCharges", AddCharges);

  // console.log("margin_percent", margin_percent);

  const handleSkuQty = (event, ind) => {
    let temp = [...colorsName];

    if (+event >= 0) {
      temp[ind] = {
        ...temp[ind],
        qty: +event,
      };
    }

    setColorsName(temp);
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  const [reveseAmount, setReveseAmount] = useState(0);

  // console.log("price_per_pack", price_per_pack, "pricePerPack", pricePerPack);

  const handleDiscountPrice = (price2, type) => {
    let obj = viewData?.productPrice?.productDistributorPrice;
    let obj2 = viewData?.productPrice;
    let obj3 = viewData?.productPrice?.productMspPrice;

    let productDistributorPrice_ = getPrices(quantity, obj);
    let productPrice_ = getPrices(quantity, obj2);
    let productMspPrice_ = getPrices(quantity, obj3);

    let price_per_packs = pricePerPack ? +pricePerPack : 0;
    let total_amount = 0;
    let final_value = +price_per_pack - +price2;
    let afterDiscount_ = 0;
    let beforeDiscount_ = 0;

    AddCharges?.map((item, ind) => {
      if (+item?.client_price == 1) {
        price_per_packs += +item?.net_price;
      }
    });

    if (type == "percentageToAmount") {
      let originalPrice = +price_per_packs;
      let discountPercentage = +price2;
      let discountedPrice = originalPrice * (1 - discountPercentage / 100);
      let discountedPrice_ = Number(discountedPrice).toFixed(2);
      afterDiscount_ = +discountedPrice_;
      beforeDiscount_ = +discountPercentage;
      total_amount = +discountedPrice_ * +quantity;
    } else if (type == "amountToPercentage") {
      let originalPrice = +price_per_packs;
      let discountedPrice = +price2;
      let discountPercentage =
        ((originalPrice - discountedPrice) / originalPrice) * 100;
      let discountPercentage_ = Number(discountPercentage).toFixed(2);
      afterDiscount_ = +discountedPrice;
      beforeDiscount_ = +discountPercentage_;
      total_amount = +discountedPrice * +quantity;
    }

    let c11 = +afterDiscount_;
    // let a11 = +productMspPrice_;
    let a11 = +productDistributorPrice_;
    let b11 = ((c11 - a11) * 100) / a11;
    let margin_ = Number(b11).toFixed(2);
    let base_price = Number(total_amount).toFixed(2);

    let count = 1;

    if (+beforeDiscount_ < 0) {
      count += 1;
    }

    if (+margin_ <= 0) {
      count += 1;
    }

    // console.log("count", count);

    // if (count == 1) {
    //   if(+beforeDiscount_ == 0){
    //     setmargin_percent(+obj2?.scp_percentage);
    //   }else{

    //   }
    //   setBeforeDiscount(+beforeDiscount_);
    //   setmargin_percent(+margin_);
    // } else {
    //   toast.error("value is below 0");
    // }

    if (beforeDiscount_ <= 0) {
      setmargin_percent(+obj2?.scp_percentage);
    } else {
      setmargin_percent(+margin_);
    }

    // if (+beforeDiscount_ >= 0) {
    //   setBeforeDiscount(+beforeDiscount_);
    // } else {
    //   toast.error("value is below 0");
    // }

    if (+margin_ >= +viewData?.productPrice?.msp_percentage) {
      setReqBtn(2);
    } else {
      setReqBtn(1);
    }

    setprice_per_pack(+price_per_packs);
    setAfterDiscount(+afterDiscount_);
    setBeforeDiscount(+beforeDiscount_);
    setnet_price(+base_price);
    calculationFun(+base_price, AddCharges);
  };

  const handleMarign = (event) => {
    let obj = productPriceData?.productDistributorPrice;

    let value = getPrices(quantity, obj);

    if (!value) {
      toast.error("Price for the selected quantity is not available.");
      return;
    }

    if (event >= margin_percents) {
      setReqBtn(2);
    } else {
      setReqBtn(1);
    }

    let base_price_sum = (+event * +value) / 100 + +value;
    let base_price_qty = Math.round(base_price_sum) * +quantity;
    let base_price = +base_price_qty * +viewData?.no_of_packs;

    calculationFun(+base_price_qty, AddCharges);
    setprice_per_pack(Math.round(base_price_sum));
    setmargin_percent(event);
  };

  return (
    <div className="w-100">
      {Variations && (
        <VariationsPopUpCopy2
          toggleShowPopup={toggleShowPopup}
          variationType={variationType}
        />
      )}
      <div className="d-flex mt-3 ac-jb flex-column flex-md-row w-100">
        <div className="d-flex mt-3 ac-jc gap-2 flex-column flex-sm-row">
          <button
            onClick={() =>
              navigate("/product-library-details", {
                state: { data: productData?.product, type: "edit" },
              })
            }
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            View Product Page
          </button>
          <button
            onClick={() =>
              navigate("/product-library-details", {
                state: { data: productData?.product, type: "edit", tab: 1 },
              })
            }
            className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Check Pricing
          </button>
        </div>
        <div className="mt-md-4 mt-3">
          <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
            Currency :
            <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
              INR
            </span>
          </h5>
        </div>
      </div>
      <div className="w-100 mt-5">
        <p className="f3 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23 black">
          Pricing
        </p>
        <div
          className="w-100 overflow-scroll mt-5 edit_page_cust"
          style={colorsShow ? { height: "300px" } : { borderWidth: "1px" }}
        >
          <table className="w-100">
            <tr className="mt-4 border_cust ">
              <div className="bg-soft-gray1 py-3 bottom_cust_bord">
                <td>
                  <div className="d-flex w-100">
                    <div className="mx-2" style={{ width: "20px" }}></div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Varients
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        SKU
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "150px" }}
                      >
                        QTY
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "100px" }}
                      >
                        Margin
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Price Per Pack
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Discount Margin
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        After Discount
                      </p>
                    </div>
                    <div className="mx-2">
                      <p
                        className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                        style={{ width: "200px" }}
                      >
                        Net Cost
                      </p>
                    </div>

                    {/* <div
                      className="mx-2 d-flex ac-jc"
                      style={{ width: "200px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        {" "}
                        Retail
                      </p>
                      <LockOpenRoundedIcon />
                    </div> */}
                    <div
                      className="mx-2 d-flex ac-jc"
                      style={{ width: "150px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        &nbsp;
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc gap-1"
                      style={{ width: "100px" }}
                    >
                      <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                        {" "}
                        Total
                      </p>
                      <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            <tr>
              <div className="py-3 hide_res">
                <td>
                  <div className="d-flex w-100">
                    <div
                      className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                      style={{ width: "20px" }}
                    >
                      {/* <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" /> */}
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc "
                      style={{ width: "200px" }}
                    >
                      <div className="position-relative">
                        {/* NEW DESIGN */}
                        <input
                          className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          // value={colorsName}
                          placeholder="Select Varients"
                          onChange={onColors}
                        />
                        <button
                          className="drop_down cust-btn"
                          onClick={() => {
                            setColorsShow(!colorsShow);
                          }}
                          disabled={type == "sales" ? true : false}
                        >
                          <KeyboardArrowDownIcon />
                        </button>
                        {colorsShow && (
                          <div
                            className="invisible-cont2"
                            onClick={() => setColorsShow(!colorsShow)}
                          />
                        )}
                        <div
                          className={`${
                            colorsShow && "drop_width z-3 h-auto py-2  px-2"
                          } submenu_cont_1 overflow-scroll z-3`}
                        >
                          {colorAndSize?.map((item, ind) => {
                            // console.log(ind)
                            return (
                              <button
                                className="d-flex ac-jb hover-cust cust-btn w-100 px-2"
                                onClick={() => {
                                  if (item?.type !== "child") {
                                    ColorSlect(item);
                                  }
                                }}
                                key={ind}
                                disabled={
                                  item?.project_detail_size_id ? true : false
                                }
                              >
                                <button className="px-2 cust-btn text-start py-1 w-100">
                                  {item?.variant_name}
                                </button>
                                {colorsID?.includes(item?.id) ? (
                                  <Circle style={{ color: "#07679c" }} />
                                ) : (
                                  <CircleOutlined className="primary" />
                                )}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={product_skuVarient}
                      />
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "150px" }}
                    >
                      <div className=" d-flex ac-jc position-relative">
                        <div className="position-relative">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            readOnly
                            value={quantity}
                            onClick={() => setDropDown(8)}
                          />
                          <button
                            className="drop_down cust-btn"
                            onClick={() => setDropDown(8)}
                            disabled={type == "sales" ? true : false}
                          >
                            <KeyboardArrowDownIcon className="primary" />
                          </button>
                          {dropDown === 8 && (
                            <div
                              className="invisible-cont2 z-0"
                              onClick={() => setDropDown(undefined)}
                            />
                          )}
                          <div
                            className={`${
                              dropDown === 8 && "submenu_1 h-auto"
                            } submenu_cont_1 overflow-scroll z-3`}
                            style={{ width: "170px" }}
                          >
                            <div className="d-flex gap-1">
                              <input
                                className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                                placeholder="Add New"
                                type={"number"}
                                onChange={(e) => setNewQty(e.target.value)}
                                value={newQty}
                                readOnly={type == "sales" ? true : false}
                              />
                              <button
                                className="border-0 bg-transparent"
                                onClick={() => {
                                  handleQty(newQty, "add");
                                }}
                              >
                                Add
                              </button>
                            </div>
                            {productData?.projectPresentationPrice?.map(
                              (item, ind) => {
                                return (
                                  <div
                                    className="d-flex as-jb hover-cust"
                                    key={ind}
                                  >
                                    <button
                                      className="px-2 cust-btn text-start py-1 w-100 "
                                      onClick={() => {
                                        handleQty(item?.qty);
                                      }}
                                    >
                                      {item?.qty}
                                    </button>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        type={"number"}
                        value={margin_percent}
                        onChange={(e) => handleMarign(e.target.value)}
                        // readOnly={type == "sales" ? true : false}
                        readOnly
                      />
                      <button className="drop_down4 cust-btn">
                        <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                          %
                        </p>
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        value={price_per_pack ? price_per_pack : "0"}
                        type={"number"}
                        readOnly={type == "sales" ? true : false}
                      />
                      <button className="drop_down4 cust-btn">
                        <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>

                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        value={beforeDiscount ? beforeDiscount : "0"}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          if (e.target.value.length !== 5) {
                            e.target.setCustomValidity("invalid Number");
                          } else if (e.target.value.length == 5) {
                            e.target.setCustomValidity("");

                            handleDiscountPrice(
                              e.target.value,
                              "percentageToAmount"
                            );
                          }
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        maxlength={5}
                        type={"number"}
                        onChange={(e) =>
                          handleDiscountPrice(
                            e.target.value,
                            "percentageToAmount"
                          )
                        }
                        readOnly={type == "sales" ? true : false}
                        disabled={
                          viewData?.project_detail?.discount_type == 1
                            ? false
                            : true
                        }
                      />
                      <button className="drop_down4 cust-btn">
                        <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                          %
                        </p>
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        value={afterDiscount ? afterDiscount : "0"}
                        onChange={
                          (e) =>
                            handleDiscountPrice(
                              e.target.value,
                              "amountToPercentage"
                            )
                          // calculateDiscount(
                          //   price_per_pack,
                          //   e.target.value,
                          //   "amountToPercentage"
                          // )
                        }
                        type={"number"}
                        readOnly={type == "sales" ? true : false}
                        disabled={
                          viewData?.project_detail?.discount_type == 1
                            ? false
                            : true
                        }
                      />
                      <button className="drop_down4 cust-btn">
                        <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        value={net_price ? net_price : "0"}
                        type={"number"}
                        readOnly={type == "sales" ? true : false}
                      />
                      <button className="drop_down4 cust-btn">
                        <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div>
                    {/* <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "200px" }}
                    >
                      <input
                        placeholder="Required Field"
                        className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                        type={"number"}
                        value={retail_price ? retail_price : "0"}
                        readOnly={type == "sales" ? true : false}
                      />
                      <button className="drop_down4 cust-btn">
                        <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                      </button>
                    </div> */}
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "150px" }}
                    >
                      <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                        &nbsp;
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative"
                      style={{ width: "100px" }}
                    >
                      <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                        {total ? "₹" + commaValue(total) : "0"}
                      </p>
                    </div>
                    <div
                      className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                      style={{ width: "10px" }}
                    >
                      <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                    </div>
                  </div>
                </td>
              </div>
            </tr>
            {reqBtn == 1 && (
              <tr>
                <div className=" hide_res">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                        style={{ width: "20px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc "
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "150px" }}
                      ></div>

                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      >
                        <p
                          className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                          role={"button"}
                          onClick={() => estimateUpdate()}
                        >
                          Request
                        </p>
                        <button
                          className={`border-0 text-info f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ${
                            btns ? "opacity-50" : ""
                          }`}
                          style={{ backgroundColor: "#f4f8ff" }}
                          onClick={() =>
                            navigate("/projects-price-request", {
                              state: {
                                data: `${locationNavi?.state?.list?.project_code}_${locationNavi?.state?.list?.id}`,
                              },
                            })
                          }
                          disabled={btns ? true : false}
                        >
                          View
                        </button>
                      </div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      ></div>

                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "150px" }}
                      ></div>
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "100px" }}
                      ></div>
                    </div>
                  </td>
                </div>
              </tr>
            )}

            {colorsName?.map((item, ind) => {
              return (
                <tr>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "20px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "415px" }}
                        >
                          <input
                            placeholder="Required Field"
                            value={item?.variant_name}
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            readOnly
                          />
                        </div>
                        <div
                          className="mx-2 d-flex flex-column ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          {item?.type == "child" ? (
                            <input
                              value={quantity}
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                              readOnly
                            />
                          ) : (
                            <input
                              type={"number"}
                              value={item?.qty}
                              onChange={(e) =>
                                handleSkuQty(e.target.value, ind)
                              }
                              readOnly={type == "sales" ? true : false}
                              placeholder="Required Field"
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            />
                          )}
                          {item?.type !== "child" && item?.qty > quantity && (
                            <div className="d-flex gap-1 mt-2">
                              <ErrorIcon className="svg_log" />
                              <p className="err-input_log mt-1">
                                Qty is higher
                              </p>
                            </div>
                          )}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          {item?.type !== "child" && (
                            <button
                              className={`drop_down4 cust-btn ${
                                item?.qty > quantity
                                  ? "opacity-50"
                                  : btns
                                  ? "opacity-50"
                                  : ""
                              }`}
                              onClick={() => qtyUpdate(item)}
                              disabled={
                                item?.qty > quantity
                                  ? true
                                  : btns
                                  ? true
                                  : false
                              }
                            >
                              <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3">
                                Set
                              </p>
                            </button>
                          )}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "10px" }}
                        ></div>
                      </div>
                    </td>
                  </div>
                </tr>
              );
            })}

            {AddCharges?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="d-flex w-100">
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "20px" }}
                          onClick={() => removeCharge(ind)}
                        >
                          <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "415px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={item?.name}
                            onChange={(e) =>
                              handleChangeRun(e.target.value, ind, "name")
                            }
                            readOnly={type == "sales" ? true : false}
                          />
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            readOnly
                            value={quantity}
                          />
                        </div>

                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            value={item?.net_price}
                            onChange={(e) =>
                              handleChangeRun(e.target.value, ind, "net_price")
                            }
                            type={"number"}
                            readOnly={type == "sales" ? true : false}
                          />
                          <button className="drop_down4 cust-btn">
                            <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                          </button>
                        </div>

                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        ></div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        >
                          {/* <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            value={item?.final_amount}
                            onChange={(e) =>
                              handleChangeRun(
                                e.target.value,
                                ind,
                                "final_amount"
                              )
                            }
                            readOnly
                            type={"number"}
                          />
                          <button className="drop_down4 cust-btn">
                            <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                          </button> */}
                        </div>

                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          <select
                            placeholder="Required Field"
                            className="editBtn text-center w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onChange={(e) =>
                              handleChangeRun(
                                e.target.value,
                                ind,
                                "client_price"
                              )
                            }
                            value={item?.client_price}
                            disabled={type == "sales" ? true : false}
                          >
                            <option value={""}>Select</option>
                            <option value={1}>Include in price</option>
                            <option value={2}>Display to client</option>
                          </select>
                        </div>
                        {/* <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "200px" }}
                        ></div> */}
                        <div
                          className="mx-2 d-flex ac-jc position-relative"
                          style={{ width: "100px" }}
                        >
                          {item?.client_price == 2 && (
                            <p className="f1 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 black">
                              ₹{commaValue(item?.final_amount)}
                            </p>
                          )}
                        </div>
                        <div
                          className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                          style={{ width: "10px" }}
                        >
                          <SouthRoundedIcon className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center black f3" />
                        </div>
                      </div>
                    </td>
                  </div>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <div className="d-flex ac-jb mt-3">
        <div className="d-flex flex-column flex-sm-row mt-3 mt-md-0">
          <div className="position-relative">
            {type != "sales" && (
              <button
                onClick={() => setDropDown(6)}
                className="pointerView cust-btn px-4 py-2 tranc cp"
              >
                <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  + Add Cost
                </p>
              </button>
            )}
            {dropDown === 6 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropDown === 6 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    // setDropDown(undefined);
                    // addRunChargeClick();
                    addCharges(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Run Charge
                </button>
              </button>
              {/* <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    // setDropDown(undefined);
                    // addFixedChargeClick();
                    addCharges(2);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Fixed Charge
                </button>
              </button> */}
            </div>
          </div>
          <div className="position-relative">
            {/* <button
              onClick={() => setDropDown(7)}
              className="pointerView cust-btn px-4 py-2 tranc cp"
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Add International Cost
              </p>
            </button> */}
            {dropDown === 7 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropDown === 7 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Inbound Freight
                </button>
              </button>
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addBrokerageClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Brokerage
                </button>
              </button>
              {/* {!international?.includes(2) && ( */}
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addBrokerageClick();
                    addInternaional(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Others
                </button>
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="mt-4 d-flex ac-jc">
          {type != "sales" && (
            <button
              class={`cust-btn addbtn bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btns && "opacity-50"
              }`}
              onClick={() => estimateUpdate()}
              disabled={btns ? true : false}
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <div className="d-flex ac-js mt-5 gap-3 flex-column flex-md-row">
        <div className="me-5">
          <p className="f3 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23 black">
            Decoration
          </p>
        </div>
        <div className="d-flex toggle_box_cont ac-jc rounded-5">
          <div
            onClick={() => setToggle(1)}
            className={`${
              toggle === 1 ? "toggle_box1" : "empty_box"
            }  d-flex ac-jc  rounded-5 w-100`}
          >
            <button className="cust-btn f3 text-light py-2 py-sm-3  px-1 px-sm-3 d-flex ac-jc text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              Supplier Decorator
            </button>
          </div>
        </div>
      </div>
      {location?.map((item, ind) => {
        return (
          <div className="w-100 overflow-scroll mt-5 edit_page_cust">
            <table className="w-100">
              <div className="d-flex hide_res">
                <p className="primary f3 ms-5 pt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                  Location {ind + 1}
                </p>
              </div>
              <tr>
                <div className="py-3 hide_res">
                  <td>
                    <div className="d-flex w-100">
                      <div
                        className="mx-2 d-flex ac-jc position-relative"
                        style={{ width: "415px" }}
                      >
                        <div className="w-100">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                            value={item?.product?.name}
                          />
                        </div>
                      </div>
                      <div className="mx-2 d-flex ac-jc position-relative">
                        <button
                          onClick={() =>
                            artWorkHandler(item, productData, 0, ind)
                          }
                          className=" bg-transparent doted_border d-flex ac-js "
                        >
                          <div className="">
                            <img
                              src={item?.image_url ? item?.image_url : product}
                              className="object-fit-contain img_style"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </td>
                </div>
              </tr>
              <tr>
                <div className="py-3 hide_res">
                  <td>
                    <div className="w-100 d-flex ac-js gap-3">
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Decorative Method
                            </p>
                            <div className="w-100 ms-3">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={item?.decorative_method_id?.name}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              {/* Imprint Type: */}
                              Proof Required
                            </p>
                            <div className="w-100 ms-3">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={
                                  item?.proof_required == "email_proof"
                                    ? "Email Proof"
                                    : item?.proof_required == "digital_photo"
                                    ? "Digital Photo"
                                    : item?.proof_required == "pre_production"
                                    ? "Pre Production"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Logo Name
                            </p>
                            <div className="w-100 ms-3">
                              <textarea
                                placeholder="Required Field"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_name}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              UOM
                            </p>
                            <div className="w-100 ac-js d-flex gap-3 ms-3">
                              <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                <button className="d-flex gap-3  ac-js cust-btn">
                                  <div className="radio_btn d-flex ac-jc">
                                    <div
                                      className={`${
                                        +item?.uom == 1
                                          ? "sm_radio"
                                          : "sm_radio1"
                                      }`}
                                    />
                                  </div>
                                  <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                    Inches
                                  </p>
                                </button>
                              </div>
                              <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                <button className="d-flex gap-3  ac-js cust-btn">
                                  <div className="radio_btn d-flex ac-jc">
                                    <div
                                      className={`${
                                        +item?.uom == 2
                                          ? "sm_radio"
                                          : "sm_radio1"
                                      }`}
                                    />
                                  </div>
                                  <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                    Stitch
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </td>
                </div>
                <div className="py-3 hide_res">
                  <td>
                    <div className="w-100 d-flex ac-js  gap-3">
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Logo Size
                            </p>
                            <div className="w-100 ms-3 d-flex gap-2">
                              <input
                                placeholder="Length"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_length}
                              />
                              <input
                                placeholder="Breadth"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_breadth}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Logo Color
                            </p>
                            <div className="w-100 ms-3">
                              <textarea
                                placeholder="Required Field"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.logo_color}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Repeat Logo?
                            </p>
                            <div className="w-100 ms-3">
                              <input
                                placeholder="Required Field"
                                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                value={
                                  item?.repeat_logo == "yes"
                                    ? "Yes"
                                    : item?.repeat_logo == "no"
                                    ? "No"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                          <div className="w-100 mt-3">
                            <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                              Supplier Notes
                            </p>
                            <div className="w-100 ms-3">
                              <textarea
                                placeholder="Required Field"
                                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                value={item?.supplier_note}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </td>
                </div>
              </tr>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default EstimateEditTableComp;
