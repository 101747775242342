import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import OverviewTab from "../components/RequestDetailView/OverviewTab";
import {
  useLazyAttributeListQuery,
  useLazyCategoriesQuery,
  useLazyCategoryViewQuery,
  useLazyColorsListAllQuery,
  useLazyDecorativeListQuery,
  useLazyGetProductDecorPriceListQuery,
  useLazyGetProductVendorPriceListQuery,
  useLazyHsnAllListQuery,
  useLazyProductViewQuery,
  useLazyTagsListQuery,
  useProductCreateMutation,
  useProductGenerateVarientMutation,
  useProductStoreVarientMutation,
  useProductUpdateMutation,
  useProductUpdateVarientMutation,
  useVendorMapPriceAddMutation,
  useProductReqStatusChangeMutation,
  useCategoryProductViewNewMutation,
} from "../redux/api/api";
import { saveLoader } from "../redux/slice/loaderNoti";

const RequestAcceptScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const priceLists = location?.state?.data?.price?.final_price;
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();

  // console.log("location", location?.state);

  // RTK QUERY
  const [listCategory] = useLazyCategoriesQuery();
  const [viewCategory] = useLazyCategoryViewQuery();
  // const [viewCategory] = useCategoryProductViewNewMutation();

  const [decorativeListApi] = useLazyDecorativeListQuery();
  const [hsnListApi] = useLazyHsnAllListQuery();
  const [tagsListApi] = useLazyTagsListQuery();
  const [colorsListApi] = useLazyColorsListAllQuery();
  const [attributesApi] = useLazyAttributeListQuery();

  const [productCreateApi] = useProductCreateMutation();
  const [productUpdateApi] = useProductUpdateMutation();
  const [productViewApi] = useLazyProductViewQuery();
  const [productGenerateVarientApi] = useProductGenerateVarientMutation();
  const [productStoreVarientApi] = useProductStoreVarientMutation();
  const [productUpdateVarientApi] = useProductUpdateVarientMutation();
  const [getProductVendorPriceApi] = useLazyGetProductVendorPriceListQuery();
  const [getProductDecorPriceApi] = useLazyGetProductDecorPriceListQuery();
  const [productPriceApi] = useVendorMapPriceAddMutation();
  const [productStatusApi] = useProductReqStatusChangeMutation();

  const [selColors, setSelColors] = useState([]);
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [decorativeList, setDecorativeList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [decorativeID, setDecorativeID] = useState([]);
  const [decorativeName, setDecorativeName] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [country, setCountry] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [otherProInfo, setOtherProInfo] = useState("");
  const [hsnCodeErr, setHsnCodeErr] = useState(false);
  const [hsnName, setHsnName] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [tagsEventList, setEventTagsList] = useState([]);
  const [tagsIconList, setIconTagsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsName, setTagsName] = useState([]);
  const [tagsEvent, setTagsEvent] = useState([]);
  const [tagsEventName, setTagsEventName] = useState([]);
  const [tagsIcon, setTagsIcon] = useState([]);
  const [tagsIconName, setTagsIconName] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [colorsID, setColorsID] = useState([]);
  const [colorsName, setColorsName] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [varientShow, setVarientShow] = useState(false);
  const [sizes, setSizes] = useState([{ value: "" }]);
  const [materials, setMeterials] = useState([{ value: "" }]);
  const [styles, setStyles] = useState([{ value: "" }]);
  const [allVarients, setAllVarients] = useState([]);
  const [catogoryName, setCatogoryName] = useState({});
  const [primaryCategoryId, setPrimaryCategoryId] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [primaryCategoryIdErr, setPrimaryCategoryIdErr] = useState(false);
  const [secondaryCategoryIdErr, setSecondaryCategoryIdErr] = useState(false);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState("");
  const [productSuplierCode, setProductSuplierCode] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [smallimage, setSmallImage] = useState([]);
  const [smallMockimage, setSmallMockImage] = useState([]);
  const [attributesName, setAttributesName] = useState(["Color"]);

  const [categoryID, setCategoryID] = useState("");
  const [catogory, setCatogory] = useState("");
  const [name, setName] = useState("");
  const [mrp, setMrp] = useState("");
  const [decorationMethod, setDecorationMethod] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPackageSize, setProductPackageSize] = useState("");
  const [gendor, setGendor] = useState("");
  const [gendorErr, setGendorErr] = useState("");
  const [colorVarient, setColorVarient] = useState("");
  const [productPackageSizeErr, setProductPackageSizeErr] = useState(false);
  const [productDescriptionErr, setProductDescriptionErr] = useState(false);
  const [decorationMethodErr, setDecorationMethodErr] = useState(false);
  const [colorVarientErr, setColorVarientErr] = useState(false);
  const [mrpErr, setMrpErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [tagErr, setTagErr] = useState(false);
  const [tagVal, setTagVal] = useState("");
  const [productImg, setProductImg] = useState("");
  const [productImgErr, setProductImgErr] = useState(false);
  const [morkupImg, setMorkupImg] = useState("");
  const [morkupImgErr, setMorkupImgErr] = useState(false);
  const [colorsImg, setColorsImg] = useState("");
  const [colorImgErr, setColorImgErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(0);
  const [listAttributes, setListAttributes] = useState([]);
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [addVariants, setAddVariants] = useState(false);
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);
  const [stylesErr, setStylesErr] = useState(false);
  const [materialsErr, setMaterialsErr] = useState(false);
  const [sizesErr, setSizesErr] = useState(false);
  const [colosErr, setColosErr] = useState(false);
  const [varientsCheck, setVarientsCheck] = useState("");
  const [addVarientErr, setAddVarientErr] = useState(false);
  const [visiableBtn, setVisiableBtn] = useState(false);
  const [tagEventErr, setTagEventErr] = useState(false);
  const [tagEventVal, setTagEventVal] = useState("");
  const [tagIconErr, setTagIconErr] = useState(false);
  const [tagIconVal, setTagIconVal] = useState("");
  const colorRef = useRef();

  const [submitData, setSubmitData] = useState({});
  const [viewData, setViewData] = useState({});
  const [varientsList, setVarientList] = useState([]);
  const [vendorPriceList, setVendorPriceList] = useState([]);
  const [decorPriceList, setDecorPriceList] = useState([]);

  // GET PARENT CATEGORY LIST
  const getParentCategoryList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          let temp = res?.categories?.filter((i) => i?.status == 1);
          setParentCategoryList(temp);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  // GET DECORATIVE METHOD
  const getDecorativeList = () => {
    decorativeListApi()
      .unwrap()
      .then((res) => {
        setDecorativeList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET HSN LIST
  const getHsnList = () => {
    hsnListApi()
      .unwrap()
      .then((res) => {
        setHsnList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET TAG LIST
  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setTagsList(res?.normalTag);
        setEventTagsList(res?.eventTag);
        setIconTagsList(res?.iconTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAttributes = () => {
    attributesApi()
      .unwrap()
      .then((res) => {
        setAttributesList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getParentCategoryList();
    getDecorativeList();
    getHsnList();
    getTagsList();
    getAttributes();
  }, []);

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, ind, index, data) => {
    if (type == "category") {
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      setSubCategory(id);
      onSubCatSelect(id, ind, index, data);
    }
  };

  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }
  };

  // VIEW CHID CATEGORY API
  const getView = (id, ind, index, type, data) => {
    viewCategory(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, ind) => {
    setSearchSubValueCategory((val) => ({ ...val, [ind]: "" }));
    let temp = [...subCategoryList];
    temp[ind] = subCategoryLists[ind];
    setSubCategoryList(temp);
    getCategoryId(subItem?.id, "sub_category", indx, ind, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));
    if (ind == 0) {
      setSecondaryCategoryId(subItem?.id);
    } else {
      setCategoryID(subItem?.id);
    }

    setProductSuplierCode(subItem?.code);
  };

  // VARIENT COMBINATION STATUC CHANGE
  const deleteVarient = (ind, index, status) => {
    let temp = [...varientsList];
    let tempx = temp[ind].childs;
    let tem = [...tempx];

    if (status == 1) {
      let obj = {
        ...tem[index],
        status: 0,
      };
      tem[index] = obj;
      tempx = tem;
      temp[ind]["childs"] = tempx;
      setVarientList(temp);
    } else {
      let obj = {
        ...tem[index],
        status: 1,
      };
      tem[index] = obj;
      tempx = tem;
      temp[ind]["childs"] = tempx;
      setVarientList(temp);
    }
  };

  // console.log("subCategoryList", subCategoryList);

  // PRODUCT CREATE
  const submitHandle = () => {
    let check;

    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }

    if (
      primaryCategoryId?.length == 0 ||
      secondaryCategoryId?.length == 0 ||
      categoryID?.length == 0 ||
      name?.length == 0 ||
      gendor?.length == 0 ||
      country?.length == 0 ||
      productPackageSize?.length == 0 ||
      mrp?.length == 0 ||
      hsnCode?.length == 0 ||
      productDescription?.length == 0 ||
      tagVal?.length == 0 ||
      tagEventVal?.length == 0 ||
      tagIconVal?.length == 0 ||
      tagEventVal?.length == "" ||
      tagIconVal?.length == "" ||
      decorationMethod?.length == 0 ||
      productImg?.length == 0 ||
      // morkupImg?.length == 0 ||
      // otherProInfo?.length == 0 ||
      check == true
    ) {
      setPrimaryCategoryIdErr(true);
      setSecondaryCategoryIdErr(true);
      setGendorErr(true);
      setCategoryIDErr(true);
      setNameErr(true);
      setCountryErr(true);
      setProductDescriptionErr(true);
      setProductPackageSizeErr(true);
      setMrpErr(true);
      setHsnCodeErr(true);
      setTagErr(true);
      setTagEventErr(true);
      setTagIconErr(true);
      setDecorationMethodErr(true);
      setProductImgErr(true);
      // setMorkupImgErr(true);
      // setOtherProInfoErr(true);
      setProductInformationErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("primary_category_id", primaryCategoryId);
      formdata.append("secondary_category_id", secondaryCategoryId);
      formdata.append("category_id", categoryID);
      formdata.append("name", name);
      formdata.append("product_for", gendor);
      formdata.append("country_origin", country);
      formdata.append("package_size", productPackageSize);
      formdata.append("mrp", mrp);
      formdata.append("hsn_id", hsnCode);
      formdata.append("description", productDescription);

      let tag_temp = [...tags, ...tagsEvent, ...tagsIcon];

      tag_temp?.map((item, ind) => {
        formdata.append(`tag_ids[${ind}]`, item);
      });

      decorativeID?.map((item, ind) => {
        formdata.append(`decoration_ids[${ind}]`, item);
      });

      productInformation?.map((item, ind) => {
        formdata.append(`attribute_varients[${ind}][attribute_id]`, item?.id);
        formdata.append(`attribute_varients[${ind}][value]`, item?.value);
        // formdata.append(`attribute_id[${ind}]`, item?.id);
        // formdata.append(`value[${ind}]`, item?.value);
      });

      let smallimageArr = [];

      smallimage?.map((item, ind) => {
        if (item?.image?.name) {
          smallimageArr.push(item);
        }
      });

      if (smallimage?.length > 0) {
        smallimage?.map((item, ind) => {
          formdata.append(
            `product_images[${ind}][id]`,
            item?.id ? item?.id : ""
          );
          formdata.append(
            `product_images[${ind}][image]`,
            item?.image?.name ? item?.image : ""
          );
          formdata.append(`product_images[${ind}][description]`, "");
          formdata.append(
            `product_images[${ind}][is_default]`,
            item?.is_default
          );
        });
      }

      let smallMockimageArr = [];

      smallMockimage?.map((item, ind) => {
        if (item?.image?.name) {
          smallMockimageArr.push(item);
        }
      });

      if (smallMockimage?.length > 0) {
        smallMockimage?.map((item, ind) => {
          formdata.append(
            `morkup_images[${ind}][id]`,
            item?.id ? item?.id : ""
          );
          formdata.append(
            `morkup_images[${ind}][image]`,
            item?.image?.name ? item?.image : ""
          );
          formdata.append(`morkup_images[${ind}][description]`, "");
          formdata.append(
            `morkup_images[${ind}][is_default]`,
            item?.is_default
          );
        });
      }

      dispatch(saveLoader(true));
      setBtn(true);
      productCreateApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          setSubmitBtn(1);
          setSubmitData(res?.data);
          toast.success(res?.message);
          // console.log("res", res);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // COMBINATE GENERATE
  const varientCombination = () => {
    let sizeCheck;
    let colorCheck;
    let styleCheck;
    let materialCheck;
    let check;

    sizes?.map((item, ind) => {
      sizeCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    colorsID?.map((item, ind) => {
      colorCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    materials?.map((item, ind) => {
      materialCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    styles?.map((item, ind) => {
      styleCheck = Object.values(item).some((x) => x === " " || x === "");
    });

    colorsData?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    // console.log("sizeCheck", sizeCheck, styleCheck, materialCheck);

    if (
      // (!attributesName?.includes("Varient") && varientsCheck?.length == 0) ||
      (attributesName?.includes("Size") && sizeCheck == true) ||
      (attributesName?.includes("Materials") && materialCheck == true) ||
      (attributesName?.includes("Color") && colorVarient?.length == 0) ||
      (attributesName?.includes("Style") && styleCheck == true) ||
      check == true
    ) {
      // !attributesName?.includes("Varient") && setAddVarientErr(true);
      attributesName?.includes("Size") && setSizesErr(true);
      attributesName?.includes("Color") && setColorVarientErr(true);
      attributesName?.includes("Materials") && setMaterialsErr(true);
      attributesName?.includes("Style") && setStylesErr(true);
      setColorImgErr(true);
    } else {
      let formdata = new FormData();
      let emptyArr = [];
      if (colorsID?.length > 0) {
        colorsID?.map((item, ind) => {
          formdata.append(`master_varients[colors][${ind}]`, item?.id);
        });
      } else {
        formdata.append(`master_varients[colors][${0}]`, emptyArr);
      }

      if (sizes?.length > 0) {
        sizes?.map((item, ind) => {
          formdata.append(`master_varients[sizes][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[sizes][${0}]`, emptyArr);
      }

      if (materials?.length > 0) {
        materials?.map((item, ind) => {
          formdata.append(`master_varients[materials][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[materials][${0}]`, emptyArr);
      }

      if (styles?.length > 0) {
        styles?.map((item, ind) => {
          formdata.append(`master_varients[styles][${ind}]`, item?.value);
        });
      } else {
        formdata.append(`master_varients[styles][${0}]`, emptyArr);
      }

      formdata.append("product_id", submitData?.id);

      dispatch(saveLoader(true));
      setBtn(true);
      productGenerateVarientApi(formdata)
        .unwrap()
        .then((res) => {
          let attribute_variations = res?.attribute_variations;
          let temp = [];
          let arr = [];

          for (let n in attribute_variations) {
            temp.push(n);
          }
          temp.map((item, ind) => {
            let obj = {
              [item]: item,
              parent_name: item,
              childs: attribute_variations[item],
            };

            arr.push(obj);

            // console.log("obj", obj);
          });

          // console.log("arr", arr);
          let childs = [];
          arr.map((item, ind) => {
            childs.push({
              ...item,
              childs: item?.childs?.map((el) => {
                var o = Object.assign({}, el);
                o.status = 1;
                return o;
              }),
            });
          });

          setVarientList(childs);
          setVarientShow(true);
          setSubmitBtn(2);
          dispatch(saveLoader(false));
          setBtn(false);
          // console.log("res", res);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // COMBINATION STORE
  const submitVarient = () => {
    let check;
    colorsData?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    if (check) {
      setColorImgErr(true);
      colorRef.current.focus();
    } else {
      setColorImgErr(false);

      let formdata = new FormData();

      let emptyArr = [];
      if (colorsID?.length > 0) {
        colorsID?.map((item, ind) => {
          formdata.append(`master_varients[colors][${ind}]`, item?.id);
        });
      } else {
        // formdata.append(`master_varients[colors][${0}]`, emptyArr);
      }

      if (sizes?.length > 0) {
        sizes?.map((item, ind) => {
          formdata.append(`master_varients[sizes][${ind}]`, item?.value);
        });
      } else {
        // formdata.append(`master_varients[sizes][${0}]`, emptyArr);
      }

      if (materials?.length > 0) {
        materials?.map((item, ind) => {
          formdata.append(`master_varients[materials][${ind}]`, item?.value);
        });
      } else {
        // formdata.append(`master_varients[materials][${0}]`, emptyArr);
      }

      if (styles?.length > 0) {
        styles?.map((item, ind) => {
          formdata.append(`master_varients[styles][${ind}]`, item?.value);
        });
      } else {
        // formdata.append(`master_varients[styles][${0}]`, emptyArr);
      }

      let colorsDataArr = [];

      colorsData?.map((item, ind) => {
        if (item?.image?.name) {
          colorsDataArr.push(item);
        }
      });

      if (colorsDataArr?.length > 0) {
        colorsDataArr?.map((item, ind) => {
          formdata.append(
            `color_images[${ind}][color_id]`,
            item?.color_id ? item?.color_id : item?.id
          );
          formdata.append(`color_images[${ind}][image]`, item?.image);
          formdata.append(`color_images[${ind}][description]`, "");
        });
      }

      // if (colorsDataArr?.length > 0) {
      //   colorsDataArr?.map((item, ind) => {
      //     formdata.append(`color_images[${ind}][color_id]`, item?.color_id);
      //     formdata.append(`color_images[${ind}][image]`, item?.image);
      //     formdata.append(`color_images[${ind}][description]`, "");
      //     formdata.append(`color_images[${ind}][id]`, item?.id ? item?.id : "");
      //   });
      // }

      varientsList?.map((item, ind) => {
        formdata.append(`product_skus[${ind}][parent_sku]`, item?.parent_name);
        formdata.append(`product_skus[${ind}][status]`, item?.status);
        item?.childs?.map((child, inx) => {
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][color_id]`,
            child?.color_id
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][sku_name]`,
            child?.sku_name
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][sku_code]`,
            child?.sku_code
          );
          formdata.append(
            `product_skus[${ind}][child_sku][${inx}][status]`,
            child?.status
          );
        });
        // item?.childs?.map((child, inx) => {
        //   formdata.append(
        //     `product_skus[${item?.parent_name}][${inx}][color_id]`,
        //     child?.color_id
        //   );
        //   formdata.append(
        //     `product_skus[${item?.parent_name}][${inx}][sku_name]`,
        //     child?.sku_name
        //   );
        //   formdata.append(
        //     `product_skus[${item?.parent_name}][${inx}][sku_code]`,
        //     child?.sku_code
        //   );
        //   formdata.append(
        //     `product_skus[${item?.parent_name}][${inx}][status]`,
        //     child?.status
        //   );
        // });
      });

      dispatch(saveLoader(true));
      setBtn(true);
      formdata.append("product_id", submitData?.id);
      productStoreVarientApi(formdata)
        // productStoreVarientApi(formdata)
        .unwrap()
        .then((res) => {
          submitHandlePrice();
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const nextChanger = () => {
    let check;
    colorsData?.map((item, ind) => {
      check = Object.values(item).some((x) => x === " " || x === "");
    });

    if (check) {
      setColorImgErr(true);
      colorRef.current.focus();
    } else {
      setColorImgErr(false);
      setVisiableBtn(true);
      setTab(1);
    }
  };

  const submitHandlePrice = () => {
    let formdata = new FormData();
    formdata.append("vendor_id", location?.state?.data?.vendor?.id);
    let prices = location?.state?.data?.price;
    let cost = prices?.cost;
    let net = prices?.net;
    let margin = prices?.margin;
    let distributorPrice = prices?.final_price;
    let priceCharge = prices?.charges;

    // BASIC PRICE
    formdata.append(`basicPrices[for_25]`, cost?.for_25);
    formdata.append(`basicPrices[for_50]`, cost?.for_50);
    formdata.append(`basicPrices[for_100]`, cost?.for_100);
    formdata.append(`basicPrices[for_150]`, cost?.for_150);
    formdata.append(`basicPrices[for_250]`, cost?.for_250);
    formdata.append(`basicPrices[for_500]`, cost?.for_500);
    formdata.append(`basicPrices[for_1000]`, cost?.for_1000);

    // NET PRICE
    formdata.append(`netPrices[for_25]`, net?.for_25);
    formdata.append(`netPrices[for_50]`, net?.for_50);
    formdata.append(`netPrices[for_100]`, net?.for_100);
    formdata.append(`netPrices[for_150]`, net?.for_150);
    formdata.append(`netPrices[for_250]`, net?.for_250);
    formdata.append(`netPrices[for_500]`, net?.for_500);
    formdata.append(`netPrices[for_1000]`, net?.for_1000);

    // MARGIN PRICE
    formdata.append(
      `mspPrices[for_25]`,
      margin?.for_25 && margin?.for_25 !== "null" ? margin?.for_25 : ""
    );
    formdata.append(
      `mspPrices[for_50]`,
      margin?.for_50 && margin?.for_50 !== "null" ? margin?.for_50 : ""
    );
    formdata.append(
      `mspPrices[for_100]`,
      margin?.for_100 && margin?.for_100 !== "null" ? margin?.for_100 : ""
    );
    formdata.append(
      `mspPrices[for_150]`,
      margin?.for_150 && margin?.for_150 !== "null" ? margin?.for_150 : ""
    );
    formdata.append(
      `mspPrices[for_250]`,
      margin?.for_250 && margin?.for_250 !== "null" ? margin?.for_250 : ""
    );
    formdata.append(
      `mspPrices[for_500]`,
      margin?.for_500 && margin?.for_500 !== "null" ? margin?.for_500 : ""
    );
    formdata.append(
      `mspPrices[for_1000]`,
      margin?.for_1000 && margin?.for_1000 !== "null" ? margin?.for_1000 : ""
    );

    // DISTRIBUTOR PRICE
    formdata.append(`distributorPrices[vendor_product_code]`, "test123");
    formdata.append(`distributorPrices[status_type]`, 4);
    formdata.append(`distributorPrices[for_25]`, distributorPrice?.for_25);
    formdata.append(`distributorPrices[for_50]`, distributorPrice?.for_50);
    formdata.append(`distributorPrices[for_100]`, distributorPrice?.for_100);
    formdata.append(`distributorPrices[for_150]`, distributorPrice?.for_150);
    formdata.append(`distributorPrices[for_250]`, distributorPrice?.for_250);
    formdata.append(`distributorPrices[for_500]`, distributorPrice?.for_500);
    formdata.append(`distributorPrices[for_1000]`, distributorPrice?.for_1000);

    // PRICE CHARGE PRICE
    if (priceCharge?.length > 0) {
      priceCharge?.map((item, ind) => {
        formdata.append(
          `additionalPrices[${ind}][name]`,
          item?.name ? item?.name : ""
        );
        formdata.append(
          `additionalPrices[${ind}][for_25]`,
          item?.for_25 && item?.for_25 !== "null" ? item?.for_25 : ""
        );
        formdata.append(
          `additionalPrices[${ind}][for_50]`,
          item?.for_50 && item?.for_50 !== "null" ? item?.for_50 : ""
        );
        formdata.append(
          `additionalPrices[${ind}][for_100]`,
          item?.for_100 && item?.for_100 !== "null" ? item?.for_100 : ""
        );
        formdata.append(
          `additionalPrices[${ind}][for_150]`,
          item?.for_150 && item?.for_150 !== "null" ? item?.for_150 : ""
        );
        formdata.append(
          `additionalPrices[${ind}][for_250]`,
          item?.for_250 && item?.for_250 !== "null" ? item?.for_250 : ""
        );
        formdata.append(
          `additionalPrices[${ind}][for_500]`,
          item?.for_500 && item?.for_500 !== "null" ? item?.for_500 : ""
        );
        formdata.append(
          `additionalPrices[${ind}][for_1000]`,
          item?.for_1000 && item?.for_1000 !== "null" ? item?.for_1000 : ""
        );
      });
    }

    formdata.append("product_id", submitData?.id);
    productPriceApi(formdata)
      .unwrap()
      .then((res) => {
        stateChange(3);
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(saveLoader(false));
        setBtn(false);
      });
  };

  const stateChange = (status) => {
    let formdata = new FormData();
    formdata.append("status", status);
    setBtn(true);
    dispatch(saveLoader(true));
    productStatusApi({ payload: formdata, id: location?.state?.data?.id })
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        toast.success(res?.message);
        navigate("/product-library", { state: { type: "product" } });
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // GET COLORS
  const getColors = (colorTemp) => {
    colorsListApi()
      .unwrap()
      .then((res) => {
        // console.log("colorTemp", colorTemp);
        let temp = [];

        if (colorTemp?.length > 0) {
          const map = new Map(colorTemp?.map(({ id, ...rest }) => [id, rest]));
          const result = res?.data?.map((o) => ({ ...o, ...map.get(o.id) }));

          // console.log("result", result);

          result?.map((item, ind) => {
            let obj = {
              ...item,
              image: "",
              color_id: item?.id,
            };
            temp.push(obj);
          });
          setColorsList(temp);
        } else {
          res?.data?.map((item, ind) => {
            let obj = {
              ...item,
              image: "",
              color_id: item?.id,
            };
            temp.push(obj);
          });
          setColorsList(temp);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // VIEW PRODUCTS
  const getViewProduct = (res) => {
    let data = res?.data;
    setViewData(res?.data);
    setCountry(data?.country_origin);
    // setMrp(data?.mrp);
    setProductDescription(data?.description);
    setProductPackageSize(data?.package_size);
    setName(data?.name);
    setGendor(data?.product_for);

    let tagsName = [];
    let tagsID = [];
    let hsnsName = [];
    let hsnsCode = [];
    let DecorationName = [];
    let DecorationID = [];
    let productAttributesName = [];
    let productAttributesID = [];
    let productImagesArr = [];
    let morkupImagesArr = [];
    let colName = [];
    let colID = [];
    let childs = [];
    let colorsDataArr = [];

    if (
      data?.variant_master?.color?.length == 0 &&
      data?.variant_master?.material?.length == 0 &&
      data?.variant_master?.style?.length == 0 &&
      data?.variant_master?.size?.length == 0
    ) {
      setAddVariants(true);
    } else {
      setVarientsCheck(23);
    }

    if (data?.variant_master?.color?.length > 0) {
      setColorVarient(23);
    }

    data?.category?.parentCategory?.map((item, ind) => {
      if (item?.icon) {
        setCatogory(item?.name);
      }
    });

    data?.tags?.map((item, ind) => {
      tagsName.push(item?.name);
      tagsID.push(item?.id);
      setTagVal(item?.id);
    });

    data?.decorative_methods?.map((item, ind) => {
      DecorationName.push(item?.name);
      DecorationID.push(item?.id);
      setDecorationMethod(item?.id);
    });

    hsnsName.push(data?.hsn?.code);
    hsnsCode.push(data?.hsn?.id);

    data?.attributes?.map((item, ind) => {
      productAttributesName.push(item?.name);
      setOtherProInfo(item?.id);
      productAttributesID.push({
        id: item?.id,
        // attribute_id: item?.attribute_id,
        title: item?.name,
        value: item?.json?.value,
      });
    });

    data?.image_urls?.product_images?.map((item, ind) => {
      fetch(item)
        .then((res) => res.blob())
        .then((blob) => {
          productImagesArr.push({
            image: new File([blob], "File name", { type: "image/png" }),
            is_default: 0,
          });
        });

      setProductImg(item);
    });

    data?.morkupImage?.map((item, ind) => {
      morkupImagesArr.push({
        ...item,
        image: item?.image_url,
      });
      setMorkupImg(item?.image_url);
    });

    data?.variant_master?.color?.map((item, ind) => {
      colName.push(item?.name);
    });

    let childData = [];
    data?.product_parent_skus?.map((item, ind) => {
      item?.product_child_skus?.map((chi, inx) => {
        childData.push({
          color_id: chi?.color_id,
          sku_code: chi?.variant_sku,
          sku_name: chi?.variant_name,
          status: chi?.status,
          id: chi?.id,
          variant_sku: item?.variant_sku,
        });
      });

      childs.push({
        [item?.variant_sku]: item?.variant_sku,
        parent_name: item?.variant_sku,
        childs: childData.filter((val) => val.variant_sku == item?.variant_sku),
      });
    });

    data?.image_urls?.color_images?.map((item, ind) => {
      let finder = data?.variant_master?.color?.find(
        (col) => col?.id == item?.color_id
      );
      fetch(item?.image)
        .then((res) => res.blob())
        .then((blob) => {
          colorsDataArr.push({
            color_id: finder?.id,
            id: item?.id,
            code: finder?.code,
            hex_code: finder?.hex_code,
            name: finder?.name,
            image: new File([blob], "File name", { type: "image/png" }),
            status: finder?.status,
            description: item?.description,
            image_url: new File([blob], "File name", { type: "image/png" }),
          });
        });
    });

    // console.log("childs", childs);

    let subCategoryListArr = [];
    let categorys = [];
    let cat = {};

    data?.category?.parentCategory?.map((item, ind) => {
      if (item?.code) {
        setProductSuplierCode(item?.code);
      }

      if (item?.icon) {
        cat.status = item?.status;
        cat.icon = item?.icon;
        cat.id = item?.id;
        cat.name = item?.name;
        setParentCategoryList(item);
      } else {
        categorys.push(item);
      }
    });

    categorys.push({
      icon: data?.category?.icon,
      id: data?.category?.id,
      name: data?.category?.name,
      status: data?.category?.status,
    });

    categorys?.map((item, ind) => {
      let obj = { ...item };
      if (ind == 0) {
        obj.category = cat;
      } else {
        obj.category = categorys[ind - 1];
      }
      subCategoryListArr.push(obj);
    });

    if (data?.variant_master?.size?.length > 0) {
      let Stemp = [];
      data?.variant_master?.size?.map((item, ind) => {
        Stemp.push({ value: item });
      });
      setSizes(Stemp);
    }

    if (data?.variant_master?.material?.length > 0) {
      let Mtemp = [];
      data?.variant_master?.material?.map((item, ind) => {
        Mtemp.push({ value: item });
      });
      setMeterials(Mtemp);
    }

    if (data?.variant_master?.style?.length > 0) {
      let STtemp = [];
      data?.variant_master?.style?.map((item, ind) => {
        STtemp.push({ value: item });
      });
      setStyles(STtemp);
    }

    if (data?.variant_master?.color?.length > 0) {
      let Ctemp = [];
      data?.variant_master?.color?.map((item, ind) => {
        Ctemp.push({
          ...item,
          type: "edit",
        });
      });
      getColors(Ctemp);
      setColorsID(Ctemp);
    } else {
      getColors();
      setColorsID([]);
    }

    let catArrs = data?.category?.parentCategory;

    setPrimaryCategoryId(catArrs?.[0]?.id);
    setSecondaryCategoryId(catArrs?.[1]?.id);
    setCategoryID(data?.category?.id);
    setColorsName(colName);
    setSmallImage(productImagesArr);
    setSmallMockImage(morkupImagesArr);
    setProductInformation(productAttributesID);
    setDynamicAttr(productAttributesName);
    setDecorativeID(DecorationID);
    setDecorativeName(DecorationName);
    setTagsName(tagsName);
    setTags(tagsID);
    setHsnName(hsnsName);
    setHsnCode(hsnsCode);
    setColorsData(colorsDataArr);
    setSubCategoryList(subCategoryListArr);

    subCategoryListArr?.map((item, ind) => {
      setCatogoryName((val) => ({
        ...val,
        [item?.category?.id]: item?.name,
      }));
    });

    if (childs?.length > 0) {
      setVarientShow(true);
      setVarientList(childs);
    }

    // data?.category
  };

  useEffect(() => {
    if (location?.state?.data?.id) {
      getViewProduct(location?.state);
    }
  }, []);

  // navigate("/pricing-page")

  return (
    <div className="dashRightView  p-5 home_section trans">
      <ProductSearchHeader />

      {tab == 0 && (
        <>
          <p className="d-flex ac-jb f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
            Confirm Request
            <span>
              <button
                class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
                onClick={() => setTab(1)}
              >
                Next
              </button>
            </span>
          </p>
          <div className="d-flex as-jb w-100 res-flex gap-5">
            <OverviewTab
              addVariants={addVariants}
              colorsData={colorsData}
              setColorsData={setColorsData}
              selColors={selColors}
              setSelColors={setSelColors}
              parentCategoryList={parentCategoryList}
              getCategoryId={getCategoryId}
              subCategoryList={subCategoryList}
              setSubCategoryList={setSubCategoryList}
              subCategoryLists={subCategoryLists}
              setSubCategoryLists={setSubCategoryLists}
              setParentCategory={setParentCategory}
              decorativeList={decorativeList}
              decorativeID={decorativeID}
              setDecorativeID={setDecorativeID}
              decorativeName={decorativeName}
              setDecorativeName={setDecorativeName}
              country={country}
              setCountry={setCountry}
              hsnList={hsnList}
              hsnCode={hsnCode}
              setHsnCode={setHsnCode}
              hsnName={hsnName}
              setHsnName={setHsnName}
              tagsList={tagsList}
              tagsEventList={tagsEventList}
              tagsIconList={tagsIconList}
              tags={tags}
              setTags={setTags}
              tagsName={tagsName}
              setTagsName={setTagsName}
              tagsEvent={tagsEvent}
              setTagsEvent={setTagsEvent}
              tagsEventName={tagsEventName}
              setTagsEventName={setTagsEventName}
              tagsIcon={tagsIcon}
              setTagsIcon={setTagsIcon}
              tagsIconName={tagsIconName}
              setTagsIconName={setTagsIconName}
              tagEventErr={tagEventErr}
              tagIconErr={tagIconErr}
              colorsList={colorsList}
              colorsID={colorsID}
              setColorsID={setColorsID}
              colorsName={colorsName}
              setColorsName={setColorsName}
              varientCombination={varientCombination}
              varientShow={varientShow}
              sizes={sizes}
              setSizes={setSizes}
              materials={materials}
              setMeterials={setMeterials}
              styles={styles}
              setStyles={setStyles}
              allVarients={allVarients}
              deleteVarient={deleteVarient}
              catogoryName={catogoryName}
              setCatogoryName={setCatogoryName}
              searchValueSubCategory={searchValueSubCategory}
              setSearchSubValueCategory={setSearchSubValueCategory}
              productSuplierCode={productSuplierCode}
              setProductSuplierCode={setProductSuplierCode}
              onSubCategorySelect={onSubCategorySelect}
              categoryID={categoryID}
              setCategoryID={setCategoryID}
              primaryCategoryIdErr={primaryCategoryIdErr}
              secondaryCategoryIdErr={secondaryCategoryIdErr}
              name={name}
              setName={setName}
              mrp={mrp}
              setMrp={setMrp}
              decorationMethod={decorationMethod}
              setDecorationMethod={setDecorationMethod}
              productDescription={productDescription}
              setProductDescription={setProductDescription}
              productPackageSize={productPackageSize}
              setProductPackageSize={setProductPackageSize}
              productPackageSizeErr={productPackageSizeErr}
              setProductPackageSizeErr={setProductPackageSizeErr}
              productDescriptionErr={productDescriptionErr}
              setProductDescriptionErr={setProductDescriptionErr}
              decorationMethodErr={decorationMethodErr}
              setDecorationMethodErr={setDecorationMethodErr}
              mrpErr={mrpErr}
              setMrpErr={setMrpErr}
              nameErr={nameErr}
              setNameErr={setNameErr}
              countryErr={countryErr}
              setCountryErr={setCountryErr}
              categoryIDErr={categoryIDErr}
              setCategoryIDErr={setCategoryIDErr}
              setGendor={setGendor}
              gendor={gendor}
              gendorErr={gendorErr}
              secondaryCategoryId={secondaryCategoryId}
              setSecondaryCategoryId={setSecondaryCategoryId}
              primaryCategoryId={primaryCategoryId}
              setPrimaryCategoryId={setPrimaryCategoryId}
              submitHandle={submitHandle}
              attributesList={attributesList}
              productInformation={productInformation}
              setProductInformation={setProductInformation}
              varientsList={varientsList}
              setVarientList={setVarientList}
              submitVarient={nextChanger}
              setTagVal={setTagVal}
              tagVal={tagVal}
              hsnCodeErr={hsnCodeErr}
              tagErr={tagErr}
              btn={btn}
              submitBtn={submitBtn}
              catogory={catogory}
              setCatogory={setCatogory}
              listAttributes={listAttributes}
              setListAttributes={setListAttributes}
              dynamicAttr={dynamicAttr}
              setDynamicAttr={setDynamicAttr}
              viewData={viewData}
              setSmallImage={setSmallImage}
              setSmallMockImage={setSmallMockImage}
              smallimage={smallimage}
              smallMockimage={smallMockimage}
              productImg={productImg}
              setProductImg={setProductImg}
              productImgErr={productImgErr}
              setProductImgErr={setProductImgErr}
              morkupImg={morkupImg}
              setMorkupImg={setMorkupImg}
              morkupImgErr={morkupImgErr}
              setMorkupImgErr={setMorkupImgErr}
              colorsImg={colorsImg}
              setColorsImg={setColorsImg}
              colorImgErr={colorImgErr}
              setColorImgErr={setColorImgErr}
              getViewProduct={getViewProduct}
              deleteImg={""}
              otherProInfoErr={otherProInfoErr}
              productInformationErr={productInformationErr}
              setOtherProInfo={setOtherProInfo}
              otherProInfo={otherProInfo}
              stylesErr={stylesErr}
              setStylesErr={setStylesErr}
              materialsErr={materialsErr}
              setMaterialsErr={setMaterialsErr}
              sizesErr={sizesErr}
              setSizesErr={setSizesErr}
              colosErr={colosErr}
              setColosErr={setColosErr}
              setAttributesName={setAttributesName}
              attributesName={attributesName}
              colorVarientErr={colorVarientErr}
              setColorVarientErr={setColorVarientErr}
              setColorVarient={setColorVarient}
              colorVarient={colorVarient}
              addVarientErr={addVarientErr}
              setAddVarientErr={setAddVarientErr}
              varientsCheck={varientsCheck}
              setVarientsCheck={setVarientsCheck}
              colorRef={colorRef}
              setTagIconVal={setTagIconVal}
              setTagEventVal={setTagEventVal}
              tagEventVal={tagEventVal}
              tagIconVal={tagIconVal}
              submitData={submitData}
            />
          </div>
        </>
      )}

      {tab == 1 && (
        <div>
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary mt-5">
            Pricing Page
          </p>
          <div className="h-auto mt-4">
            <p class="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary mt-5">
              Vendor Pricing
            </p>
            <div className="">
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-3">
                {location?.state?.data?.vendor?.legal_name}
              </p>
              <div className="overflow-scroll">
                <table className="w-100">
                  <tr className="mt-4">
                    <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Qty
                    </th>
                    <div className="my-3">
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="25"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="50"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="100"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="150"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="250"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="500"
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              type="button"
                              value="1000"
                            />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                  <tr>
                    <th className="primary f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      Net
                    </th>
                    <div className="mt-2">
                      <td>
                        <div className="d-flex w-100">
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="$90.00"
                              value={priceLists?.for_25}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="$85.00"
                              value={priceLists?.for_50}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="$85.00"
                              value={priceLists?.for_100}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="$75.00"
                              value={priceLists?.for_150}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="$75.00"
                              value={priceLists?.for_250}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="$75.00"
                              value={priceLists?.for_500}
                            />
                          </div>
                          <div className="mx-2">
                            <input
                              disabled
                              class="num-cls-btn  f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-center"
                              placeholder="$75.00"
                              value={priceLists?.for_1000}
                            />
                          </div>
                        </div>
                      </td>
                    </div>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex as-jb w-100 h-50 mt-5">
            <button
              class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
              onClick={() => setTab(0)}
            >
              Back
            </button>{" "}
            {visiableBtn && (
              <button
                class={`cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
                disabled={btn}
                onClick={() => submitVarient()}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestAcceptScreen;
