import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PorejectPoppup from "../PODetails/PorejectPoppup";
import SaleRejPopup from "../POEstimateRequestComp/SaleRejPopup";
import moment from "moment/moment";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CachedIcon from "@mui/icons-material/Cached";
import {
  useGetShippingBoxQtyMutation,
  useInventryPriceMutation,
  useLazyBoxNoPageListQuery,
  useLazyMasterNoPageListQuery,
  useShippingPriceMutation,
  useShippingTaxMutation,
  useStoreProjectBoxMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const EstimateShipWarehouse = ({
  getProjectView,
  setAddContComp,
  poppupAddressToggle,
  collaboratClick,
  onCollaborat,
  setTab,
  tab,
  setDynamicAttr,
  dynamicAttr,
  productInformation,
  setProductInformation,
  total_weight,
  settotal_weight,
  how_longi,
  setHow_longi,
  start_datei,
  setStart_datei,
  setType_of_shipping,
  type_of_shipping,
  setshipping_location_id,
  shipping_location_id,
  setshipping_type_id,
  shipping_type_id,
  setFinal,
  final,
  attributesList,
  setAttributesList,
  listAttributes,
  setListAttributes,
  boxList,
  setBoxList,
  otherProInfo,
  setOtherProInfo,
  projectData,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dropDown, setDropDown] = useState(undefined);
  const [type_of_shippingErr, setType_of_shippingErr] = useState(false);
  const [listMaster, setListMaster] = useState({});
  const [shipping_location_idErr, setshipping_location_idErr] = useState(false);
  const [shipping_type_idErr, setshipping_type_idErr] = useState(false);
  const [btns, setBtns] = useState(false);
  const [finalErr, setFinalErr] = useState(false);

  // RTK QUERY
  const [listBoxApi] = useLazyBoxNoPageListQuery();
  const [listMasterApi] = useLazyMasterNoPageListQuery();
  const [getShippingBoxQtyApi] = useGetShippingBoxQtyMutation();
  const [inventryPriceApi] = useInventryPriceMutation();
  const [shippingPriceApi] = useShippingPriceMutation();
  const [storeProjectBoxApi] = useStoreProjectBoxMutation();
  const [shippingTaxApi] = useShippingTaxMutation();

  // OTHER INFORMATION START
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [searchProInfo, setSearchProInfo] = useState("");
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);
  // OTHER INFORMATION END

  // OTHER INFORMATION START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, qty: "" });
        ListName.push(item?.name);
        setOtherProInfo(item?.id);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
      setOtherProInfo("");
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        qty: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");

    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
    setFinal({});
  };

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["qty"] = +event;
    setProductInformation(temp);
    setFinal({});
  };

  // OTHER INFORMATION END

  // input states

  const [how_many_boxesi, setHow_many_boxesi] = useState("");
  const [which_boxi, setWhich_boxi] = useState("FLIP 1");

  const [how_longiErr, setHow_longiErr] = useState(false);
  const [start_dateiErr, setStart_dateiErr] = useState(false);

  const calculateInventory = () => {
    let check;
    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }

    if (
      how_longi?.length == 0 ||
      dynamicAttr?.length == 0 ||
      start_datei?.length == 0 ||
      check == true
    ) {
      setHow_longiErr(true);
      setProductInformationErr(true);
      setOtherProInfoErr(true);
      setStart_dateiErr(true);
    } else {
      let formdata = new FormData();
      let project_code = searchParams?.get("project");
      let split = project_code.split("_");
      formdata.append("project_id", split[1]);
      formdata.append("storing_type", tab);
      formdata.append("number_of_days", how_longi);

      productInformation?.map((item, ind) => {
        formdata.append(`boxes[${ind}][box_id]`, item?.id);
        formdata.append(`boxes[${ind}][qty]`, item?.qty);
      });

      dispatch(saveLoader(true));
      setBtns(true);
      inventryPriceApi(formdata)
        .unwrap()
        .then((res) => {
          setFinal(res?.data);
          dispatch(saveLoader(false));
          setBtns(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtns(false);
          console.log("err", err);
        });
    }
  };

  const calculateShipping = () => {
    let check;
    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }

    if (
      type_of_shipping?.length == 0 ||
      shipping_location_id?.length == 0 ||
      shipping_type_id?.length == 0 ||
      check == true
    ) {
      setType_of_shippingErr(true);
      setshipping_location_idErr(true);
      setshipping_type_idErr(true);
      setProductInformationErr(true);
    } else {
      let formdata = new FormData();
      let project_code = searchParams?.get("project");
      let split = project_code.split("_");
      formdata.append("project_id", split[1]);
      formdata.append("shipping_location_id", shipping_location_id);
      formdata.append("shipping_type_id", shipping_type_id);
      formdata.append("storing_type", type_of_shipping);
      productInformation?.map((item, ind) => {
        formdata.append(`boxes[${ind}][box_id]`, item?.id);
        formdata.append(`boxes[${ind}][qty]`, item?.qty);
      });
      dispatch(saveLoader(true));
      setBtns(true);
      shippingPriceApi(formdata)
        .unwrap()
        .then((res) => {
          setFinal(res?.data);
          dispatch(saveLoader(false));
          setBtns(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtns(false);
          console.log("err", err);
        });
    }
  };

  const submitCalculate = () => {
    if (tab == 3) {
      calculateInventory();
    } else {
      calculateShipping();
    }
  };

  const getMasters = () => {
    listMasterApi()
      .unwrap()
      .then((res) => {
        setListMaster(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMasters();
  }, []);

  const getShippingBoxQty = (storing_type) => {
    if (boxList?.length > 0) {
      if (+storing_type == 3) {
        setAttributesList(boxList);
        setListAttributes(boxList);
      } else {
        let finder = boxList?.filter(
          (i) => i?.box_detail?.type == +storing_type
        );

        setAttributesList(finder);
        setListAttributes(finder);
      }

      let formdata = new FormData();
      let project_code = searchParams?.get("project");
      let split = project_code.split("_");
      formdata.append("project_id", split[1]);
      formdata.append("storing_type", storing_type);
      setType_of_shipping(storing_type);
      dispatch(saveLoader(true));
      setBtns(true);
      getShippingBoxQtyApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtns(false);
          settotal_weight(res?.data);
          let data = res?.data?.project_box;
          let responce = [];
          let temp = [];
          let ListName = [];

          data?.map((item, ind) => {
            responce.push({
              ...item,
              box_detail: {
                ...item.box_detail,
                actual_name: item.box_detail?.name,
                name: `${item.box_detail?.name}-${item.box_detail?.model}-${item.box_detail?.dimension}`,
              },
            });

            ListName.push(
              `${item.box_detail?.name}-${item.box_detail?.model}-${item.box_detail?.dimension}`
            );
          });

          responce?.map((item, ind) => {
            temp.push({
              id: item?.box_id,
              title: item?.box_detail?.name,
              qty: item?.qty,
            });
            setOtherProInfo(item?.box_id);
          });

          // setAttributesList(temp);
          // setListAttributes(temp);

          setProductInformation(temp);
          setDynamicAttr(ListName);
          setFinal({});
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtns(false);
          console.log("err", err);
        });
    }
  };

  const handleChangeType = (event) => {
    if (+event == 3) {
      getShippingBoxQty(3);
      // setAttributesList(boxList);
      // setListAttributes(boxList);
    } else {
      setListAttributes([]);
    }
    setTab(event);
    setProductInformation([]);
    setshipping_location_id("");
    setshipping_type_id("");
    setType_of_shipping("");
    setshipping_location_idErr(false);
    setshipping_type_idErr(false);
    setType_of_shippingErr(false);
    settotal_weight("");
    setProductInformationErr(false);
    setHow_longi("");
    setHow_longiErr(false);
    setOtherProInfoErr(false);
    setDynamicAttr([]);
    setFinal({});
    settotal_weight({});
    setProductInformation([]);
  };

  const submitHandler = () => {
    let formdata = new FormData();
    let project_code = searchParams?.get("project");
    let split = project_code.split("_");
    formdata.append("project_id", split[1]);
    formdata.append("total_price", final?.total_price);
    formdata.append("total_volumetric_weight", total_weight?.total_weight);

    if (tab == 3) {
      formdata.append("storing_type", tab);
      formdata.append("storage_no_of_days", how_longi);
      formdata.append("storage_start_date", start_datei);
    } else {
      formdata.append("storing_type", type_of_shipping);
    }

    final?.price_details?.map((item, ind) => {
      formdata.append(`project_boxes[${ind}][project_id]`, split[1]);
      formdata.append(`project_boxes[${ind}][box_id]`, item?.box_id);
      formdata.append(`project_boxes[${ind}][price]`, item?.price);
      formdata.append(`project_boxes[${ind}][box_count]`, item?.box_count);
      if (item?.shipping_charge_id) {
        formdata.append(
          `project_boxes[${ind}][shipping_charge_id]`,
          item?.shipping_charge_id
        );
      }
    });

    dispatch(saveLoader(true));
    setBtns(true);
    storeProjectBoxApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          shippingTax();
        } else {
          getProjectView();
        }
        toast.success(res?.message);
        dispatch(saveLoader(false));
        setBtns(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtns(false);
        console.log("err", err);
      });
  };

  const shippingTax = () => {
    dispatch(saveLoader(true));
    setBtns(true);
    let formdata = new FormData();
    let project_code = searchParams?.get("project");
    let split = project_code.split("_");
    formdata.append("project_id", split[1]);
    shippingTaxApi(formdata)
      .unwrap()
      .then((res) => {
        getProjectView();
        dispatch(saveLoader(false));
        setBtns(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtns(false);
      });
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  const handleChangeTotalPrice = (event, type) => {
    let final_ = { ...final };
    final_[type] = event;
    setFinal((val) => ({ ...val, [type]: +event }));
    setFinalErr(true);
  };

  // console.log("final", final);

  return (
    <>
      <div className="w-100 mb-3">
        <Col sm={6} md={6} lg={6} className="me-md-3">
          <p
            className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
            style={{ marginLeft: "0px" }}
          >
            Store Type
          </p>

          <select
            className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            value={tab}
            onChange={(e) => handleChangeType(e.target.value)}
            disabled={projectData?.estimation_ready == 0 ? false : true}
          >
            <option disabled value={0}>
              Select
            </option>
            <option value={1}>Shipping</option>
            <option value={3}>Inventory</option>
          </select>
        </Col>
        {/* <div className="mt-3 d-flex ac-jb mt-0 mt-md-3 flex-column flex-md-row">
          <div className="d-flex ac-js">
            <button
              onClick={() => setTab(1)}
              className={`${
                tab == 1 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Shipping
            </button>
            <button
              onClick={() => setTab(2)}
              className={`${
                tab == 2 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Inventory
            </button>
          </div>
        </div> */}
        {tab == 3 ? (
          <div className="d-flex ">
            <div className="d-flex flex-column flex-md-row flex-wrap w-50">
              <div className="mt-2 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Which box
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={
                      dynamicAttr?.length > 0 ? dynamicAttr : "Select Which box"
                    }
                    onClick={(e) => {
                      if (projectData?.estimation_ready == 0) {
                        setDropDown(77);
                      }
                    }}
                    readOnly
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      if (projectData?.estimation_ready == 0) {
                        setDropDown(77);
                      }
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                </div>
                {dropDown == 77 && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropDown == 77 && "submenu_1 "
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchProductInfoFun(e.target.value)}
                      value={searchProInfo}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        allSelectProductInfo();
                      }}
                    >
                      All
                    </button>
                  </div>
                  {listAttributes?.map((item, ind) => {
                    return (
                      <div className="d-flex as-jb hover-cust" key={ind}>
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 "
                          onClick={() => {
                            onDynamicAttributeSelect(
                              item?.box_detail?.name,
                              item?.box_id,
                              ind
                            );
                            setDropDown(null);
                          }}
                        >
                          {item?.box_detail?.name}
                        </button>
                        <button
                          className="px-2 cust-btn text-start"
                          onClick={() => {
                            onDynamicAttributeSelect(
                              item?.box_detail?.name,
                              item?.box_id,
                              ind
                            );
                          }}
                        >
                          {dynamicAttr?.includes(item?.box_detail?.name) ? (
                            <CheckCircleIcon className="primary" />
                          ) : (
                            <RadioButtonUncheckedIcon className="primary" />
                          )}

                          {dynamicAttr?.includes(item?.box_detail?.name)
                            ? item?.check
                            : item.uncheck}
                        </button>
                      </div>
                    );
                  })}
                </div>
                {dynamicAttr?.length == 0 && otherProInfoErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">
                      Select Other Product Information
                    </p>
                  </div>
                )}
              </div>
              <div className="d-flex flex-column">
                {productInformation?.map((item, ind) => {
                  return (
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      key={ind}
                      className={"d-flex mt-2 ac-jc gap-3 mx-3"}
                    >
                      <Col lg={6} md={6} sm={6} xs={6}>
                        <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  text-nowrap ">
                          {item?.title}
                        </p>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={6}>
                        <div>
                          <input
                            placeholder="Qty *"
                            className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                            onChange={(e) =>
                              handleProductInformation(e.target.value, ind)
                            }
                            value={item?.qty}
                            disabled={
                              projectData?.estimation_ready == 0 ? false : true
                            }
                          />

                          {item?.qty?.length == 0 && productInformationErr && (
                            <div className="d-flex gap-1 mt-2">
                              <ErrorIcon className="svg_log" />
                              <p className="err-input_log mt-1">
                                fill Information
                              </p>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Col>
                  );
                })}
              </div>
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Total Volumetric Weight
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="number"
                  value={total_weight?.total_weight}
                  readOnly
                />
              </Col>
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  How Long (days)
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="number"
                  onChange={(e) => setHow_longi(e.target.value)}
                  value={how_longi}
                  disabled={projectData?.estimation_ready == 0 ? false : true}
                />
                {how_longi?.length == 0 && how_longiErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter How Long</p>
                  </div>
                )}
              </Col>
              <Col sm={12} md={12} lg={12} className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                  style={{ marginLeft: "0px" }}
                >
                  Start Date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  type="date"
                  onChange={(e) => setStart_datei(e.target.value)}
                  value={start_datei}
                  disabled={projectData?.estimation_ready == 0 ? false : true}
                />
                {start_datei?.length == 0 && start_dateiErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Start Date</p>
                  </div>
                )}
              </Col>
            </div>
          </div>
        ) : tab == 1 || tab == 2 ? (
          <div className="d-flex flex-column flex-md-row flex-wrap w-50 mt-3">
            <Col sm={12} md={12} lg={12} className="me-md-3">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Type of Shipping
              </p>

              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={type_of_shipping}
                onChange={(e) => getShippingBoxQty(e.target.value)}
                disabled={projectData?.estimation_ready == 0 ? false : true}
              >
                <option disabled value={""}>
                  Select
                </option>
                <option value={1}>Individual</option>
                <option value={2}>Bulk</option>
              </select>
              {type_of_shipping?.length == 0 && type_of_shippingErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Shipping</p>
                </div>
              )}
            </Col>

            <Col sm={12} md={12} lg={12} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Location:
              </p>
              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => {
                  setshipping_location_id(e.target.value);
                  setFinal({});
                }}
                value={shipping_location_id}
                disabled={projectData?.estimation_ready == 0 ? false : true}
              >
                <option disabled value={""}>
                  Select
                </option>
                {listMaster?.shipping_locations?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {shipping_location_id?.length == 0 && shipping_location_idErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Location</p>
                </div>
              )}
            </Col>

            <div className="mt-2 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                style={{ marginLeft: "0px" }}
              >
                Which box
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={
                    dynamicAttr?.length > 0 ? dynamicAttr : "Select Which box"
                  }
                  onClick={(e) => {
                    if (projectData?.estimation_ready == 0) {
                      setDropDown(77);
                    }
                  }}
                  readOnly
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    if (projectData?.estimation_ready == 0) {
                      setDropDown(77);
                    }
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {dropDown == 77 && (
                <div
                  className="invisible-cont2"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 77 && "submenu_1 "
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchProductInfoFun(e.target.value)}
                    value={searchProInfo}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectProductInfo();
                    }}
                  >
                    All
                  </button>
                </div>
                {listAttributes?.map((item, ind) => {
                  return (
                    <div className="d-flex as-jb hover-cust" key={ind}>
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onDynamicAttributeSelect(
                            item?.box_detail?.name,
                            item?.box_id,
                            ind
                          );
                          setDropDown(null);
                        }}
                      >
                        {item?.box_detail?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          onDynamicAttributeSelect(
                            item?.box_detail?.name,
                            item?.box_id,
                            ind
                          );
                        }}
                      >
                        {dynamicAttr?.includes(item?.box_detail?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}

                        {dynamicAttr?.includes(item?.box_detail?.name)
                          ? item?.check
                          : item.uncheck}
                      </button>
                    </div>
                  );
                })}
              </div>
              {otherProInfo?.length == 0 && otherProInfoErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Select Other Product Information
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex flex-column">
              {productInformation?.map((item, ind) => {
                return (
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={ind}
                    className={"d-flex mt-2 ac-jc gap-3 mx-3"}
                  >
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  text-nowrap ">
                        {item?.title}
                      </p>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <div>
                        <input
                          placeholder="Qty *"
                          className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                          onChange={(e) =>
                            handleProductInformation(e.target.value, ind)
                          }
                          value={item?.qty}
                          disabled={
                            projectData?.estimation_ready == 0 ? false : true
                          }
                        />

                        {item?.qty?.length == 0 && productInformationErr && (
                          <div className="d-flex gap-1 mt-2">
                            <ErrorIcon className="svg_log" />
                            <p className="err-input_log mt-1">
                              fill Information
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Col>
                );
              })}
            </div>

            <Col sm={12} md={12} lg={12} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Shipping mode:
              </p>

              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => {
                  setshipping_type_id(e.target.value);
                  setFinal({});
                }}
                value={shipping_type_id}
                disabled={projectData?.estimation_ready == 0 ? false : true}
              >
                <option disabled value={""}>
                  Select
                </option>
                {listMaster?.shipping_types?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {shipping_type_id?.length == 0 && shipping_type_idErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Shipping mode</p>
                </div>
              )}
            </Col>
            <Col sm={12} md={12} lg={12} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                {/* Actual Weight Per Box (kg): */}
                Total Volumetric Weight
              </p>

              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="number"
                value={total_weight?.total_weight}
                readOnly
              />
            </Col>
          </div>
        ) : null}

        {tab !== 0 && (
          <div className="mt-3 mb-3">
            {projectData?.estimation_ready == 0 && (
              <button
                className={`cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded ${
                  btns ? "opacity-50" : ""
                }`}
                onClick={() => submitCalculate()}
              >
                Calculate
              </button>
            )}
          </div>
        )}

        {final?.price_details?.length > 0 && (
          <div>
            <Col sm={6} md={6} lg={6} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2 d-flex ac-jb"
                style={{ marginLeft: "0px" }}
              >
                Total Price
                {finalErr && (
                  <button
                    className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
                    onClick={() => submitCalculate()}
                  >
                    <CachedIcon />
                  </button>
                )}
              </p>
              {/* <p className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                {commaValue(final?.total_price)}
              </p> */}
              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="number"
                value={final?.total_price}
                onChange={(e) =>
                  handleChangeTotalPrice(e.target.value, "total_price")
                }
                disabled={projectData?.estimation_ready == 0 ? false : true}
              />
            </Col>
            <div className="mt-3 mb-3">
              <button
                className={`cust-btn addbtn  addbtn_save  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 mx-2 rounded ${
                  btns ? "opacity-50" : ""
                }`}
                onClick={() => submitHandler()}
                disabled={
                  btns
                    ? true
                    : projectData?.estimation_ready == 1
                    ? true
                    : false
                }
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EstimateShipWarehouse;
