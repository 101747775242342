import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageUplodeComp from "../AddNewProductComps/ImageUplodeComp";
import FormComp from "../AddNewProductComps/FormComp";
import { Col } from "react-bootstrap";
import { currency_list, DefaultTax, sub_menu } from "../../redux/api/DummyJson";
import PorejectPoppup from "../PODetails/PorejectPoppup";
import SaleRejPopup from "../POEstimateRequestComp/SaleRejPopup";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../../redux/slice/alertMessage";
import { useEstimateDiscountMutation } from "../../redux/api/api";
import { saveLoader } from "../../redux/slice/loaderNoti";

const EstimateHeadComp = ({
  statusChangeHandler,
  statusDrop,
  showAddress,
  setAddContComp,
  poppupAddressToggle,
  collaboratClick,
  onCollaborat,
  handleProductInformation,
  productInformationErr,
  productInformation,
  dynamicAttr,
  listBillingAddress,
  setListBillingAddress,
  AddressBillingList,
  listShippingAddress,
  setListShippingAddress,
  AddressShippingList,
  searchProductInfoFun,
  searchProInfo,
  allSelectProductInfo,
  listAttributes,
  onDynamicAttributeSelect,
  otherProInfo,
  otherProInfoErr,
  setIntroduction,
  introduction,
  introductionErr,
  setTerms_and_conditions,
  terms_and_conditions,
  terms_and_conditionsErr,
  setin_hand_date,
  in_hand_date,
  in_hand_dateErr,
  setclosing_date,
  closing_dateErr,
  closing_date,
  setestimate_date,
  estimate_date,
  estimate_dateErr,
  setDefaultTax,
  defaultTax,
  defaultTaxs,
  defaultTaxErr,
  setcustomer_po,
  customer_po,
  setBillingAddress,
  billingAddress,
  mainShipAddress,
  setMainShipAddress,
  taxChanger,
  setGSTType,
  gstType,
  gstTypes,
  gstTypeErr,
  setDiscountType,
  discountType,
  discountTypes,
  discountTypeErr,
  projectData,
  getProjectView,
  changeGSTtype,
}) => {
  const [dropDown, setDropDown] = useState(undefined);
  const dispatch = useDispatch();

  // RTK QUERY
  const [estimateDiscountApi] = useEstimateDiscountMutation();

  // input states
  const [billingAddress2, setBillingAddress2] = useState("");
  const [mainShipAddress2, setMainShipAddress2] = useState("");
  const [terms, setTerms] = useState("");
  const [currency, setCurrency] = useState("");
  const [statust, setStatust] = useState("");
  const [salestatus, setSaleStatus] = useState("");
  const [otherText, setOtherText] = useState([]);

  const status = [
    {
      list: "Artwork Completed",
    },
    { list: "Artwork Approved" },
    {
      list: "Artwork Rejected",
    },

    { list: "Artwork Request" },
  ];
  const sale_status = [
    {
      list: "In process",
    },
    { list: "Accepted" },
    {
      list: "Rejected",
    },

    { list: "Move to sales order" },
  ];
  const statusset = (e) => {
    setSaleStatus(e.target.value);
    if (e.target.value == "Rejected") {
      setRejectPop(true);
    } else {
      setRejectPop(false);
    }
  };

  const statushandl = (e) => {
    setStatust(e.target.value);
    if (e.target.value == "Artwork Rejected") {
      setRejectPoppup(true);
    } else {
      setRejectPoppup(false);
    }
  };

  const [rejectpoppup, setRejectPoppup] = useState(false);
  const [rejectup, setRejectPop] = useState(false);

  const rejPopFun = () => {
    setRejectPoppup(!rejectpoppup);
  };
  const rejectFun = () => {
    setRejectPop(!rejectup);
  };

  const addOtherInfo = (text) => {
    if (+text !== 0) {
      let temp = [...otherText];
      temp.push(text);
      setOtherText(temp);
    }
  };

  const [searchBilling, setSearchBilling] = useState("");
  const [searchShipping, setSearchShipping] = useState("");
  const searchBillingFun = (event) => {
    let temp = [...AddressBillingList];
    let search = temp.filter((value) =>
      value?.address1?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListBillingAddress(search);
    } else {
      setListBillingAddress(AddressBillingList);
    }

    setSearchBilling(event);
  };

  const searchShippingFun = (event) => {
    let temp = [...AddressShippingList];
    let search = temp.filter((value) =>
      value?.address1?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListShippingAddress(search);
    } else {
      setListShippingAddress(AddressShippingList);
    }

    setSearchShipping(event);
  };

  const taxTypeHandler = (event) => {
    // dispatch(saveAlertMessage("one time only change this type"));
    setDefaultTax(event);
    if (defaultTaxs == 1 || defaultTaxs == 2) {
      taxChanger(event);
    }
  };

  const discountEstimate = (event) => {
    if (discountTypes !== 0) {
      dispatch(saveLoader(true));
      let formdata = new FormData();
      formdata.append("discount_type", event);
      formdata.append("project_id", projectData?.id);
      estimateDiscountApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          getProjectView();
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
    setDiscountType(event);
  };

  const gstTypeChanger = (event) => {
    if (gstTypes) {
      changeGSTtype(event);
    }

    setGSTType(event);
  };

  return (
    <>
      {rejectpoppup && (
        <PorejectPoppup
          rejPopFun={rejPopFun}
          setRejectPoppup={setRejectPoppup}
        />
      )}
      <div className="w-100">
        <div className="w-100 d-flex ac-je">
          <button
            onClick={onCollaborat}
            className="d-flex m-3 pointerView btn_border py-2 px-3 bg-transparent f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
          >
            - {collaboratClick ? "Collapse Info" : "Expand Info"}
          </button>
        </div>
        <div className="d-flex ">
          <div className="d-flex flex-column flex-md-row flex-wrap w-50">
            <Col sm={12} md={12} lg={4} className="me-md-3">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Status
              </p>

              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => statusChangeHandler(e.target.value)}
                value={statusDrop}
                disabled={projectData?.estimation_ready == 0 ? false : true}
              >
                <option value={0}>Over All</option>
                <option value={1}>In Process</option>
                <option value={2}>Estimate Ready</option>
                <option value={3}>Estimate Accepted</option>
                <option value={4}>Estimate Rejected</option>
              </select>
            </Col>
            <Col sm={12} md={12} lg={4} className="">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Estimate Date
              </p>

              <input
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                type="date"
                onChange={(e) => setestimate_date(e.target.value)}
                value={estimate_date}
                disabled={projectData?.estimation_ready == 0 ? false : true}
              />
              {estimate_date?.length == 0 && estimate_dateErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Estimate Date</p>
                </div>
              )}
            </Col>
          </div>
          {rejectup && (
            <SaleRejPopup rejectFun={rejectFun} setRejectPop={setRejectPop} />
          )}

          {/* <Col sm={4} md={4} lg={2} className="">
            <div className="d-flex flex-column flex-md-row flex-wrap m-2">
              <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Sales Status
              </p>
              {/* <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"> 
              <option>In Process</option>
              <select
                placeholder="sale_status"
                onChange={statusset}
                value={salestatus}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              >
                {sale_status?.map((item, ind) => {
                  return (
                    <option key={item?.list} value={item?.list}>
                      {item?.list}
                    </option>
                  );
                })}
                {/* <option>Accepted</option>
                <option>Rejected</option>
                <option>Move to sales order</option> 
              </select>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} className="">
            <div className="d-flex flex-column flex-md-row flex-wrap m-2">
              <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Mockup Status
              </p>
              <select
                placeholder="Status"
                onChange={statushandl}
                value={statust}
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              >
                {status?.map((item, ind) => {
                  return (
                    <option key={item?.list} value={item?.list}>
                      {item?.list}
                    </option>
                  );
                })}
                {/* <option>Rejected</option>
            <option>In process</option>
            <option>Request</option> 
              </select>
            </div>
          </Col> */}
        </div>
        {collaboratClick && (
          <>
            <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                {" "}
                Introduction
              </p>
              <textarea
                type="text"
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                onChange={(e) => setIntroduction(e.target.value)}
                value={introduction}
                disabled={projectData?.estimation_ready == 0 ? false : true}
              />
              {introduction?.length == 0 && introductionErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Introduction</p>
                </div>
              )}
            </div>
            <div className="w-100 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
              {" "}
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                style={{ marginLeft: "0px" }}
              >
                Terms and conditions
              </p>
              <textarea
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                placeholder=""
                rows={2}
                onChange={(e) => setTerms_and_conditions(e.target.value)}
                value={terms_and_conditions}
                disabled={projectData?.estimation_ready == 0 ? false : true}
              />
              {terms_and_conditions?.length == 0 && terms_and_conditionsErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Enter Terms and Conditions
                  </p>
                </div>
              )}
            </div>
          </>
        )}
        {collaboratClick && (
          <>
            <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p
                className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                style={{ marginLeft: "0px" }}
              >
                Other Information
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  value={
                    dynamicAttr?.length > 0
                      ? dynamicAttr
                      : "Select Other Information"
                  }
                  onClick={(e) => {
                    if (projectData?.estimation_ready == 0) {
                      setDropDown(77);
                    }
                  }}
                  readOnly
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    if (projectData?.estimation_ready == 0) {
                      setDropDown(77);
                    }
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
              </div>
              {dropDown == 77 && (
                <div
                  className="invisible-cont2"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 77 && "submenu_1 "
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchProductInfoFun(e.target.value)}
                    value={searchProInfo}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectProductInfo();
                    }}
                  >
                    All
                  </button>
                </div>
                {listAttributes?.map((item, ind) => {
                  return (
                    <div className="d-flex as-jb hover-cust" key={ind}>
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onDynamicAttributeSelect(item?.name, item?.id, ind);
                          setDropDown(null);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        onClick={() => {
                          onDynamicAttributeSelect(item?.name, item?.id, ind);
                        }}
                      >
                        {dynamicAttr?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}

                        {dynamicAttr?.includes(item?.list)
                          ? item?.check
                          : item.uncheck}
                      </button>
                    </div>
                  );
                })}
              </div>
              {otherProInfo?.length == 0 && otherProInfoErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">
                    Select Other Product Information
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex gap-3 flex-wrap">
              {productInformation?.map((item, ind) => {
                return (
                  <Col lg={5} md={5} sm={5} xs={12} key={ind}>
                    <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                      {item?.title}
                    </p>
                    <input
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) =>
                        handleProductInformation(e.target.value, ind)
                      }
                      value={item?.value}
                      disabled={
                        projectData?.estimation_ready == 0 ? false : true
                      }
                    />

                    {item?.value?.length == 0 && productInformationErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">fill Information</p>
                      </div>
                    )}
                  </Col>
                );
              })}
            </div>
          </>
        )}
        {collaboratClick && (
          <div className="w-100 d-flex as-jb gap-3 flex-column flex-md-row">
            <div className="d-flex as-jb gap-3 w-100 w-md-50 res-flex">
              {/* <div className=''> */}
              <div className="w-100">
                <div className="w-100 drop-dow position-relative ">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Billing Address
                  </p>
                  <div className="d-flex position-relative">
                    <input
                      type="text"
                      value={billingAddress?.address1}
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down3 cust-btn "
                      onClick={() => {
                        setDropDown(1);
                      }}
                    >
                      <KeyboardArrowDownIcon className="primary" />
                    </button>

                    <input
                      onClick={() => {
                        if (projectData?.estimation_ready == 0) {
                          showAddress(2);
                        }
                      }}
                      type="button"
                      value={"+"}
                      disabled={
                        projectData?.estimation_ready == 0 ? false : true
                      }
                      // disabled={listBillingAddress?.length <= 0 ? false : true}
                      placeholder="Required Field"
                      //   className={`editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0
                      //  ${listBillingAddress?.length > 0 ? "opacity-50" : ""} `}
                      className={`editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0`}
                    />

                    {/* <div className='ms-2 bg-transparent editBtn rounded-2 d-flex ac-jc'><p className='p-3 py-0 primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16'>+</p></div> */}
                  </div>
                  {dropDown === 1 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 1 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchBillingFun(e.target.value)}
                        value={searchBilling}
                      />
                    </div>
                    {listBillingAddress?.map((item, ind) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 cust-btn text-start py-1 w-100"
                            onClick={() => {
                              setBillingAddress(item);
                              setDropDown(undefined);
                              setSearchBilling("");
                            }}
                          >
                            {item?.address1}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {billingAddress?.address1 && (
                  <>
                    {" "}
                    <div className="w-100 drop-dow mt-3">
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {billingAddress?.address1}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {billingAddress?.address2}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {billingAddress?.landmark}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {billingAddress?.city}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {billingAddress?.state}{" "}
                        {billingAddress?.country
                          ? " , " + billingAddress?.country
                          : ""}
                      </p>
                    </div>
                    <div className="w-100 drop-dow mt-3">
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {billingAddress?.email_id}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        W: {billingAddress?.phone_no}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="w-100">
                <div className="w-100 drop-dow position-relative ">
                  <p
                    className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                    style={{ marginLeft: "0px" }}
                  >
                    Main Shipping Address
                  </p>
                  <div className="d-flex position-relative">
                    <input
                      type="text"
                      value={mainShipAddress?.address1}
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    />
                    <button
                      className="drop_down3 cust-btn "
                      onClick={() => setDropDown(2)}
                    >
                      <KeyboardArrowDownIcon className="primary" />
                    </button>
                    <input
                      onClick={() => {
                        if (projectData?.estimation_ready == 0) {
                          showAddress(1);
                        }
                      }}
                      disabled={
                        projectData?.estimation_ready == 0 ? false : true
                      }
                      type="button"
                      value={"+"}
                      placeholder="Required Field"
                      className="editBtn rounded-3 ms-2 mt-2 px-3 bg-transparent primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 z-0"
                    />
                  </div>
                  {dropDown === 2 && (
                    <div
                      className="invisible-cont2 z-1"
                      onClick={() => setDropDown(undefined)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown === 2 && "submenu_1"
                    } submenu_cont_1 overflow-scroll z-2`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchShippingFun(e.target.value)}
                        value={searchShipping}
                      />
                    </div>
                    {listShippingAddress?.map((item, ind) => {
                      return (
                        <div className="d-flex hover-cust">
                          <button
                            className="px-2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 cust-btn text-start py-1 w-100"
                            onClick={() => {
                              setMainShipAddress(item);
                              setDropDown(undefined);
                              setSearchShipping("");
                            }}
                          >
                            {item?.address1}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {mainShipAddress?.address1 && (
                  <>
                    {" "}
                    <div className="w-100 drop-dow mt-3">
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {mainShipAddress?.address1}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {mainShipAddress?.address2}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {mainShipAddress?.landmark}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {mainShipAddress?.city}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {mainShipAddress?.state}{" "}
                        {mainShipAddress?.country
                          ? " , " + mainShipAddress?.country
                          : ""}
                      </p>
                    </div>
                    <div className="w-100 drop-dow mt-3">
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        {mainShipAddress?.email_id}
                      </p>
                      <p className="black f1 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                        W: {mainShipAddress?.phone_no}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex as-jb gap-3 flex-column flex-md-row w-100 w-md-50 res-flex">
              <div className="w-100">
                <div className="d-flex flex-wrap ac-jb flex-m-r">
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}
                    >
                      Currency
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        value={"INR"}
                        placeholder="Required Field"
                        disabled
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                      />
                      {/* <button
                      className="drop_down cust-btn"
                      //   onClick={() => setDropDown(6)}
                    >
                      <KeyboardArrowDownIcon />
                    </button> */}
                    </div>
                    {dropDown === 6 && (
                      <div
                        className="invisible-cont2 z-1"
                        onClick={() => setDropDown(undefined)}
                      />
                    )}
                    <div
                      className={`${
                        dropDown === 6 && "submenu_1 h-auto"
                      } submenu_cont_1 overflow-scroll z-2`}
                    >
                      {currency_list?.map((item) => {
                        return (
                          <div className="d-flex hover-cust">
                            <button
                              className="px-2 cust-btn text-start py-1 w-100 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 f2 "
                              onClick={() => {
                                setCurrency(item?.list);
                                setDropDown(undefined);
                              }}
                            >
                              {item?.list}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}
                    >
                      In Hands Date
                    </p>
                    <div className="position-relative">
                      <input
                        type="date"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onChange={(e) => setin_hand_date(e.target.value)}
                        value={in_hand_date}
                        disabled={
                          projectData?.estimation_ready == 0 ? false : true
                        }
                      />
                      {in_hand_date?.length == 0 && in_hand_dateErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Select In Hands Date
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}
                    >
                      Expiry Date
                    </p>
                    <div className="position-relative">
                      <input
                        type="date"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onChange={(e) => setclosing_date(e.target.value)}
                        value={closing_date}
                        disabled={
                          projectData?.estimation_ready == 0 ? false : true
                        }
                      />
                      {closing_date?.length == 0 && closing_dateErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Select Expiry Date
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}
                    >
                      Default Margin
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        disabled={
                          projectData?.estimation_ready == 0 ? false : true
                        }
                      />
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}
                    >
                      Tax Type
                    </p>
                    <select
                      placeholder="Required Field"
                      className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      onChange={(e) => taxTypeHandler(e.target.value)}
                      value={defaultTax}
                      disabled={
                        projectData?.estimation_ready == 0 ? false : true
                      }
                      // disabled={defaultTaxs ? true : false}
                    >
                      <option hidden>Select Tax Type</option>
                      <option value={2}>Highest tax</option>
                      <option value={1}>Individual tax</option>
                    </select>
                    {defaultTax?.length == 0 && defaultTaxErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select Tax Type</p>
                      </div>
                    )}
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                      GST Type
                    </p>
                    <div className="mt-2 d-flex gap-3">
                      <div className="d-flex">
                        <input
                          type={"radio"}
                          className="mx-1"
                          checked={gstType == 1 ? true : false}
                          onClick={() => gstTypeChanger(1)}
                          disabled={
                            projectData?.estimation_ready == 0 ? false : true
                          }
                        />
                        <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                          Intercity
                        </span>
                      </div>
                      <div className="d-flex">
                        <input
                          type={"radio"}
                          className="mx-1"
                          checked={gstType == 2 ? true : false}
                          onClick={() => gstTypeChanger(2)}
                          disabled={
                            projectData?.estimation_ready == 0 ? false : true
                          }
                        />
                        <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                          Intracity
                        </span>
                      </div>
                    </div>
                    {gstType == 0 && gstTypeErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">Select GST Type</p>
                      </div>
                    )}
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p
                      className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2"
                      style={{ marginLeft: "0px" }}
                    >
                      Customer PO
                    </p>
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onChange={(e) => setcustomer_po(e.target.value)}
                        value={customer_po}
                        disabled={
                          projectData?.estimation_ready == 0 ? false : true
                        }
                      />
                    </div>
                  </div>
                  <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
                    <p className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start mt-2">
                      Discount Type
                    </p>
                    <div className="mt-2 d-flex gap-3">
                      <div className="d-flex">
                        <input
                          type={"radio"}
                          className="mx-1"
                          checked={discountType == 2 ? true : false}
                          onClick={() => discountEstimate(2)}
                          disabled={
                            projectData?.estimation_ready == 0 ? false : true
                          }
                        />
                        <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                          Overall
                        </span>
                      </div>
                      <div className="d-flex">
                        <input
                          type={"radio"}
                          className="mx-1"
                          checked={discountType == 1 ? true : false}
                          onClick={() => discountEstimate(1)}
                          disabled={
                            projectData?.estimation_ready == 0 ? false : true
                          }
                        />
                        <span className="f2 f1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                          Individual
                        </span>
                      </div>
                    </div>
                    {discountType == 0 && discountTypeErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Select Discount Type
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* </div> */}
      </div>
    </>
  );
};

export default EstimateHeadComp;
