import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Steper = ({ stepsConfig = [], projectData }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });
  const stepRef = useRef([]);

  // 0 => Rejected, 1 => overview, 2 => collaborate, 3 => presentation, 4 => show to customer 5 => estimates, 6 => production artwork, 7 => sales order

  useEffect(() => {
    if (projectData?.status >= 4) {
      setCurrentStep(+projectData?.status - 1);
    } else {
      setCurrentStep(projectData?.status);
    }
  }, [projectData?.status]);

  // useEffect(() => {
  //   setCurrentStep(3);
  // }, [projectData?.status]);

  useEffect(() => {
    setMargins({
      marginLeft: stepRef.current[0].offsetWidth / 2,
      marginRight: stepRef.current[stepsConfig.length - 1].offsetWidth / 2,
    });
  }, [stepRef, stepsConfig.length]);

  if (!stepsConfig.length) {
    return <></>;
  }

  const handleNext = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === stepsConfig.length) {
        setIsComplete(true);
        return prevStep;
      } else {
        return prevStep + 1;
      }
    });
  };

  const calculateProgressBarWidth = () => {
    return ((currentStep - 1) / (stepsConfig.length - 1)) * 100;
  };

  const ActiveComponent = stepsConfig[currentStep - 1]?.Component;

  return (
    <div className="custom-stepper mt-3">
      <div className="stepper">
        {stepsConfig.map((step, index) => {
          return (
            <>
              <div
                key={step.name}
                ref={(el) => (stepRef.current[index] = el)}
                className={`step ${
                  projectData?.status > 1 && step?.name == "Overview" ? (
                    "complete"
                  ) : projectData?.status == 1 && step?.name == "Overview" ? (
                    "active"
                  ) : projectData?.status > 2 &&
                    projectData?.presentation_ready == 1 &&
                    step?.name == "Presentation" ? (
                    "complete"
                  ) : projectData?.status > 2 &&
                    projectData?.presentation_ready == 0 &&
                    step?.name == "Presentation" ? (
                    "active"
                  ) : projectData?.status > 4 &&
                    projectData?.estimation_ready == 1 &&
                    step?.name == "Estimate" ? (
                    "complete"
                  ) : projectData?.status > 4 &&
                    projectData?.estimation_ready == 0 &&
                    step?.name == "Estimate" ? (
                    "active"
                  ) : projectData?.status == 7 &&
                    step?.name == "Sales Order" ? (
                    "active"
                  ) : projectData?.status > 7 && step?.name == "Sales Order" ? (
                    "complete"
                  ) : (
                    <span>&#128078;</span>
                  )
                }`}
              >
                <div className="step-number">
                  {projectData?.status > 1 && step?.name == "Overview" ? (
                    <span>&#128077;</span>
                  ) : projectData?.status == 1 && step?.name == "Overview" ? (
                    <span>&#9995;</span>
                  ) : projectData?.status > 2 &&
                    projectData?.presentation_ready == 1 &&
                    step?.name == "Presentation" ? (
                    <span>&#128077;</span>
                  ) : projectData?.status > 2 &&
                    projectData?.presentation_ready == 0 &&
                    step?.name == "Presentation" ? (
                    <span>&#9995;</span>
                  ) : projectData?.status > 4 &&
                    projectData?.estimation_ready == 1 &&
                    step?.name == "Estimate" ? (
                    <span>&#128077;</span>
                  ) : projectData?.status > 4 &&
                    projectData?.estimation_ready == 0 &&
                    step?.name == "Estimate" ? (
                    <span>&#9995;</span>
                  ) : projectData?.status == 7 &&
                    step?.name == "Sales Order" ? (
                    <span>&#9995;</span>
                  ) : projectData?.status > 7 && step?.name == "Sales Order" ? (
                    <span>&#128077;</span>
                  ) : (
                    <span>&#128078;</span>
                  )}
                </div>
                <div className="step-name">{step.name}</div>
              </div>
            </>
          );
        })}

        {/* <div
          className="progress-bar"
          style={{
            width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
            marginLeft: margins.marginLeft,
            marginRight: margins.marginRight,
          }}
        >
          <div
            className="progress"
            style={{ width: `${calculateProgressBarWidth()}%` }}
            // style={{ width: `${40}%` }}
          ></div>
        </div> */}
      </div>

      {/* <ActiveComponent /> 
      {!isComplete && (
        <button className="btn" onClick={handleNext}>
          {currentStep === stepsConfig.length ? "Finish" : "Next"}
        </button>
      )} */}
    </div>
  );
};

export default Steper;
