import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { product, profilePic } from "../../assets/img";
import ProductSearchHeader from "../ProductComp/ProductSearchHeader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AddProjectPopup from "../Popup/AddProjectPopup";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import AddFilesPopup from "../Popup/AddFilesOverview";
import NavbarHead from "../Sub_Layout/NavbarHead";
import IosShareIcon from "@mui/icons-material/IosShare";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { overview_input1, overview_input2 } from "../../redux/api/DummyJson";
import EditShippingPoppup from "../ShippingPopup/EditShippingPoppup";
import moment from "moment/moment";
import OverviewFiles from "./OverviewFiles";
import AddFilesPopupCopy from "../Popup/AddFilesOverviewCopy";
// import { viewIcon } from "../../assets/img";
import {
  DocIcon,
  editIcon,
  fileDocIcon,
  viewIcon,
  xlsDocIcon,
} from "../../assets/img";
import Steper from "../Popup/Steper";
import {
  useAdminListCustomerReqMutation,
  useLazyCustomerRequestListTagsQuery,
  useLazyMasterNoPageListQuery,
  useLazyProjectViewQuery,
  useProjectUpdateMutation,
  useProducttaskaddMutation,
  useLazyProjectsListQuery,
  useDeparmentListMutation,
  useActivityAddMutation,
  useActivityListMutation,
  useCommandsaddMutation,
  useActivityPinMutation,
  useLazyProducttaskViewQuery,
  useLazyNewCategoryViewQuery,
  useCategoryProductViewNewMutation,
  useLazyCategoriesQuery,
  useMasterActiveListMutation,
  useProjectStatusChangeMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-hot-toast";
import OverViewLostPopup from "../Popup/OverViewLostPopup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const OverView = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.data?.id;
  const formdata = location.state?.formdata;
  // console.log(formdata, "formdata");
  const [searchParams, setSearchParams] = useSearchParams();

  // RTK QUERY
  const [projectViewApi] = useLazyProjectViewQuery();
  const [viewApi] = useLazyProducttaskViewQuery();
  const [masterApi] = useLazyMasterNoPageListQuery();
  const [AdminListApi] = useAdminListCustomerReqMutation();
  const [tagList] = useLazyCustomerRequestListTagsQuery();
  const [projectUpdateApi] = useProjectUpdateMutation();
  const [taskAddApi] = useProducttaskaddMutation();
  const [projectslistApi] = useLazyProjectsListQuery();
  const [userApi] = useDeparmentListMutation();
  const [commandsApi] = useCommandsaddMutation();
  const [activeMasterListApi] = useMasterActiveListMutation();
  const [pinApi] = useActivityPinMutation();
  const [viewCategoryApi] = useLazyNewCategoryViewQuery();
  const [viewCategory] = useCategoryProductViewNewMutation();
  const [listCategory] = useLazyCategoriesQuery();
  const [projectStatusChangeApi] = useProjectStatusChangeMutation();

  const [btnChange, setBtnChange] = useState(0);
  const [btnFiles, setBtnFiles] = useState(0);
  const [comment, setComment] = useState("");
  const [commentBox, setCommentBox] = useState(null);
  const [commentList, setCommentList] = useState([]);

  const [currencyBtn, setCurrencyBtn] = useState(false);
  const [LostBtn, setLostBtn] = useState(false);
  const [LostOption, setLostOption] = useState(1);
  const [taskBtn, setTaskBtn] = useState(false);
  const [productBtn, setProductBtn] = useState(false);
  const [selectFiles, setSelectFiles] = useState(false);
  const [btn, setBtn] = useState(false);
  const [post, setPost] = useState("");
  const [actionBtn, setActionBtn] = useState(false);
  const [otherText, setOtherText] = useState([]);
  const [masterList, setMasterList] = useState({});
  const [adminList, setAdminList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [productionList, setProductionList] = useState([]);
  const [tagNormalList, setTagNormalList] = useState([]);
  const [tagNormalsList, setTagNormalsList] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [descriptions, setDescriptions] = useState("");

  const [dropDown, setDropDown] = useState(null);
  const [partnerName, setPartnerName] = useState("");
  const [partnerID, setPartnerID] = useState("");
  const [partnerIDErr, setPartnerIDErr] = useState(false);
  const [projectCode, setProjectCode] = useState("");
  const [searchPartner, setSearchPartner] = useState("");
  const [partnerList, setPartnerList] = useState([]);
  const [isCommentBoxVisible, setCommentBoxVisible] = useState(false);
  const [assigned_by, setAssigned_by] = useState();

  const [leadName, setLeadName] = useState("");
  const [leadID, setLeadID] = useState("");
  const [leadIDErr, setLeadIDErr] = useState(false);
  const [searchLead, setSearchLead] = useState("");
  const [leadList, setLeadList] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [typeOfOrderName, setTypeOfOrderName] = useState("");
  const [typeOfOrderID, setTypeOfOrderID] = useState("");
  const [typeOfOrderIDErr, setTypeOfOrderIDErr] = useState(false);
  const [searchTypeOfOrder, setSearchTypeOfOrder] = useState("");
  const [typeOfOrderList, setTypeOfOrderList] = useState([]);
  const [typeOfOrderLists, setTypeOfOrderLists] = useState([]);
  const [typeOfOrderListss, setTypeOfOrderListss] = useState([]);
  const [assigned_to_id, setAssigned_To] = useState();

  const [searchTypeOfOrders, setSearchTypeOfOrders] = useState("");
  const [searchTypeOfOrderss, setSearchTypeOfOrderss] = useState("");

  const [types, setTypes] = useState(1);

  const [locationName, setLocationName] = useState("");
  const [locationID, setLocationID] = useState("");
  const [locationIDErr, setLocationIDErr] = useState(false);
  const [searchLocation, setSearchLocation] = useState("");
  const [locationList, setLocationList] = useState([]);

  const [clientName, setClientName] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientIDErr, setClientIDErr] = useState(false);
  const [searchClient, setSearchClient] = useState("");

  const [OrderName, setOrderName] = useState("");
  const [orderID, setOrderID] = useState("");
  const [orderIDErr, setOrderIDErr] = useState(false);
  const [searchOrder, setSearchOrder] = useState("");

  const [projectOfOrderID, setProjectOfOrderID] = useState("");
  const [searchProjectOfOrder, setSearchProjectOfOrder] = useState("");
  const [projectOfOrderList, setProjectOfOrderList] = useState([]);
  const [projectOfOrderName, setProjectOfOrderName] = useState("");
  const [projectDropDown, setProjectDropDown] = useState(false);
  const [project_id, setProject_id] = useState("");

  const [productionName, setProductionName] = useState("");
  const [productionID, setProductionID] = useState("");
  const [productionIDErr, setProductionIDErr] = useState(false);
  const [searchProduction, setSearchProduction] = useState("");
  const type = location?.state?.type;
  const [projectList, setProjectList] = useState([]);
  const [Tags, setTags] = useState("");
  const [normalTag, setNormalTag] = useState([]);
  const [normalTagErr, setNormalTagErr] = useState(false);
  const [selectALLTags, setSelectALLTags] = useState(false);
  const [searchTags, setSearchTags] = useState("");
  const [task_date, setTask_date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [task_deadline_date, setTask_deadline_date] = useState("");
  const [description, setDescription] = useState("");
  const [commentss, setCommentss] = useState("");
  const [projectIdErr, setProjectIdErr] = useState(false);
  const [task_dateErr, setTask_dateErr] = useState(false);
  const [task_deadline_dateErr, setTask_deadline_dateErr] = useState(false);
  const [DepartmentErr, setDepartmentErr] = useState(false);
  const [DescriptionErr, setDescriptionErr] = useState(false);
  const [postcomments, setPostcomments] = useState("");
  const [statusErr, setStatusErr] = useState(false);
  const [typeOfOrderIDs, setTypeOfOrderIDs] = useState("");
  const [completion_date, setCompletion_date] = useState("");
  const [typeOfOrderIDss, setTypeOfOrderIDss] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [dropDowns, setDropDowns] = useState(null);
  const [dropDownss, setDropDownss] = useState(null);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [selectedValue, setSelectedValue] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [slaBreach, setSlaBreach] = useState(0);
  const [postApi] = useActivityAddMutation();
  const [activityData, setActivityData] = useState([]);
  const [activityList] = useActivityListMutation();
  const [start_date, setstart_date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [postErr, setPostErr] = useState(false);
  const [in_hand_date, setin_hand_date] = useState("");
  const [total_amount, settotal_amount] = useState("");
  const [closing_date, setclosing_date] = useState("");
  const [commentId, setCommentId] = useState("");

  const [in_hand_dateErr, setin_hand_dateErr] = useState(false);
  const [total_amountErr, settotal_amountErr] = useState(false);
  const [closing_dateErr, setclosing_dateErr] = useState(false);
  //product

  const [catogory, setCatogory] = useState("");
  // const [dropDown, setDropDown] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [categoryID, setCategoryID] = useState("");
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [category_id, setCategory_id] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [productName, setProductName] = useState([]);
  const [prods, setProds] = useState("");
  const [productID, setProductID] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productLists, setProductLists] = useState([]);
  const [skuCodeList, setSkuCode] = useState([]);
  const [skuSearch, setSearch] = useState("");
  const [sku, setSku] = useState("");
  const [skuErr, setSkuErr] = useState(false);
  const [productData, setProductData] = useState("");
  const [skuID, setSkuID] = useState("");
  const [productsErr, setProductsErr] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState({});
  const [catogoryName, setCatogoryName] = useState({});
  const [searchValuePro, setSearchValuePro] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [productPost, setProductPost] = useState("");
  const [productPostErr, setProductPostErr] = useState(false);

  // OTHER INFORMATION START
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [listAttributes, setListAttributes] = useState([]);
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [attributesList, setAttributesList] = useState([]);
  const [otherProInfo, setOtherProInfo] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [searchProInfo, setSearchProInfo] = useState("");
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);
  const [add, setAdd] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImgSrc, setSelectedImgSrc] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showTitle, setShowTitle] = useState("");
  const handleClose = () => setShowModal(false);

  const handleShow = (file, title) => {
    setSelectedFile(file);
    setShowTitle(title);
    setShowModal(true);
  };

  // OTHER INFORMATION END

  // MASTER, ADMIN, TAGS LIST API
  const getMaster = () => {
    masterApi()
      .unwrap()
      .then((res) => {
        setMasterList(res);
        setPartnerList(res?.partners);
        setLeadList(res?.lead_sources);
        setTypeOfOrderList(res?.type_of_orders);
        setLocationList(res?.project_locations);
        setListAttributes(res?.overview_attributes);
        setAttributesList(res?.overview_attributes);
      })
      .catch((err) => {
        console.log("err", err);
      });
    projectslistApi()
      .unwrap()
      .then((res) => {
        setProjectList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    AdminListApi()
      .unwrap()
      .then((res) => {
        setAdminList(res?.admins);
        setClientList(res?.admins);
        setOrderList(res?.admins);
        setProductionList(res?.admins);
      })
      .catch((err) => {
        console.log("err", err);
      });
    let formdata = new FormData();
    formdata.append("type", 7);
    activeMasterListApi(formdata)
      .unwrap()
      .then((res) => {
        setCategoryList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    tagList()
      .unwrap()
      .then((res) => {
        setTagNormalList(res?.projectTag);
        setTagNormalsList(res?.projectTag);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMaster();
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    setTask_date(todayDate);
  }, []);

  // Function for  Breach of SLA
  const calculateSlaBreach = () => {
    if (task_date && task_deadline_date) {
      if (!completion_date) {
        setSlaBreach(0);
      } else {
        const deadline = moment(task_deadline_date);
        const completion = moment(completion_date);
        const difference = completion.diff(deadline, "days");

        if (difference < 0) {
          setSlaBreach(Math.abs(difference));
        } else if (difference === 0) {
          setSlaBreach(0);
        } else {
          setSlaBreach(-difference);
        }
      }
    }
  };
  console.log(projectData, "project");
  useEffect(() => {
    calculateSlaBreach();
  }, [task_date, task_deadline_date, completion_date]);

  const userdetails = localStorage.getItem("userDetails");
  let userStr = JSON.parse(userdetails);
  // PARTNER DROPDOWN START
  const searchPartnerFun = (event) => {
    let temp = [...masterList?.partners];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setPartnerList(search);
    } else {
      setPartnerList(masterList?.partners);
    }

    setSearchPartner(event);
  };
  const onPartnerNameSelect = (item, id) => {
    if (item == partnerName) {
      setPartnerName("");
      setPartnerID("");
    } else {
      setPartnerName(item);
      setPartnerID(id);
    }
    setDropDown(null);
    setPartnerList(masterList?.partners);
    setSearchPartner("");
  };
  // PARTNER DROPDOWN END

  // LEAD DROPDOWN START
  const searchLeadFun = (event) => {
    let temp = [...masterList?.lead_sources];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setLeadList(search);
    } else {
      setLeadList(masterList?.lead_sources);
    }

    setSearchLead(event);
  };

  const onLeadSelect = (item, id) => {
    if (item == leadName) {
      setLeadName("");
      setLeadID("");
    } else {
      setLeadName(item);
      setLeadID(id);
    }
    setDropDown(null);
    setLeadList(masterList?.lead_sources);
    setSearchLead("");
  };
  // LEAD DROPDOWN END
  // POST ADD
  const Onpost = () => {
    if (post == 0) {
      setPostErr(true);
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("description", post);
      formdata.append("commented_by_id", userStr.id);
      formdata.append("project_id", projectData.id);
      dispatch(saveLoader(true));
      if (type == "edit") {
      } else {
        postApi(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              setPost("");
              OnpostList();
            }
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
          });
      }
    }
  };
  const OnProductPost = () => {
    if (
      productID?.length == 0 ||
      categoryID?.length == 0 ||
      productPost?.length == 0
    ) {
      setProductsErr(true);
      setProductPostErr(true);
      setCategoryIDErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("description", productPost);
      formdata.append("commented_by_id", userStr.id);
      formdata.append("project_id", projectData.id);
      formdata.append("product_id", productID);
      dispatch(saveLoader(true));

      postApi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            toast.success(res?.data?.message || res?.message);
            setPost("");
            OnpostList();
          }
          dispatch(saveLoader(false));
        })
        .catch((err) => {
          console.log("err");
          dispatch(saveLoader(false));
        });
    }
  };
  let project_code = searchParams?.get("project");
  console.log(project_code, "selected");
  const handleSelectchange = async (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    let formdata = new FormData();
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      formdata.append("project_id", split[1]);
      formdata.append("type", value);

      dispatch(saveLoader(true));

      activityList(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            const data = res?.data;
            setActivityData(data);
          }
          dispatch(saveLoader(false));
        })
        .catch((err) => {
          dispatch(saveLoader(false));
        });
    }
  };
  // POST LIST
  const OnpostList = () => {
    let formdata = new FormData();

    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      formdata.append("project_id", split[1]);

      dispatch(saveLoader(true));
      activityList(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            const data = res?.data;
            setActivityData(data);
          }
          dispatch(saveLoader(false));
        })
        .catch((err) => {
          console.log("err");
          dispatch(saveLoader(false));
        });
    }
  };
  useEffect(() => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      OnpostList();
    }
  }, []);

  const submitComment = (id, adminid) => {
    if (comment?.length == 0) {
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("description", comment);
      formdata.append("admin_id", userStr?.id);
      formdata.append("comment_id", id);
      formdata.append("type", "2");

      setBtn(true);
      dispatch(saveLoader(true));
      if (type == "edit") {
      } else {
        commandsApi(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              setComment("");
              setCommentBoxVisible(false);
              setSelectFiles("");
              OnpostList();
            }
            setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
            setBtn(false);
          });
      }
    }
  };
  //FIN
  const getpin = (id) => {
    const formdata = new FormData();
    formdata.append("project_activity_id", id);
    pinApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.data?.message || res?.message);
        console.log(res);
        OnpostList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMasters = (departmentId) => {
    let formdata = new FormData();
    const categorys = categoryList.id;
    console.log(categorys, "categorys");
    formdata.append("department_id", departmentId);

    userApi(formdata)
      .unwrap()
      .then((res) => {
        setUserList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (selectedDepartmentId) {
      getMasters(selectedDepartmentId);
    }
  }, [selectedDepartmentId]);
  // TYPE OF ODER START
  const searchTypeofOrderFun = (event) => {
    let temp = [...masterList?.type_of_orders];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTypeOfOrderList(search);
    } else {
      setTypeOfOrderList(masterList?.type_of_orders);
    }

    setSearchTypeOfOrder(event);
  };

  const onTypeofOrderSelect = (item, id) => {
    if (item == typeOfOrderName) {
      setTypeOfOrderName("");
      setTypeOfOrderID("");
    } else {
      setTypeOfOrderName(item);
      setTypeOfOrderID(id);
    }
    setDropDown(null);
    setTypeOfOrderList(masterList?.type_of_orders);
    setSearchTypeOfOrder("");
  };
  // TYPE OF ODER DROPDOWN END

  // LOCATION START
  const searchLocationFun = (event) => {
    let temp = [...masterList?.project_locations];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setLocationList(search);
    } else {
      setLocationList(masterList?.project_locations);
    }

    setSearchLocation(event);
  };

  const onLocationSelect = (item, id) => {
    if (item == locationName) {
      setLocationName("");
      setLocationID("");
    } else {
      setLocationName(item);
      setLocationID(id);
    }
    setDropDown(null);
    setLocationList(masterList?.project_locations);
    setSearchLocation("");
  };
  // LOCATION DROPDOWN END

  // CLIENT START
  const searchClientFun = (event) => {
    let temp = [...adminList];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setClientList(search);
    } else {
      setClientList(adminList);
    }

    setSearchClient(event);
  };

  const onClientSelect = (item, id) => {
    if (item == clientName) {
      setClientName("");
      setClientID("");
    } else {
      setClientName(item);
      setClientID(id);
    }
    setDropDown(null);
    setClientList(adminList);
    setSearchClient("");
  };
  // CLIENT DROPDOWN END

  // ORDER START
  const searchOderFun = (event) => {
    let temp = [...adminList];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setOrderList(search);
    } else {
      setOrderList(adminList);
    }

    setSearchOrder(event);
  };

  const onOderSelect = (item, id) => {
    if (item == OrderName) {
      setOrderName("");
      setOrderID("");
    } else {
      setOrderName(item);
      setOrderID(id);
    }
    setDropDown(null);
    setOrderList(adminList);
    setSearchOrder("");
  };
  // ORDER DROPDOWN END

  // ORDER START
  const searchProductionFun = (event) => {
    let temp = [...adminList];
    let search = temp.filter((value) =>
      value?.first_name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setProductionList(search);
    } else {
      setProductionList(adminList);
    }

    setSearchProduction(event);
  };

  const onProductionSelect = (item, id) => {
    if (item == productionName) {
      setProductionName("");
      setProductionID("");
    } else {
      setProductionName(item);
      setProductionID(id);
    }
    setDropDown(null);
    setProductionList(adminList);
    setSearchProduction("");
  };
  // ORDER DROPDOWN END

  // TAGS DROPDOWN START
  const allSelectTags = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTags) {
      tagNormalsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
      });

      setNormalTag(ListId);
      setTags(ListName);
    } else {
      setNormalTag(ListId);
      setTags(ListName);
    }

    setSelectALLTags(!selectALLTags);
  };

  const onTagsSelect = (event, id, type) => {
    let temp = [...normalTag];
    let temps = [...Tags];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setNormalTag(list);
    } else {
      setNormalTag([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTags(list);
    } else {
      setTags([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchTags("");
    setTagNormalList(tagNormalsList);
  };

  const searchTagsFun = (event) => {
    let temp = [...tagNormalsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setTagNormalList(search);
    } else {
      setTagNormalList(tagNormalsList);
    }

    setSearchTags(event);
  };
  // TAGS DROPDOWN END

  const showCommendBox = (ind) => {
    if (commentBox == ind) {
      setCommentBox(null);
    } else {
      setCommentBox(ind);
    }
  };
  const toggleCommentBox = () => {
    setCommentBoxVisible(!isCommentBoxVisible);
  };
  // department/roles dropdown function

  const searchTypeofOrderFuns = (event) => {
    let search = getUniqueRoles().filter((value) =>
      value?.role?.toLowerCase().includes(event?.toLowerCase())
    );

    if (event.length > 0) {
      setTypeOfOrderLists(search);
    } else {
      setTypeOfOrderLists(getUniqueRoles());
    }

    setSearchTypeOfOrders(event);
  };
  const onTypeofOrderSelects = (role, id) => {
    if (role === category) {
      setTypeOfOrderIDs("");
      setCategory("");
      setFilteredUsers(id);
    } else {
      setTypeOfOrderIDs(id);
      setCategory(role);
      const usersForSelectedRole = userList.filter(
        (item) => item?.role?.id === id
      ); // Filter users by role id
      setFilteredUsers(usersForSelectedRole);
    }
    setDropDowns(null);
    setTypeOfOrderLists(getUniqueRoles());
    setSearchTypeOfOrders("");
    setSelectedDepartmentId(id); // Set the selected department ID
  };

  const toggleShowPopup = () => {
    setCurrencyBtn(!currencyBtn);
  };

  const toggleShowLoastPopup = () => {
    setLostBtn(!LostBtn);
  };

  const toggleShowPopups = () => {
    setSelectFiles(!selectFiles);
  };
  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const addOtherInfo = (text) => {
    let temp = [...otherText];
    temp.push(text);
    setOtherText(temp);
  };
  // Get unique roles

  const getUniqueRoles = () => {
    const uniqueRoles = [];
    const rolesMap = new Map();

    userList.forEach((item) => {
      if (!rolesMap.has(item?.role?.id)) {
        rolesMap.set(item?.role?.id, true);
        uniqueRoles.push(item.role);
      }
    });

    return uniqueRoles;
  };

  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;
    setCategory(selectedRoleId);

    const usersForSelectedRole = userList.filter(
      (item) => item?.role?.id === Number(selectedRoleId)
    );
    setFilteredUsers(usersForSelectedRole);
  };

  // PROJECT VIEW API
  const getProjectView = () => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      dispatch(saveLoader(true));
      projectViewApi(split[1])
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            setProjectData(res?.project);
            setProjectCode(split[0]);
            let data = res?.project;
            if (data?.partner?.id) {
              setPartnerID(data?.partner?.id);
              setPartnerName(data?.partner?.name);
            }
            if (data?.lead_source?.id) {
              setLeadID(data?.lead_source?.id);
              setLeadName(data?.lead_source?.name);
            }
            if (data?.type_of_order?.id) {
              setTypeOfOrderID(data?.type_of_order?.id);
              setTypeOfOrderName(data?.type_of_order?.name);
            }

            if (data?.location?.id) {
              setLocationID(data?.location?.id);
              setLocationName(data?.location?.name);
            }

            if (data?.projectTags?.length > 0) {
              let tagId = [];
              let tagName = [];
              data?.projectTags?.map((item, ind) => {
                tagId.push(item?.id);
                tagName.push(item?.name);
              });
              setNormalTag(tagId);
              setTags(tagName);
            } else {
              let tagId = [];
              let tagName = [];
              data?.project_request?.projectTags?.map((item, ind) => {
                tagId.push(item?.id);
                tagName.push(item?.name);
              });
              setNormalTag(tagId);
              setTags(tagName);
            }

            if (data?.project_request?.client_rep?.id) {
              setClientID(data?.project_request?.client_rep?.id);
              setClientName(data?.project_request?.client_rep?.first_name);
            }

            if (data?.project_request?.client_rep?.id) {
              setClientID(data?.project_request?.client_rep?.id);
              setClientName(data?.project_request?.client_rep?.first_name);
            }

            if (data?.project_request?.order_rep?.id) {
              setOrderID(data?.project_request?.order_rep?.id);
              setOrderName(data?.project_request?.order_rep?.first_name);
            }

            if (data?.project_request?.production_rep?.id) {
              setProductionID(data?.project_request?.production_rep?.id);
              setProductionName(
                data?.project_request?.production_rep?.first_name
              );
            }

            if (data?.start_date) {
              setstart_date(data?.start_date);
            }
            if (data?.closing_date) {
              setclosing_date(data?.closing_date);
            }

            if (data?.total_amount) {
              settotal_amount(data?.total_amount);
            }

            if (data?.in_hand_date) {
              setin_hand_date(data?.in_hand_date);
            }

            let productAttributesName = [];
            let productAttributesID = [];
            data?.OverViewProjectOtherInfo?.map((item, ind) => {
              productAttributesName.push(item?.master?.name);
              setOtherProInfo(item?.id);
              productAttributesID.push({
                info_id: item?.id,
                id: item?.master?.id,
                title: item?.master?.name,
                value: item?.value,
              });
            });
            setDynamicAttr(productAttributesName);
            setProductInformation(productAttributesID);
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };
  // User dropdown function
  const searchTypeofOrderFunss = (event) => {
    let search = filteredUsers.filter((value) =>
      value?.first_name?.toLowerCase().includes(event?.toLowerCase())
    );

    if (event.length > 0) {
      setTypeOfOrderListss(search);
    } else {
      setTypeOfOrderListss(filteredUsers);
    }

    setSearchTypeOfOrderss(event);
  };
  const onTypeofOrderSelectss = (role, id) => {
    if (role === assigned_to_id) {
      setTypeOfOrderIDss("");
      setAssigned_To("");
    } else {
      setTypeOfOrderIDss(id);
      setAssigned_To(role);
    }
    setDropDownss(null);
    setTypeOfOrderListss(filteredUsers);
    setSearchTypeOfOrderss("");
  };
  useEffect(() => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      getProjectView();
    }
  }, []);

  const handleImageClick = (imgSrc) => {
    setSelectedImgSrc(imgSrc);
    setModalOpen(true);
  };
  const submitHandle = () => {
    let formdata = new FormData();

    let check;

    if (productInformation?.length > 0) {
      productInformation?.map((item, ind) => {
        check = Object.values(item).some((x) => x === " " || x === "");
      });
    }

    formdata.append("project_id", projectData?.id);
    formdata.append("project_request_id", projectData?.project_request?.id);
    formdata.append("start_date", start_date);

    if (
      partnerID?.length == 0 ||
      leadID?.length == 0 ||
      typeOfOrderID?.length == 0 ||
      locationID?.length == 0 ||
      in_hand_date?.length == 0 ||
      total_amount?.length == 0 ||
      closing_date?.length == 0 ||
      orderID?.length == 0 ||
      clientID?.length == 0 ||
      productionID?.length == 0 ||
      normalTag?.length == 0 ||
      check == true
    ) {
      setProductInformationErr(true);
      setPartnerIDErr(true);
      setLeadIDErr(true);
      setTypeOfOrderIDErr(true);
      setLocationIDErr(true);
      setin_hand_dateErr(true);
      settotal_amountErr(true);
      setclosing_dateErr(true);
      setOrderIDErr(true);
      setClientIDErr(true);
      setProductionIDErr(true);
      setProductionIDErr(true);
      setNormalTagErr(true);
    } else {
      formdata.append("partner_id", partnerID);
      formdata.append("lead_source_id", leadID);
      formdata.append("type_of_order_id", typeOfOrderID);
      formdata.append("location_id", locationID);
      formdata.append("in_hand_date", in_hand_date);
      formdata.append("total_amount", total_amount);
      formdata.append("closing_date", closing_date);
      formdata.append("order_rep", orderID);
      formdata.append("client_rep", clientID);
      formdata.append("production_rep", productionID);

      normalTag?.map((item, ind) => {
        formdata.append(`tags[${ind}]`, item);
      });

      if (productInformation?.length > 0) {
        productInformation?.map((item, ind) => {
          formdata.append(`informations[${ind}][master_id]`, item?.id);
          formdata.append(`informations[${ind}][value]`, item?.value);
        });
      }

      setBtn(true);
      dispatch(saveLoader(true));
      projectUpdateApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            toast.success(res?.message);
            getProjectView();
          }
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };
  const submitHandler = () => {
    if (
      projectData.id?.length == 0 ||
      task_date?.length == 0 ||
      task_deadline_date?.length == 0 ||
      typeOfOrderIDs?.length == 0 ||
      description?.length == 0
    ) {
      setTask_dateErr(true);
      setTask_deadline_dateErr(true);
      setDepartmentErr(true);
      setDescriptionErr(true);
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("project_id", projectData.id);
      formdata.append("task_date", task_date);
      formdata.append("task_deadline_date", task_deadline_date);
      formdata.append("assigned_by_id", userStr.id);
      formdata.append("assigned_to_id", typeOfOrderIDss);
      formdata.append("department_id", typeOfOrderIDs);
      formdata.append("description", description);
      formdata.append("status", 1);

      // setBtn(true);
      dispatch(saveLoader(true));
      if (type == "edit") {
        // EditApi({payload: formdata, id:location?.state?.data?.id })
        //   .unwrap()
        //   .then((res) => {
        //     if (res?.status == "success") {
        //       toast.success(res?.data?.message || res?.message);
        //       navigate("/projects-task");
        //     }
        //     // setBtn(false);
        //     dispatch(saveLoader(false));
        //   })
        //   .catch((err) => {
        //     console.log("err");
        //     dispatch(saveLoader(false));
        //     // setBtn(false);
        //   });
      } else {
        dispatch(saveLoader(true));
        taskAddApi(formdata)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.data?.message || res?.message);
              setProjectOfOrderID("");
              setTask_deadline_date("");
              setDescription("");
              setTypeOfOrderIDs("");
              setTypeOfOrderIDss("");
              setProjectOfOrderName("");
              setAssigned_To("");
              setCategory("");
              setTask_dateErr(false);
              setTask_deadline_dateErr(false);
              setDepartmentErr(false);
              setDescriptionErr(false);
              OnpostList();
            }
            // setBtn(false);
            dispatch(saveLoader(false));
          })
          .catch((err) => {
            console.log("err");
            dispatch(saveLoader(false));
            // setBtn(false);
          });
      }
    }
  };
  // project associated list
  const searchProjectofOrderFun = (event) => {
    let search = projectList.filter((value) =>
      value?.project_code?.toLowerCase().includes(event?.toLowerCase())
    );

    if (event.length > 0) {
      setProjectOfOrderList(search);
    } else {
      setProjectOfOrderList(projectList);
    }

    setSearchProjectOfOrder(event);
  };

  const onProjectofOrderSelect = (project_code, id) => {
    if (project_code === typeOfOrderName) {
      setProjectOfOrderName("");
      setProjectOfOrderID("");
    } else {
      setProjectOfOrderName(project_code);
      setProjectOfOrderID(id);
    }
    setProjectDropDown(null);
    setProjectOfOrderList(projectList);
    setSearchProjectOfOrder("");
  };

  const handlePinClick = (index) => {
    const newData = [...activityData];
    const pinnedItem = newData[index];
    newData.splice(index, 1);
    newData.unshift(pinnedItem);
    setActivityData(newData);
  };

  const projectStatusHandler = (event) => {
    if (+event == 2) {
      toggleShowLoastPopup();
    }
    setLostOption(event);
  };
  //PRODUCT
  const closeAddPro = () => {
    setCatogory("");
    setShowPopup(false);
    setDropDown(null);
    setCategoryIDErr(false);
    setSubcatogory(false);
    setSubCategoryList([]);
    setCatogoryShow2(null);
    setProds("");
    setProductID("");
    setProductList([]);
    setProductLists([]);
    setProductsErr(false);
    setSkuList([]);
    setSku("");
    setSkuErr(false);
    setShowPopup(false);
  };

  const getList = () => {
    listCategory()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.categories);
          setLists(res?.categories);
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    getList();
  }, []);

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...lists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setList(search);
    } else {
      setList(lists);
    }

    setSearchValueCategory(event);
  };
  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCategoryID(item?.id);
    setCatogory(item?.name);
    setSubcatogory(true);
    getCategoryId(item?.id, "category");
    setList(lists);
    setCatogoryName({});
    setDropDown(null);
    setSkuCode(skuCodeList);
    setProductID("");
    setProds("");
    setSkuList([]);
    setSku("");
    // setParentCategory(item?.id);
  };

  // GET PARENT ID FOR SUB CATEGORY LIST
  const getCategoryId = (id, type, indx, index, data) => {
    if (type == "category") {
      setCategory_id(id);
      setParentCategory(id);
      getView(id, "", "", type, "");
    } else {
      // console.log("lll");
      if (index == 0) {
        setCategory_id(id);
        // console.log("kkkkk");
      }
      onSubCatSelect(id, indx, index, data);
    }
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // SLICE ARRAY
  const onSubCatSelect = (id, ind, index, data) => {
    if (index < data?.length - 1) {
      let temp = [...data];
      let slicer = temp.slice(0, index + 1);
      setSubCategoryList(slicer);

      let temps = [...subCategoryLists];
      let slicers = temps.slice(0, index + 1);
      setSubCategoryLists(slicers);

      getView(id, ind, index, "sub_category", slicer);
    } else {
      getView(id, ind, index, "", data);
    }

    setProductID("");
    setProds("");
    setSkuList([]);
    setSku("");
  };
  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };
  // SELECT CHILD CATEGORY
  const onSubCategorySelect = (subItem, indx, inx) => {
    setSearchSubValueCategory((val) => ({ ...val, [inx]: "" }));
    let temp = [...subCategoryList];
    // console.log("temp[inx]", temp[inx], subCategoryLists[inx]);
    temp[inx] = subCategoryLists[inx];
    // console.log("temp", temp);
    setSubCategoryList(temp);
    setCatogoryShow2(null);
    getCategoryId(subItem?.id, "sub_category", indx, inx, temp);
    setCatogoryName((val) => ({
      ...val,
      [subItem?.category?.id]: subItem?.name,
    }));

    setProductName([]);

    if (inx == 0) {
      setSecondaryCategoryId(subItem?.id);
    }
    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };
  // CHILD CATEGORY VIEW API AND 2D DIMENTION ARRAY CREATE
  const getView = (id, inx, index, type, data) => {
    setLoading(true);
    viewCategoryApi(id)
      .unwrap()
      .then((res) => {
        let child = [];
        res?.child_categories?.map((item, ind) => {
          child.push({
            ...item,
            category: {
              ...res?.category,
            },
          });
        });

        let responce = {
          category: res?.category,
          child_categories: child,
        };

        let temp = [];
        let temps = [];
        if (data?.length > 0) {
          temp = [...data];
          temps = [...data];
        } else if (type !== "category") {
          temp = [...subCategoryList];
          temps = [...subCategoryLists];
        }

        if (type == "subSumit") {
          let temX = responce?.child_categories;

          let obj = {
            ...temp[index],
            child_categories: temX,
          };

          let final = subCategoryList?.filter((i) => i?.category?.id !== id);
          final.splice(index, 0, obj);

          let finals = subCategoryLists?.filter((i) => i?.category?.id !== id);
          finals.splice(index, 0, obj);

          setSubCategoryList(final);
          setSubCategoryLists(finals);

          let productsArr = [];

          finals?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          // console.log("finals", finals);
          setProductLists(productsArr);
          setProductList(productsArr);

          setLoading(false);
        } else {
          let finder = temp.indexOf(id);

          if (temp?.includes(responce)) {
            temp.slice(0, 1);
            temp.push(responce);
          } else {
            temp.push(responce);
          }

          if (temps?.includes(responce)) {
            temps.slice(0, 1);
            temps.push(responce);
          } else {
            temps.push(responce);
          }

          setSubCategoryList(temp);
          setSubCategoryLists(temps);

          let productsArr = [];

          temps?.map((item, ind) => {
            item?.child_categories?.map((chi, indx) => {
              chi?.product?.map((pro, inx) => {
                productsArr.push(pro);
              });
            });
          });
          console.log("temps", temps);
          setProductLists(productsArr);
          setProductList(productsArr);
          setLoading(false);
          getPro(id);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
    setProductName([]);

    // setProds((val) => ({ ...val, [ind]: {} }));
    setProductID("");
  };

  const searchPro = (event) => {
    let temp = [...productLists];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setProductList(search);
    } else {
      setProductList(productLists);
    }

    setSearchValuePro(event);
  };

  //PRODUCT
  const getProducts = (primary_category_id, secondary_category_id, id) => {
    let formdata = new FormData();
    formdata.append("primary_category_id", primary_category_id);
    formdata.append("secondary_category_id", secondary_category_id);
    viewCategory({ id: id, payload: formdata })
      .unwrap()
      .then((res) => {
        setProductLists(res?.products);
        setProductList(res?.products);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getPro = (id) => {
    if (categoryID && secondaryCategoryId) {
      getProducts(categoryID, secondaryCategoryId, id);
    }
  };

  const onProSelect = (event, id, type, item) => {
    setProductID(id);
    setProds(event);
    setProductData(item);
    let name = [];
    item?.productSku?.map((skulist, ind) => {
      if (skulist?.status == 1) {
        name?.push(skulist);
      }
    });
    setSkuList(name);

    setDropDown(null);
  };

  const skuSelect = (event) => {
    setSku(event);
  };

  // OTHER INFORMATION START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
        setOtherProInfo(item?.id);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
      setOtherProInfo("");
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];
    let finder = temp.find((i) => i.id == id);
    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");

    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
  };

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  // OTHER INFORMATION END

  //  PROJECT STATUS CHANGE
  const projectStatusChanger = (status) => {
    if (status == 5) {
      statusChanger(status, "estimate");
    } else {
      if (projectData?.presentation_code) {
        navigate(`/presentation?project=${searchParams?.get("project")}`);
      } else {
        statusChanger(status, "presentation");
      }
    }
  };

  const statusChanger = (status, navi) => {
    let formdata = new FormData();
    formdata.append("project_id", projectData?.id);
    formdata.append("status", status);

    dispatch(saveLoader(true));
    projectStatusChangeApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          navigate(`/${navi}?project=${searchParams?.get("project")}`);
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  return (
    <>
      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <div className="p-5">
          <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
            Add Product
            <span>
              <button onClick={() => closeAddPro()} className="cust-btn">
                <HighlightOffIcon />
              </button>
            </span>
          </p>

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Category
            </p>
            <div className="w-md-50 w-100 position-relative">
              <input
                placeholder="Catogory"
                type="text"
                value={
                  catogory?.length > 0
                    ? catogory?.toString()
                    : "Select Catogory"
                }
                onClick={() => {
                  setDropDown(1);
                }}
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                disabled={location?.state?.type == "view" ? true : false}
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(1);
                }}
                // disabled={location?.state?.type == "view" ? true : false}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 1 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchCategory(e.target.value)}
                    value={searchValueCategory}
                  />
                </div>
                {list?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onCategorySelct(item);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
              {categoryID?.length == 0 && categoryIDErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Category</p>
                </div>
              )}
            </div>
          </div>
          {subcatogory && (
            <>
              {subCategoryList?.map((item, ind) => {
                return (
                  <div
                    className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center"
                    key={ind}
                  >
                    <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                      {item?.categories
                        ? item?.categories?.name
                        : item?.category?.name}
                    </p>
                    <div className="w-md-50 w-100 position-relative">
                      <input
                        placeholder="Required Field"
                        className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        value={
                          catogoryName?.[
                            item?.categories
                              ? item?.categories?.id
                              : item?.category?.id
                          ]
                            ? catogoryName?.[
                                item?.categories
                                  ? item?.categories?.id
                                  : item?.category?.id
                              ]
                            : ""
                        }
                        // onChange={onCatogory2}
                        onClick={() => setCatogoryShow2(ind)}
                        readOnly
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(ind);
                          setDropDown(null);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                      {catogoryShow2 == ind && (
                        <div
                          className="invisible-cont2 z-0"
                          onClick={() => setCatogoryShow2(null)}
                        />
                      )}
                      <div
                        className={`${
                          catogoryShow2 == ind && "submenu_1 py-2"
                        } submenu_cont_1 overflow-scroll z-3`}
                      >
                        <div className="d-flex gap-1">
                          <input
                            className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                            placeholder="Search..."
                            onChange={(e) =>
                              searchSubCatFun(e.target.value, ind)
                            }
                            value={searchValueSubCategory[ind]}
                          />
                        </div>
                        {item?.child_categories?.map((subItem, indx) => {
                          return (
                            <button
                              className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                              onClick={() => {
                                onSubCategorySelect(subItem, indx, ind);
                              }}
                              key={ind}
                            >
                              <button className="px-2 cust-btn text-start py-1 w-100 ">
                                {subItem?.name}
                              </button>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              Product Name
            </p>
            <div className="w-md-50 w-100 position-relative">
              <input
                placeholder="Product Name"
                type="text"
                value={prods ? prods : "Select Product Name"}
                onClick={() => {
                  setDropDown(2);
                }}
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => {
                    setDropDown(null);
                    setCatogoryShow2(null);
                  }}
                />
              )}
              <div
                className={`${
                  dropDown == 2 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchPro(e.target.value)}
                    value={searchValuePro}
                  />
                </div>
                {productList?.map((item, inx) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={inx}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onProSelect(item?.name, item?.id, "", item);
                        }}
                      >
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
              {productID?.length == 0 && productsErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Product</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 d-flex flex-md-row flex-column gap-md-5 mt-3 align-items-start align-items-md-center">
            <p className="w-50 text-nowrap f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
              commands
            </p>
            <div className="w-md-50 w-100 position-relative">
              <textarea
                className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                placeholder="Post a note about this project"
                onChange={(e) => setProductPost(e.target.value)}
                value={productPost}
              />
            </div>
            {productPost?.length == 0 && productPostErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Commands</p>
              </div>
            )}
          </div>

          <div className="d-flex mt-3 justify-content-end m-2">
            <button
              onClick={OnProductPost}
              disabled={btn}
              className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <div className="dashRightView2 overview_pages overflow-scroll ">
        <div className=" d-flex justify-content-end position-relative">
          <div className="action_overview mx-4">
            <NavbarHead onLinkShow={onLinkShow} projectData={projectData} />

            {actionBtn && (
              <div
                className="invisible-cont2"
                style={{ zIndex: 200 }}
                onClick={() => setActionBtn(false)}
              />
            )}
            {actionBtn && (
              <div className="action_btn ml-1">
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() =>
                    navigate(
                      `/collaborate?project=${searchParams?.get("project")}`
                    )
                  }
                >
                  Collaborate
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() => {
                    projectStatusChanger(3);
                  }}
                >
                  {projectData?.status >= 2
                    ? "View Presentation"
                    : "Create Presentation"}
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() => {
                    projectStatusChanger(5);
                  }}
                >
                  {projectData?.status >= 5
                    ? "Preview Estimate"
                    : "Create Estimate"}
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={() =>
                    navigate(
                      `/sales-order?project=${searchParams?.get("project")}`
                    )
                  }
                >
                  Create Sales Order
                </button>
                <button
                  className="border-0 bg-white text-start text-dark f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2 px-2 hover_eff w-100"
                  onClick={""}
                >
                  View Portal
                </button>
              </div>
            )}
          </div>
        </div>
        {/* <EditShippingPoppup /> */}
        <div
          className={`${
            btnChange !== 1 && "flex_res_over_view"
          } d-flex as-jb gap-5 w-100 flex-column flex-md-row mt-5`}
        >
          <div className="d-flex overview_page w-100">
            <div className="w-100 px-0 px-md-3">
              <div className="d-flex ac-jb w-100">
                <div className="w-100 d-flex ac-js w-100">
                  <button
                    className={`${
                      btnChange == 0 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                    onClick={() => setBtnChange(0)}
                  >
                    Activity
                  </button>
                  <button
                    className={`${
                      btnChange == 1 ? "border_click_cust" : "cust-btn "
                    } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                    // onClick={() => setBtnChange(1)}
                    onClick={() => {
                      setSelectFiles(!selectFiles);
                      setAdd(1);
                    }}
                  >
                    Files
                  </button>
                </div>
                {/* <div className="text-center">
                  {btnChange == 1 && (
                    <button class="position-relative cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded">
                      Upload
                    </button>
                  )}
                </div> */}
              </div>
              <div className="over_view_tab  w-100">
                {btnChange == 1 && (
                  <>
                    {/* <div className="over_imgs mt-5">
                      <div className="over_img d-flex ac-jc rounded-2">
                        <img
                          src={product}
                          className="w-100 img_size3 object-fit-contain"
                        />
                        <div className="files_btn gap-1 d-flex mt-2 px-2">
                          <button className="">Edit</button>
                          <button className="">Delete</button>
                          <button className="">
                            <FileDownloadIcon />
                          </button>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <p className="im1">Jobs1.jpeg</p>
                        <p className="im2">Updated 14 October 20233333333333</p>
                      </div>
                    </div> */}
                    <div className="d-flex ae-jb w-100">
                      <button
                        className={`${
                          btnFiles == 0 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(0)}
                      >
                        Product Deck
                      </button>
                      <button
                        className={`${
                          btnFiles == 1 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(1)}
                      >
                        Accounting Documents
                      </button>
                      <button
                        className={`${
                          btnFiles == 2 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(2)}
                      >
                        Customer PO
                      </button>
                      <button
                        className={`${
                          btnFiles == 3 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(3)}
                      >
                        Material Information
                      </button>
                      <button
                        className={`${
                          btnFiles == 4 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(4)}
                      >
                        Artwork
                      </button>
                      <button
                        className={`${
                          btnFiles == 5 ? "border_click_cust" : "cust-btn "
                        } bg-transparent py-1 f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-3`}
                        onClick={() => setBtnFiles(5)}
                      >
                        Notes and Attachment
                      </button>
                    </div>
                    {btnFiles == 0 && <OverviewFiles list={3} />}
                    {btnFiles == 1 && <OverviewFiles list={7} />}
                    {btnFiles == 2 && <OverviewFiles list={8} />}
                    {btnFiles == 3 && <OverviewFiles list={2} />}
                    {btnFiles == 4 && <OverviewFiles list={10} />}
                    {btnFiles == 5 && <OverviewFiles list={5} />}
                  </>
                )}

                {btnChange == 0 && (
                  <div className="w-100">
                    <div className="py-2 px-3 rounded-3 bg-lt-blue2 my-3 py-4 rounded-3">
                      <textarea
                        className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        placeholder="Post a note about this project"
                        onChange={(e) => setPost(e.target.value)}
                        value={post}
                      />
                      {productBtn && (
                        <div className="">
                          <img className="w-20" src={product} />
                        </div>
                      )}
                      {/* <div className="d-flex over_task_btn"> */}
                      <div className="d-flex justify-content-around justify-content-md-center gap-3 my-3 w-100">
                        <div className="d-flex as-jc gap-3 flex-column flex-sm-row">
                          <button
                            onClick={() => setTaskBtn(!taskBtn)}
                            style={productBtn ? { opacity: "0.3" } : {}}
                            className="cust-btn text-nowrap addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                          >
                            <AccessTimeOutlinedIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            Task
                          </button>
                          <button
                            onClick={() =>
                              navigate(
                                `/product-library-select?project=${searchParams?.get(
                                  "project"
                                )}`,
                                { state: { type: "edit" } }
                              )
                            }
                            disabled={taskBtn ? true : false}
                            style={taskBtn ? { opacity: "0.3" } : {}}
                            className="text-nowrap cust-btn addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                          >
                            <WorkOutlineOutlinedIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            Product
                          </button>
                        </div>
                        <div className="d-flex ae-jc gap-3 flex-column flex-sm-row">
                          <button
                            disabled={taskBtn ? true : false}
                            style={taskBtn ? { opacity: "0.3" } : {}}
                            onClick={() => {
                              setSelectFiles(!selectFiles);
                              setAdd(0);
                            }}
                            className="text-nowrap cust-btn text-nowrap addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 rounded mb-0"
                          >
                            <AttachmentOutlinedIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            File
                          </button>
                          <button
                            style={
                              post?.length > 0
                                ? { opacity: "1" }
                                : { opacity: "0.3" }
                            }
                            className="text-nowrap cust-btn addbtn bg-primar me-0 text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                            onClick={Onpost}
                          >
                            <IosShareIcon className="f3 me-1 text-light fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                            Post
                          </button>
                        </div>
                      </div>
                      {taskBtn && (
                        <div className="d-flex w-100 flex-wrap gap-3">
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Task Date
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="date"
                              value={task_date}
                              readOnly
                            />
                            {task_date?.length == 0 && task_dateErr && (
                              <div className="d-flex gap-1 mt-2">
                                <ErrorIcon className="svg_log" />
                                <p className="err-input_log mt-1">
                                  Enter Task Date
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Task Deadline
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="date"
                              onChange={(e) =>
                                setTask_deadline_date(e.target.value)
                              }
                              value={task_deadline_date}
                            />
                            {task_deadline_date?.length == 0 &&
                              task_deadline_dateErr && (
                                <div className="d-flex gap-1 mt-2">
                                  <ErrorIcon className="svg_log" />
                                  <p className="err-input_log mt-1">
                                    Enter Task Deadline
                                  </p>
                                </div>
                              )}
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Project Associated
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              disabled
                              value={projectData?.project_code}
                              readOnly
                            />
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Assigned By
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              disabled
                              value={`${userStr?.first_name} ${userStr?.last_name}`}
                              readOnly
                            />
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Department/ Team
                            </p>
                            <div className="position-relative">
                              <input
                                placeholder="Department/ Team"
                                className=" editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                onChange={(e) => setCategory(e.target.value)}
                                value={category}
                                onClick={() => {
                                  setDropDowns(7);
                                }}
                              />
                              <button
                                className="drop_down cust-btn"
                                onClick={() => {
                                  setDropDowns(7);
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </button>
                              {dropDowns == 7 && (
                                <div
                                  className="invisible-cont2 z-0"
                                  onClick={() => setDropDowns(null)}
                                />
                              )}
                              <div
                                className={`${
                                  dropDowns == 7 && "submenu_1  py-2"
                                } submenu_cont_1 overflow-scroll z-3`}
                              >
                                <div className="d-flex gap-1">
                                  <input
                                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                                    placeholder="Search..."
                                    onChange={(e) =>
                                      searchTypeofOrderFuns(e.target.value)
                                    }
                                    value={searchTypeofOrderFuns}
                                  />
                                </div>
                                {/* <option hidden>Select</option> */}
                                {categoryList?.map((role, ind) => {
                                  return (
                                    <button
                                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                                      key={ind}
                                      onClick={() => {
                                        onTypeofOrderSelects(
                                          role?.name,
                                          role?.id
                                        );
                                      }}
                                    >
                                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                                        {role?.name}
                                      </button>
                                      <button
                                        className="px-2 cust-btn text-start"
                                        style={{ fontSize: "large" }}
                                      >
                                        {category === role?.name &&
                                        role?.name !== userStr?.name ? (
                                          <CheckCircleIcon className="primary" />
                                        ) : (
                                          <RadioButtonUncheckedIcon className="primary" />
                                        )}
                                      </button>
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                            {/* </select> */}
                            {category?.length == 0 && DepartmentErr && (
                              <div className="d-flex gap-1 mt-2">
                                <ErrorIcon className="svg_log" />
                                <p className="err-input_log mt-1">
                                  Enter Department
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              User<span></span>
                            </p>
                            <div className="position-relative">
                              <input
                                placeholder="user"
                                className=" editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                onChange={(e) => setAssigned_To(e.target.value)}
                                disabled={type === "edit"}
                                value={assigned_to_id}
                                onClick={() => {
                                  setDropDownss(6);
                                }}
                              />
                              <button
                                className="drop_down cust-btn"
                                onClick={() => {
                                  setDropDownss(6);
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </button>
                              {dropDownss == 6 && (
                                <div
                                  className="invisible-cont2 z-0"
                                  onClick={() => setDropDownss(null)}
                                />
                              )}
                              <div
                                className={`${
                                  dropDownss == 6 && "submenu_1  py-2"
                                } submenu_cont_1 overflow-scroll z-3`}
                              >
                                <div className="d-flex gap-1">
                                  <input
                                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                                    placeholder="Search..."
                                    onChange={(e) =>
                                      searchTypeofOrderFunss(e.target.value)
                                    }
                                    value={searchTypeofOrderFunss}
                                    disabled={type == "view"}
                                  />
                                </div>
                                {/* {getUniqueRoles().map((role, ind) => { */}
                                {/* //  {filteredUsers.map((user, ind) => { */}
                                {userList?.map((user, ind) => {
                                  return (
                                    <button
                                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                                      key={ind}
                                      onClick={() => {
                                        onTypeofOrderSelectss(
                                          user?.first_name,
                                          user?.id
                                        );
                                      }}
                                    >
                                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                                        {user?.first_name}
                                      </button>
                                      <button
                                        className="px-2 cust-btn text-start"
                                        style={{ fontSize: "large" }}
                                      >
                                        {assigned_to_id == user?.first_name ? (
                                          <CheckCircleIcon className="primary" />
                                        ) : (
                                          <RadioButtonUncheckedIcon className="primary" />
                                        )}
                                      </button>
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Status
                            </p>
                            <select
                              placeholder=""
                              className=" editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                              name=""
                              id=""
                              onChange={(e) => setStatus(e.target.value)}
                              value={status}
                              // disabled={type == "view" ? true : false}
                            >
                              <option value={1}>Yet to start</option>
                            </select>
                            {/* {status?.length == 0 && statusErr && (
                              <div className="d-flex gap-1 mt-2">
                                <ErrorIcon className="svg_log" />
                                <p className="err-input_log mt-1">
                                  Enter Status
                                </p>
                              </div>
                            )} */}
                          </div>
                          {/* <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Date of completion
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="date"
                              onChange={(e) =>
                                setCompletion_date(e.target.value)
                              }
                              value={completion_date}
                            />
                          </div>
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Breach of SLA
                            </p>
                            <input
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="text"
                              placeholder="Breach of SLA"
                              value={slaBreach}
                              readOnly
                            />
                          </div> */}
                          <div className="w-45">
                            <p className=" f3 fs-15 fs-xs-11 me-0 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2">
                              Description
                            </p>
                            <textarea
                              className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                              type="text"
                              placeholder="Description"
                              onChange={(e) => setDescription(e.target.value)}
                              value={description}
                            />

                            {description?.length == 0 && DescriptionErr && (
                              <div className="d-flex gap-1 mt-2">
                                <ErrorIcon className="svg_log" />
                                <p className="err-input_log mt-1">
                                  Enter Description
                                </p>
                              </div>
                            )}
                          </div>
                          <button
                            className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                              btn && "opacity-50"
                            }`}
                            onClick={submitHandler}
                            // disabled={btn ? true : false}
                          >
                            Submit
                          </button>
                          {/* <button
                            className={`cust-btn addbtn mt-3 ms-1 bg-primary text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                              btn ? "opacity-50" : ""
                            }`}
                            onClick={() =>
                              navigate("/projects-task-view", {
                                state: { data: projectData },
                              })
                            } // Passing `projectData` through location state
                            disabled={btn}
                          > */}
                          {/* View Comments */}
                          {/* </button> */}
                          <button
                            className={`cust-btn addbtn mt-3 ms-1 bg-primary text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                              btn ? "opacity-50" : ""
                            }`}
                            onClick={() => navigate("/projects-tasks")} // Passing `projectData` through location state
                            disabled={btn}
                          >
                            View more task
                          </button>
                          {/* <div className="flex-wrap">
                            <p
                              className=" f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                              style={{ marginLeft: "0px" }}
                            >
                              Task For
                            </p>
                            <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                              <option>ODR</option>
                              <option>ODR 2</option>
                              <option>ODR 3</option>
                            </select>
                          </div> */}
                        </div>
                      )}
                    </div>
                    <div className="d-flex gap-3 ac-jc mb-3 px-0 px-md-4  w-100 flex-column flex-sm-row">
                      <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                        {/* <div className="position-relative">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          />
                          <button className="drop_down cust-btn">
                            <KeyboardArrowDownIcon />
                          </button>
                        </div> */}
                        {/* {(
                          <div
                            className="invisible-cont2 z-0"
                          />
                        )} */}
                        <div
                          className={`${"submenu_1"} d-none submenu_cont_1 overflow-scroll z-3`}
                        >
                          {overview_input1?.map((item) => {
                            return (
                              <div className="d-flex hover-cust">
                                <button className="px-2 cust-btn text-start py-1 w-100">
                                  {item?.list}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
                        {/* <div className="position-relative">
                          <input
                            placeholder="Required Field"
                            className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                          />
                          <button className="drop_down cust-btn">
                            <KeyboardArrowDownIcon />
                          </button>
                        </div> */}
                        {/* {(
                          <div
                            className="invisible-cont2 z-0"
                          />
                        )} */}
                        <div
                          className={`${"submenu_1"} d-none submenu_cont_1 overflow-scroll z-3`}
                        >
                          {overview_input2?.map((item) => {
                            return (
                              <div className="d-flex hover-cust">
                                <button className="px-2 cust-btn text-start py-1 w-100">
                                  {item?.list}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                        <input
                          placeholder="Search..."
                          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                      </div>
                      <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                        <select
                          placeholder=""
                          className=" editBtn mt-2 rounded-3 p-2 w-100 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                          name=""
                          id=""
                          onChange={handleSelectchange}
                          value={selectedValue}
                        >
                          <option value="">Select Activity</option>
                          <option value={1}>Post</option>
                          <option value={2}>Product </option>
                          <option value={3}>File</option>
                          <option value={4}>Task</option>
                          <option value={5}>Client File</option>
                          <option value={6}>Overview</option>
                          <option value={7}>Collaborate</option>
                          <option value={8}>Presentation</option>
                          <option value={9}>Show to Customer</option>
                          <option value={10}>Estimate</option>
                          <option value={11}>Create Sales Order</option>
                          <option value={12}>Production Artwork</option>
                          <option value={13}>Sales Order</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <ul>
                        {activityData.map((item, index) => (
                          <li
                            className="d-flex flex-wrap justify-content-center"
                            style={{
                              borderBottom: "1px solid #d3d3d3",
                              padding: "10px 0",
                            }}
                          >
                            {/* <div className="d-flex-ac-jc mb-3 mb-md-0">
                              {item?.images?.length > 0 &&
                                item.images.map((image, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={image?.image_url}
                                    className="sm_img_size"
                                    alt={`Activity Image ${imgIndex + 1}`}
                                    style={{
                                      marginRight: "20px",
                                      width: "100px",
                                      height: "auto",
                                    }}
                                  />
                                ))}
                            </div> */}
                            <div className="d-flex-ac-jc mb-3 mb-md-0">
                              {item?.images?.length > 0 &&
                                item?.images?.map((imgItem, imgIndex) => {
                                  // console.log(imgItem.product_id, "imgItem");
                                  const imgSrc = imgItem?.image_url?.includes(
                                    ".pdf"
                                  )
                                    ? fileDocIcon
                                    : imgItem?.image_url.includes(".xls")
                                    ? xlsDocIcon
                                    : imgItem?.image_url.includes(".docx")
                                    ? DocIcon // Handle .docx
                                    : imgItem?.image_url;

                                  return (
                                    <img
                                      key={imgIndex}
                                      src={imgSrc}
                                      role="button"
                                      onClick={() => {
                                        console.log(
                                          imgItem?.product_id,
                                          "imgItem.product_id"
                                        );

                                        if (imgItem?.product_id) {
                                          console.log(
                                            imgItem?.product_id,
                                            "imgItem.product_id"
                                          );
                                          navigate("/product-library-details", {
                                            state: {
                                              data: { id: imgItem?.product_id },
                                              type: "edit",
                                            },
                                          });
                                        } else {
                                          setSelectedFile(imgItem?.image_url);
                                          setShowTitle("Document Preview");
                                          setShowModal(true);
                                        }
                                      }}
                                      alt="Document Icon"
                                      className="sm_img_size"
                                      style={{
                                        marginRight: imgItem?.product_id
                                          ? "70px"
                                          : "20px",
                                        width: "100px",
                                        height: "auto",
                                      }}
                                    />
                                  );
                                })}

                              <Modal
                                show={showModal}
                                onHide={handleClose}
                                size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                              >
                                <Modal.Header closeButton>
                                  <h5>{showTitle}</h5>
                                </Modal.Header>
                                <div className="d-flex justify-content-center">
                                  {selectedFile?.includes(".pdf") ? (
                                    <iframe
                                      src={selectedFile}
                                      className="p-1"
                                      style={{
                                        height: "500px",
                                        width: "100%",
                                        overflow: "hidden",
                                      }}
                                    />
                                  ) : selectedFile?.includes(".xls") ? (
                                    <div>
                                      <p>
                                        This is an Excel file. You can download
                                        it{" "}
                                      </p>
                                      <a href={selectedFile} className="err">
                                        Download
                                      </a>
                                      <img
                                        src={xlsDocIcon}
                                        alt="Excel Document Icon"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  ) : selectedFile?.includes(".docx") ? ( // Handle .docx
                                    <div>
                                      <p>
                                        This is a Word document. You can
                                        download it{" "}
                                      </p>
                                      <a href={selectedFile} className="err">
                                        Download
                                      </a>
                                      <img
                                        src={DocIcon}
                                        alt="Word Document Icon"
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      src={selectedFile}
                                      className="p-1"
                                      style={{
                                        height: "500px",
                                        overflow: "hidden",
                                      }}
                                      alt="Document Preview"
                                    />
                                  )}
                                </div>
                                <Modal.Footer>
                                  <button onClick={handleClose}>Close</button>
                                </Modal.Footer>
                              </Modal>
                            </div>

                            <div className="d-flex ac-jc mt-3 px-0 px-md-3 w-100 flex-column flex-sm-row ">
                              <div className="d-flex-ac-jc mb-3 mb-md-0">
                                <img
                                  src={
                                    item?.admin?.image_url
                                      ? item?.admin?.image_url
                                      : profilePic
                                  }
                                  className="sm_img_size"
                                />{" "}
                              </div>
                              <div className="d-flex px-0 px-md-3 w-100">
                                <div className="mx-3 w-100">
                                  <div className="d-flex ac-jb">
                                    <h5 className="author">
                                      <span
                                        key={index}
                                        className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17"
                                      >
                                        {item?.type === 10 ? (
                                          item?.user_name
                                        ) : (
                                          <>
                                            {item?.admin?.first_name}{" "}
                                            {item?.admin?.last_name}
                                          </>
                                        )}{" "}
                                        <span style={{ color: "blue" }}>
                                          ({item?.name})
                                        </span>
                                      </span>

                                      <span
                                        key={index}
                                        className="author_name mx-2 f3 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
                                      >
                                        {/* — PRESENTATION {item.project_id} */}
                                      </span>
                                    </h5>
                                  </div>
                                  <div>
                                    <p
                                      key={index}
                                      className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"
                                    >
                                      {item?.description}
                                    </p>
                                  </div>

                                  <div className="d-flex gap-2 flex-wrap text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark mb-1">
                                    <p className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                                      {item?.task_detail?.assignedBy ? (
                                        <>
                                          <span className="text-start text_auth f3 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                                            Assigned By:
                                          </span>{" "}
                                          {
                                            item?.task_detail?.assignedBy
                                              ?.first_name
                                          }{" "}
                                          {
                                            item?.task_detail?.assignedBy
                                              ?.last_name
                                          }
                                        </>
                                      ) : null}
                                    </p>

                                    <p
                                      key={index}
                                      className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark "
                                    >
                                      {item?.task_detail?.assignedTo ? (
                                        <>
                                          <span className="ms-3 text-start text_auth f3 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                                            Assigned To :
                                          </span>{" "}
                                          {
                                            item?.task_detail?.assignedTo
                                              ?.first_name
                                          }{" "}
                                          {
                                            item?.task_detail?.assignedTo
                                              ?.last_name
                                          }
                                        </>
                                      ) : null}
                                    </p>

                                    {/* <p
  key={index}
  className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"
>
  {item.task_detail?.status ? (
    <>
      <span className="text-start text_auth f3 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark ms-3">
        Status: 
      </span>{" "}
      <span
        className={`${
          item.task_detail.status === 1
            ? "text-primary"
            : item.task_detail.status === 2
            ? "text-warning"
            : item.task_detail.status === 3
            ? "text-success"
            : ""
        } f3 text-center`}
      >
        {item.task_detail.status === 1 && ' Yet to Start'}
        {item.task_detail.status === 2 && ' In Progress'}
        {item.task_detail.status === 3 && ' Completed'}
      </span>
    </>
  ) : null}
</p> */}

                                    <p
                                      key={index}
                                      className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"
                                    >
                                      {item?.task_detail ? (
                                        <>
                                          <span className="text-start text_auth f3 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark ">
                                            Task Date:
                                          </span>{" "}
                                          {item?.task_detail?.task_date}
                                        </>
                                      ) : null}
                                    </p>
                                    <p
                                      key={index}
                                      className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"
                                    >
                                      {item?.task_detail ? (
                                        <>
                                          <span className="text-start text_auth f3 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark">
                                            Task Deadline :
                                          </span>{" "}
                                          {
                                            item?.task_detail
                                              ?.task_deadline_date
                                          }
                                        </>
                                      ) : null}
                                    </p>
                                  </div>
                                  <div>
                                    <p
                                      key={index}
                                      className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"
                                    >
                                      {item?.task_detail?.description}
                                    </p>
                                  </div>
                                  <div>
                                    {/* <p
                                      key={index}
                                      className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"
                                    >
                                      {item.task_detail?.description}
                                    </p> */}
                                    {/* <button className="px-2 cust-btn text-start">view</button> */}
                                    {/* {item.task_detail ? (
                                      <button
                                        className={`cust-btn addbtn mt-3 ms-1 bg-primary text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                                          btn ? "opacity-50" : ""
                                        }`}
                                        onClick={() =>
                                          navigate("/projects-task-view", {
                                            state: { data: projectData },
                                          })
                                        }
                                      >
                                        View
                                      </button>
                                    ) : null} */}
                                  </div>
                                  <div>
                                    <div className="icon_com_pin">
                                      <p className="hours_ago gary f1 fs-xs-11 fs-sm-11 fs-md-11 fs-lg-12 fs-xl-12 fs-xxl-13">
                                        {moment(item?.created_at).calendar()}
                                      </p>
                                      {item?.type !== 4 && (
                                        <div
                                          className="d-flex ac-js gap-1"
                                          onClick={() => showCommendBox(null)}
                                        >
                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                              <Tooltip {...props}>
                                                Comment
                                              </Tooltip>
                                            )}
                                            placement="top"
                                          >
                                            <CommentOutlinedIcon
                                              className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp"
                                              onClick={() => {
                                                toggleCommentBox();
                                                setCommentId(item?.id);
                                              }}
                                            />
                                          </OverlayTrigger>
                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props) => (
                                              <Tooltip {...props}>Pin</Tooltip>
                                            )}
                                            placement="top"
                                          >
                                            <PushPinOutlinedIcon
                                              className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp"
                                              onClick={() =>
                                                // handlePinClick(index)
                                                getpin(item?.id)
                                              }
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      )}
                                    </div>
                                    {item?.task_detail && (
                                      <img
                                        src={viewIcon}
                                        onClick={() =>
                                          navigate("/projects-task-view", {
                                            state: { data: item?.task_detail },
                                          })
                                        }
                                        className="viewDes"
                                        alt="View Icon"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {/* <div>
                                    <DescriptionIcon className="f3 me-1 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                                  </div> */}
                                </div>
                              </div>
                              {/* {item?.product !== null && (
                                <div className="d-flex-ac-jc mb-0 mb-md-0">
                                  <img
                                    src={
                                      item?.product?.defaultImage
                                        ? item?.product?.defaultImage
                                        : profilePic
                                    }
                                    className="sm_img_size"
                                  />{" "}
                                </div>
                              )} */}
                            </div>
                            {isCommentBoxVisible && commentId == item?.id && (
                              <div className="w-100 mt-3 py-2 px-2 px-sm-3 rounded-3 bg-lt-blue2">
                                <div className="d-flex mt-3 ac-jc align-items-sm-start justify-content-sm-start flex-column flex-sm-row">
                                  <textarea
                                    className="w-100 me-0 me-sm-3 rounded-1 p-2 w-70 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 editBtn"
                                    placeholder="Comment on this message"
                                    onChange={(e) => setComment(e.target.value)}
                                    value={comment}
                                  />
                                  <div>
                                    <button
                                      className={
                                        comment?.length > 0
                                          ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                                          : "opacity-50 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                                      }
                                      onClick={() => {
                                        submitComment(item?.id, item?.admin.id);
                                      }}
                                    >
                                      Comment
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {!item.task_detail &&
                              item?.comments?.length > 0 &&
                              item?.comments?.map((items, indexs) => (
                                <div className="d-flex ac-je mt-3 px-0 px-md-3 w-70 flex-column flex-sm-row ">
                                  <div className="d-flex-ac-jc mb-3 mb-md-0">
                                    <img
                                      src={
                                        items?.admin?.image_url
                                          ? items?.admin?.image_url
                                          : profilePic
                                      }
                                      className="sm_img_size"
                                    />{" "}
                                  </div>
                                  <div className="d-flex px-0 px-md-3 w-100">
                                    <div className="mx-3 w-100">
                                      <div className="d-flex ac-jb">
                                        <h5 className="author">
                                          <span
                                            key={index}
                                            className="f3 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17"
                                          >
                                            {items?.admin?.first_name}{" "}
                                            {items?.admin?.last_name}
                                          </span>

                                          <span
                                            key={index}
                                            className="author_name mx-2 f3 fs-xs-11 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
                                          >
                                            {/* — PRESENTATION {item.project_id} */}
                                          </span>
                                        </h5>
                                      </div>
                                      <div>
                                        <p
                                          key={index}
                                          className="text-start text_auth f1 fs-xs-12 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-13 fs-xxl-14 text-dark"
                                        >
                                          {items?.description}
                                        </p>
                                      </div>

                                      <div>
                                        <div className="icon_com_pin">
                                          <p className="hours_ago gary f1 fs-xs-11 fs-sm-11 fs-md-11 fs-lg-12 fs-xl-12 fs-xxl-13">
                                            {moment(
                                              items?.created_at
                                            ).calendar()}
                                          </p>
                                          {/* <div
                                            className="d-flex ac-js gap-1"
                                            onClick={() => showCommendBox(null)}
                                          >
                                            <OverlayTrigger
                                              delay={{ hide: 450, show: 300 }}
                                              overlay={(props) => (
                                                <Tooltip {...props}>
                                                  Comment
                                                </Tooltip>
                                              )}
                                              placement="top"
                                            >
                                              <CommentOutlinedIcon
                                                className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp"
                                                onClick={() => {
                                                  toggleCommentBox();
                                                  setCommentId(item?.id);
                                                }}
                                              />
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                              delay={{ hide: 450, show: 300 }}
                                              overlay={(props) => (
                                                <Tooltip {...props}>
                                                  Pin
                                                </Tooltip>
                                              )}
                                              placement="top"
                                            >
                                              <PushPinOutlinedIcon
                                                className="f3 primary fs-xs-14 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-17 cp"
                                                onClick={() =>
                                                  handlePinClick(index)
                                                }
                                              />
                                            </OverlayTrigger>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {/* <div>
                                        <DescriptionIcon className="f3 me-1 text-dark fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-17" />
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </li>
                        ))}

                        {/* {[0, 1, 2, 3, 4].map((item, ind) => (
                          <li className="d-flex flex-wrap">
                            <div className="w-100 mt-3">
                              {commentBox == ind && (
                                <div className="d-flex">
                                  <textarea
                                    className="w-70  rounded-1 p-2 w-70 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 editBtn"
                                    placeholder="Comment on this message"
                                    onChange={(e) => setComment(e.target.value)}
                                  />
                                  <button
                                    className={
                                      comment?.length > 0
                                        ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                                        : "opacity-50 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                                    }
                                  >
                                    Comment
                                  </button>
                                </div>
                              )}
                            </div>
                          </li>
                        ))} */}
                      </ul>

                      {/* {isCommentBoxVisible && (
                        <div className="w-100 mt-3 py-2 px-2 px-sm-3 rounded-3 bg-lt-blue2">
                            <div className="d-flex mt-3 ac-jc align-items-sm-start justify-content-sm-start flex-column flex-sm-row">
                              <textarea
                                className="w-100 me-0 me-sm-3 rounded-1 p-2 w-70 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 editBtn"
                                placeholder="Comment on this message"
                                onChange={(e) => setComment(e.target.value)}
                                value={comment}
                              />
                              <div>
                                <button
                                  className={
                                    comment?.length > 0
                                      ? "cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                                      : "opacity-50 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mb-0"
                                  }
                                  onClick={submitComment} 

                                >
                                  Comment
                                </button>
                              </div>
                            </div>
                          </div>
                        )} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`${
              btnChange !== 1 ? "w-50 box_width" : "w-50   w-md-50"
            } px-0 px-md-3 `}
          >
            <h5 className="f3 fs-13 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark">
              Project Status
            </h5>
            <div className="d-flex ac-js gap-3 mt-3 w-50">
              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                onChange={(e) => projectStatusHandler(e.target.value)}
                value={LostOption}
              >
                <option value={1}>Off</option>
                <option value={2}>Lost Date</option>
              </select>
              {/* <div className="over_pro_st m-0">
                <button className="cust-btn addbtn m-0  bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 py-2">
                  SO
                </button>
              </div>
              <div className="flex-wrap">
                <p className="over_p_tag f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 m-0">
                  Presentation
                </p>
                <p className="over_open f1 fs-15 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-12 fs-xl-13 fs-xxl-14 primary1 m-0">
                  New
                </p>
              </div> */}
            </div>
            <h5 className=" f3 fs-13 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark my-3">
              Project Info
            </h5>
            <div className="py-2">
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Project ID
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="text"
                  value={projectCode}
                  readOnly
                />
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Partner
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Tags"
                    type="text"
                    value={
                      partnerName?.length > 0 ? partnerName : "Select Partner"
                    }
                    onClick={() => {
                      setDropDown(1);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(1);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 1 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 1 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchPartnerFun(e.target.value)}
                        value={searchPartner}
                      />
                    </div>
                    {partnerList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                          onClick={() => {
                            onPartnerNameSelect(item?.name, item?.id);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                          >
                            {partnerName == item?.name ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {partnerID?.length == 0 && partnerIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Partner</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Lead sources
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Tags"
                    type="text"
                    value={leadName ? leadName : "Select Lead sources"}
                    onClick={() => {
                      setDropDown(2);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(2);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 2 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 2 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchLeadFun(e.target.value)}
                        value={searchLead}
                      />
                    </div>
                    {leadList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                          onClick={() => {
                            onLeadSelect(item?.name, item?.id);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                          >
                            {leadName == item?.name ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {leadID?.length == 0 && leadIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Lead sources</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Type of Order
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Type of Order"
                    type="text"
                    value={
                      typeOfOrderName ? typeOfOrderName : "Select Type of Order"
                    }
                    onClick={() => {
                      setDropDown(3);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(3);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 3 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 3 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchTypeofOrderFun(e.target.value)}
                        value={searchTypeOfOrder}
                      />
                    </div>
                    {typeOfOrderList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                          onClick={() => {
                            onTypeofOrderSelect(item?.name, item?.id);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                          >
                            {typeOfOrderName == item?.name ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {typeOfOrderID?.length == 0 && typeOfOrderIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Type of Order</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Location
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Location"
                    type="text"
                    value={locationName ? locationName : "Select Location"}
                    onClick={() => {
                      setDropDown(4);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(4);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 4 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 4 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchLocationFun(e.target.value)}
                        value={searchLocation}
                      />
                    </div>
                    {locationList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                          onClick={() => {
                            onLocationSelect(item?.name, item?.id);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                          >
                            {locationName == item?.name ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {locationID?.length == 0 && locationIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Location</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start"
                  style={{ marginLeft: "0px" }}
                >
                  Organization Name
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="text"
                  readOnly
                  value={projectData?.project_request?.user?.organisation}
                />
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Project Start date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="date"
                  value={start_date}
                  readOnly
                  onChange={(e) => setstart_date(e.target.value)}
                />
                {start_date?.length == 0 && start_date?.length == 0 && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">
                      Select Project Start date
                    </p>
                  </div>
                )}
              </div>

              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  In Hands Date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="date"
                  onChange={(e) => setin_hand_date(e.target.value)}
                  value={in_hand_date}
                />
                {in_hand_date?.length == 0 && in_hand_dateErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select In Hands Date</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Budget
                </p>
                <div className="d-flex">
                  <div className="editBtnSelect  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black  py-2 over_budgets">
                    <span className="over_budget_icon">₹</span>
                  </div>
                  <input
                    className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black  py-2"
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 0px" }}
                    onChange={(e) => settotal_amount(e.target.value)}
                    value={total_amount}
                  />
                </div>
                {total_amount?.length == 0 && total_amountErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Budget</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Initial Client Contact
                </p>
                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="text"
                  value={projectData?.project_request?.first_name}
                  readOnly
                />

                {/* <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>Cli</option>
                  <option>Cli 2</option>
                  <option>Cli 3</option>
                </select> */}
              </div>
              <div className="d-flex flex-wrap mt-3">
                <span className="w-100">
                  {projectData?.project_request?.email}
                </span>
                <span>W: {projectData?.project_request?.mobile}</span>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Client Rep
                </p>

                <div className="position-relative">
                  <input
                    placeholder="Location"
                    type="text"
                    value={clientName ? clientName : "Select Client Rep"}
                    onClick={() => {
                      setDropDown(5);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(5);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 5 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 5 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchClientFun(e.target.value)}
                        value={searchClient}
                      />
                    </div>
                    {clientList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                          onClick={() => {
                            onClientSelect(item?.first_name, item?.id);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item?.first_name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                          >
                            {clientName == item?.first_name ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {clientID?.length == 0 && clientIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Client Rep</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Order Rep
                </p>

                <div className="position-relative">
                  <input
                    placeholder="Location"
                    type="text"
                    value={OrderName ? OrderName : "Select Order Rep"}
                    onClick={() => {
                      setDropDown(6);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(6);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 6 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 6 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchOderFun(e.target.value)}
                        value={searchOrder}
                      />
                    </div>
                    {orderList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                          onClick={() => {
                            onOderSelect(item?.first_name, item?.id);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item?.first_name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                          >
                            {OrderName == item?.first_name ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {orderID?.length == 0 && orderIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Order Rep</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Production Rep
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Production Rep"
                    type="text"
                    value={
                      productionName ? productionName : "Select Production Rep"
                    }
                    onClick={() => {
                      setDropDown(7);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(7);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 7 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 7 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchProductionFun(e.target.value)}
                        value={searchProduction}
                      />
                    </div>
                    {productionList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                          onClick={() => {
                            onProductionSelect(item?.first_name, item?.id);
                          }}
                        >
                          <button className="px-2 cust-btn text-start py-1 w-100 ">
                            {item?.first_name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                          >
                            {productionName == item?.first_name ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {productionID?.length == 0 && productionIDErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Production Rep</p>
                  </div>
                )}
              </div>
              <div className="">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Currency
                </p>
                <div className="d-flex ac-jb">
                  <span>INR</span>{" "}
                  <button
                    onClick={() => toggleShowPopup()}
                    class="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded opacity-50"
                  >
                    Change
                  </button>
                  {/* <button
                    className="mx-3 cust-btn addbtn ms-1  bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-8 py-2 py-md-2 px-5 rounded"
                    onClick={() => toggleShowPopup()}
                  >
                    Change
                  </button> */}
                </div>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Project Tags
                </p>

                <div className="position-relative">
                  <input
                    placeholder="Project Tags"
                    type="text"
                    value={
                      Tags?.length > 0
                        ? Tags?.toString()
                        : "Select Project Tags"
                    }
                    onClick={() => {
                      setDropDown(8);
                    }}
                    className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(8);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                  {dropDown == 8 && (
                    <div
                      className="invisible-cont2 z-0"
                      onClick={() => setDropDown(null)}
                    />
                  )}
                  <div
                    className={`${
                      dropDown == 8 && "submenu_1  py-2"
                    } submenu_cont_1 overflow-scroll z-3`}
                  >
                    <div className="d-flex gap-1">
                      <input
                        className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                        placeholder="Search..."
                        onChange={(e) => searchTagsFun(e.target.value)}
                        value={searchTags}
                      />
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => {
                          allSelectTags();
                        }}
                      >
                        All
                      </button>
                    </div>
                    {tagNormalList?.map((item, ind) => {
                      return (
                        <button
                          className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                          key={ind}
                        >
                          <button
                            className="px-2 cust-btn text-start py-1 w-100 "
                            onClick={() => {
                              onTagsSelect(item?.name, item?.id);
                            }}
                          >
                            {item?.name}
                          </button>
                          <button
                            className="px-2 cust-btn text-start"
                            style={{ fontSize: "large" }}
                            onClick={() => {
                              onTagsSelect(item?.name, item?.id, "check");
                            }}
                          >
                            {Tags?.includes(item?.name) ? (
                              <CheckCircleIcon className="primary" />
                            ) : (
                              <RadioButtonUncheckedIcon className="primary" />
                            )}
                          </button>
                        </button>
                      );
                    })}
                  </div>
                </div>
                {normalTag?.length == 0 && normalTagErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Select Tags</p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Expected Closing Date
                </p>

                <input
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2"
                  type="date"
                  onChange={(e) => setclosing_date(e.target.value)}
                  value={closing_date}
                />
                {closing_date?.length == 0 && closing_dateErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">
                      Select Expected Closing Date
                    </p>
                  </div>
                )}
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Project Email
                </p>
                <div className="d-flex">
                  <input
                    className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black py-2"
                    type="text"
                    style={{ borderRadius: "5px 0px 0px 5px" }}
                  />

                  <span className="editBtnSelect  f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black  py-2 over_budget">
                    <ContentPasteOutlinedIcon />
                  </span>
                </div>
              </div>
              <div>
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Project Template
                </p>

                <select className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust py-2">
                  <option>Default Template</option>
                </select>
              </div>
              <div className="d-flex mt-3">
                <input type={"checkbox"} />
                <p style={{ marginLeft: "2%", fontWeight: "bold" }}>
                  Show Warning for Prop65
                </p>
              </div>

              <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
                <p
                  className="w-100 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 text-start py-2"
                  style={{ marginLeft: "0px" }}
                >
                  Other Information
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    value={
                      dynamicAttr?.length > 0
                        ? dynamicAttr
                        : "Select Other Information"
                    }
                    onClick={(e) => setDropDown(77)}
                    readOnly
                  />
                  <button
                    className="drop_down cust-btn"
                    onClick={() => {
                      setDropDown(77);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </button>
                </div>
                {dropDown == 77 && (
                  <div
                    className="invisible-cont2"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropDown == 77 && "submenu_1 "
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  <div className="d-flex gap-1">
                    <input
                      className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                      placeholder="Search..."
                      onChange={(e) => searchProductInfoFun(e.target.value)}
                      value={searchProInfo}
                    />
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => {
                        allSelectProductInfo();
                      }}
                    >
                      All
                    </button>
                  </div>
                  {listAttributes?.map((item, ind) => {
                    return (
                      <div className="d-flex as-jb hover-cust" key={ind}>
                        <button
                          className="px-2 cust-btn text-start py-1 w-100 "
                          onClick={() => {
                            onDynamicAttributeSelect(item?.name, item?.id, ind);
                            setDropDown(null);
                          }}
                        >
                          {item?.name}
                        </button>
                        <button
                          className="px-2 cust-btn text-start"
                          onClick={() => {
                            onDynamicAttributeSelect(item?.name, item?.id, ind);
                          }}
                        >
                          {dynamicAttr?.includes(item?.name) ? (
                            <CheckCircleIcon className="primary" />
                          ) : (
                            <RadioButtonUncheckedIcon className="primary" />
                          )}

                          {dynamicAttr?.includes(item?.list)
                            ? item?.check
                            : item.uncheck}
                        </button>
                      </div>
                    );
                  })}
                </div>
                {otherProInfo?.length == 0 && otherProInfoErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">
                      Select Other Product Information
                    </p>
                  </div>
                )}
              </div>

              <div className="d-flex gap-3 flex-wrap">
                {productInformation?.map((item, ind) => {
                  return (
                    <Col lg={5} md={5} sm={5} xs={12} key={ind}>
                      <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                        {item?.title}
                      </p>
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        onChange={(e) =>
                          handleProductInformation(e.target.value, ind)
                        }
                        value={item?.value}
                      />

                      {item?.value?.length == 0 && productInformationErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">fill Information</p>
                        </div>
                      )}
                    </Col>
                  );
                })}
              </div>

              <div>
                <button
                  className={`cust-btn addbtn mt-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded ${
                    btn && "opacity-50"
                  }`}
                  onClick={() => {
                    submitHandle();
                  }}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currencyBtn && (
        <AddProjectPopup
          toggleShowPopup={toggleShowPopup}
          popupType={"currency"}
        />
      )}
      {LostBtn && (
        <OverViewLostPopup
          toggleShowPopup={toggleShowLoastPopup}
          projectData={projectData}
        />
      )}
      {selectFiles && (
        <AddFilesPopupCopy
          toggleShowPopup={toggleShowPopups}
          btnChange={btnChange}
          add={add}
          OnpostList={OnpostList}
        />
      )}
    </>
  );
};

export default OverView;
