import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  sub_menu,
  Product_Name,
  Hsn_type,
  Hsn_type_Child2,
  Hsn_type_Child3,
  Hsn_type_Child4,
  catogory_setting,
  counting,
  line_business,
  static_attributes,
  dynamic_attributes,
  colors,
  Select_list,
} from "../../redux/api/DummyJson";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import Select from "react-select";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  Circle,
  CircleOutlined,
  DeleteForever,
} from "@mui/icons-material";
import { countriesList } from "../../redux/api/Country";
import { Col } from "react-bootstrap";
import { toggleOff, toggleOn } from "../../assets/img";
import SelectBox from "../SelectBox";

const FormComp = ({
  setVarientsCheck,
  varientsCheck,
  vendor,
  selColors,
  setSelColors,
  parentCategoryList,
  getCategoryId,
  subCategoryList,
  setSubCategoryList,
  subCategoryLists,
  setSubCategoryLists,
  setParentCategory,
  decorativeList,
  decorativeID,
  setDecorativeID,
  decorativeName,
  setDecorativeName,
  country,
  setCountry,
  hsnList,
  hsnCode,
  setHsnCode,
  hsnName,
  setHsnName,
  tagsList,
  tagsEventList,
  tagsIconList,
  tags,
  setTags,
  tagsName,
  setTagsName,
  tagsEvent,
  setTagsEvent,
  tagsEventName,
  setTagsEventName,
  tagsIcon,
  setTagsIcon,
  tagsIconName,
  setTagsIconName,
  productInformationErr,
  colorsList,
  colorsID,
  setColorsID,
  colorsName,
  setColorsName,
  varientCombination,
  varientShow,
  sizes,
  setSizes,
  materials,
  setMeterials,
  styles,
  setStyles,
  allVarients,
  deleteVarient,
  catogoryName,
  setCatogoryName,
  searchValueSubCategory,
  setSearchSubValueCategory,
  productSuplierCode,
  setProductSuplierCode,
  onSubCategorySelect,
  categoryID,
  setCategoryID,
  name,
  setName,
  mrp,
  setMrp,
  decorationMethod,
  setDecorationMethod,
  productDescription,
  setProductDescription,
  productPackageSize,
  setProductPackageSize,
  productPackageSizeErr,
  setProductPackageSizeErr,
  productDescriptionErr,
  setProductDescriptionErr,
  decorationMethodErr,
  setDecorationMethodErr,
  mrpErr,
  setMrpErr,
  nameErr,
  setNameErr,
  countryErr,
  setCountryErr,
  categoryIDErr,
  setCategoryIDErr,
  setGendor,
  gendor,
  gendorErr,
  secondaryCategoryId,
  setSecondaryCategoryId,
  primaryCategoryId,
  setPrimaryCategoryId,
  submitHandle,
  attributesList,
  productInformation,
  setProductInformation,
  varientsList,
  setVarientList,
  colorsData,
  setColorsData,
  submitVarient,
  primaryCategoryIdErr,
  secondaryCategoryIdErr,
  setTagVal,
  setTagEventVal,
  setTagIconVal,
  hsnCodeErr,
  tagErr,
  tagEventErr,
  tagIconErr,
  tagVal,
  tagEventVal,
  tagIconVal,
  btn,
  submitBtn,
  catogory,
  setCatogory,
  listAttributes,
  setListAttributes,
  dynamicAttr,
  setDynamicAttr,
  viewData,
  setOtherProInfo,
  otherProInfoErr,
  otherProInfo,

  addVarientErr,
  setAddVarientErr,
  addVarients,
  setAddVarients,
  colorVarientErr,
  setColorVarientErr,
  sizeVarientErr,
  setSizeVarientErr,
  styleVarientErr,
  setStyleVarientErr,
  matVarientErr,
  setMatVarientErr,
  colorVarient,
  setColorVarient,
  sizeVarient,
  setSizeVarient,
  styleVarient,
  setStyleVarient,
  matVarient,
  setMatVarient,
  attributesName,
  setAttributesName,
  submitData,
  stylesErr,
  materialsErr,
  sizesErr,
  colosErr,

  onAddPoints,
  onDeletePoints,
  handlePoints,
  pointsArr,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle List
  const [catogoryShow, setCatogoryShow] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [dropDown, setDropDown] = useState(null);
  const [subcatogory, setSubcatogory] = useState(false);
  const [Dropdown, setDrop] = useState(0);
  const [showDynamicAttr, setShowDynamicAttr] = useState(false);
  const [showVarient, setShowVarient] = useState(false);
  const [attributesShow, setAttributesShow] = useState(false);
  const [varients, setVarients] = useState(null);

  // Select Fuctction
  const [check, setCheck] = useState([]);
  const [decorativeLists, setDecorativeLists] = useState([]);
  const [selectALLSub, setSelectALLSub] = useState(true);
  const [selectALLTags, setSelectALLTags] = useState(true);
  const [selectALLTagsEvent, setSelectALLTagsEvent] = useState(true);
  const [selectALLTagsIcon, setSelectALLTagsIcon] = useState(true);
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [selectALLColors, setSelectALLColors] = useState(true);
  const [searchValueSub, setSearchValueSub] = useState("");
  const [listCountries, setListCountries] = useState([]);
  const [listHSN, setListHSN] = useState([]);
  const [searchValueCountry, setSearchValueCountry] = useState("");
  const [searchValueGendor, setSearchValueGendor] = useState("");
  const [searchHsn, setSearchHsn] = useState("");
  const [listTags, setListTags] = useState([]);
  const [listIconTags, setListIconTags] = useState([]);
  const [listEventTags, setListEventTags] = useState([]);
  const [searchTags, setSearchTags] = useState("");
  const [searchEventTags, setSearchEventTags] = useState("");
  const [searchIconTags, setSearchIconTags] = useState("");
  const [searchProInfo, setSearchProInfo] = useState("");
  const [searchColors, setSearchColors] = useState("");
  const [listColors, setListColors] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchValueCategory, setSearchValueCategory] = useState("");

  const gendorList = ["NA", "U", "M", "F", "K"];
  const [listGendor, setListGendor] = useState([]);

  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    if (decorativeList?.length > 0) {
      setDecorativeLists(decorativeList);
    }
    if (hsnList?.length > 0) {
      setListHSN(hsnList);
    }
    if (tagsList?.length > 0) {
      setListTags(tagsList);
    }
    if (tagsEventList?.length > 0) {
      setListEventTags(tagsEventList);
    }
    if (tagsIconList?.length > 0) {
      setListIconTags(tagsIconList);
    }
    if (colorsList?.length > 0) {
      setListColors(colorsList);
    }
    if (parentCategoryList?.length > 0) {
      setCategoryList(parentCategoryList);
    }

    if (attributesList?.length > 0) {
      setListAttributes(attributesList);
    }

    setListGendor(gendorList);
    setListCountries(countriesList);
  }, [
    decorativeList,
    hsnList,
    tagsList,
    tagsEventList,
    tagsIconList,
    colorsList,
    parentCategoryList,
    attributesList,
  ]);

  // SEARCH CATEGROY
  const searchCategory = (event) => {
    let temp = [...parentCategoryList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setCategoryList(search);
    } else {
      setCategoryList(parentCategoryList);
    }

    setSearchValueCategory(event);
  };

  // SUB CATEGORY SEARCH
  const searchSubCatFun = (event, ind) => {
    let temp = [...subCategoryLists];
    let tempX = temp[ind];
    let temps = [...subCategoryList];
    let tempXs = temps[ind];
    let search = tempX?.child_categories?.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    // console.log("search", search);

    tempXs = search;

    let obj = {
      ...subCategoryList[ind],
      child_categories: tempXs,
    };

    let arr = [...subCategoryList];
    arr[ind] = obj;

    // console.log("tempXs", obj, arr);

    if (event?.length > 0) {
      setSubCategoryList(arr);
    } else {
      // console.log("jjj");
      setSubCategoryList(subCategoryLists);
    }

    setSearchSubValueCategory((val) => ({ ...val, [ind]: event }));
  };

  // SELECT CATEGORY
  const onCategorySelct = (item) => {
    setCatogoryName({ [item?.id]: "" });
    setCatogoryShow(false);
    setCatogory(item?.name);
    setPrimaryCategoryId(item?.id);
    setSubcatogory(true);
    setProductSuplierCode("");
    getCategoryId(item?.id, "category");
    setCategoryList(parentCategoryList);

    setSearchValueCategory("");
    setDropDown(null);
    // setParentCategory(item?.id);
  };

  // Decoration Method START
  const allSelectSub = () => {
    let decorativeListId = [];
    let decorativeListName = [];
    if (selectALLSub) {
      decorativeList?.map((item, ind) => {
        decorativeListId.push(item?.id);
        decorativeListName.push(item?.name);
        setDecorationMethod(item?.id);
      });

      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    } else {
      setDecorativeID(decorativeListId);
      setDecorativeName(decorativeListName);
    }
  };

  const searchSub = (event) => {
    let temp = [...decorativeList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setDecorativeLists(search);
    } else {
      setDecorativeLists(decorativeList);
    }

    setSearchValueSub(event);
  };

  const onSubSelect = (event, id, type) => {
    let temp = [...decorativeName];
    let temps = [...decorativeID];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setDecorativeName(list);
    } else {
      setDecorativeName([...temp, event]);
    }

    if (temps.includes(id)) {
      const listtemp = temps.indexOf(id);
      const list = [...temps];
      list.splice(listtemp, 1);
      setDecorativeID(list);
    } else {
      setDecorativeID([...temps, id]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchValueSub("");
    setDecorativeLists(decorativeList);
    setDecorationMethod(id);
  };
  // Decoration Method END

  // COUNTY START
  const searchCountry = (event) => {
    let temp = [...countriesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListCountries(search);
    } else {
      setListCountries(countriesList);
    }

    setSearchValueCountry(event);
  };

  const getCountry = (event) => {
    setDropDown(null);
    setCountry(event);
    setSearchValueCountry("");
    setListCountries(countriesList);
  };
  // COUNTY END

  // GENDOR SEARCH
  const searchGendor = (event) => {
    let temp = [...gendorList];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListGendor(search);
    } else {
      setListGendor(gendorList);
    }

    setSearchValueGendor(event);
  };

  // GENDOR SELECT
  const onSelectGendor = (item) => {
    setGendor(item);
    setDropDown(null);
    setSearchValueGendor("");
    setListGendor(gendorList);
  };

  // HSN START
  const searchHSNValue = (event) => {
    let temp = [...hsnList];
    let search = temp.filter((value) =>
      value?.code?.toLowerCase()?.includes(event?.toLowerCase())
    );
    if (event?.length > 0) {
      setListHSN(search);
    } else {
      setListHSN(hsnList);
    }

    setSearchHsn(event);
  };

  const getHSN = (event) => {
    setDropDown(null);
    setHsnCode(event?.id);
    setHsnName(event?.code);
    setSearchHsn("");
    setListHSN(hsnList);
  };
  // HSN END

  // TAGS START
  const allSelectTags = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTags) {
      tagsList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagVal(item?.id);
      });

      setTags(ListId);
      setTagsName(ListName);
    } else {
      setTags(ListId);
      setTagsName(ListName);
    }

    setSelectALLTags(!selectALLTags);
  };

  const onTagsSelect = (event, id, type) => {
    let temp = [...tags];
    let temps = [...tagsName];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTags(list);
    } else {
      setTags([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsName(list);
    } else {
      setTagsName([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchTags("");
    setListTags(tagsList);
    setTagVal(id);
  };

  const searchTagsFun = (event) => {
    let temp = [...tagsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListTags(search);
    } else {
      setListTags(tagsList);
    }

    setSearchTags(event);
  };
  // TAGS END

  // TAGS EVENT START
  const allSelectTagsEvent = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTagsEvent) {
      tagsEventList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagIconVal(item?.id);
      });

      setTagsEvent(ListId);
      setTagsEventName(ListName);
    } else {
      setTagsEvent(ListId);
      setTagsEventName(ListName);
    }

    setSelectALLTagsEvent(!selectALLTagsEvent);
  };

  const onTagsSelectEvent = (event, id, type) => {
    let temp = [...tagsEvent];
    let temps = [...tagsEventName];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTagsEvent(list);
    } else {
      setTagsEvent([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsEventName(list);
    } else {
      setTagsEventName([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchEventTags("");
    setListEventTags(tagsEventList);
    setTagEventVal(id);
  };

  const searchTagsFunEvent = (event) => {
    let temp = [...tagsEvent];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListEventTags(search);
    } else {
      setListEventTags(tagsList);
    }

    setSearchEventTags(event);
  };
  // TAGS EVENT END

  // TAGS ICON START
  const allSelectTagsIcon = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLTagsIcon) {
      tagsIconList?.map((item, ind) => {
        ListId.push(item?.id);
        ListName.push(item?.name);
        setTagIconVal(item?.id);
      });

      setTagsIcon(ListId);
      setTagsIconName(ListName);
    } else {
      setTagsIcon(ListId);
      setTagsIconName(ListName);
    }

    setSelectALLTagsIcon(!selectALLTagsIcon);
  };

  const onTagsSelectIcon = (event, id, type) => {
    let temp = [...tagsIcon];
    let temps = [...tagsIconName];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setTagsIcon(list);
    } else {
      setTagsIcon([...temp, id]);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setTagsIconName(list);
    } else {
      setTagsIconName([...temps, event]);
    }

    if (type !== "check") {
      setDropDown(null);
    }

    setSearchIconTags("");
    setListIconTags(tagsIconList);
    setTagIconVal(id);
  };

  const searchTagsFunIcon = (event) => {
    let temp = [...tagsIcon];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListIconTags(search);
    } else {
      setListIconTags(tagsList);
    }

    setSearchIconTags(event);
  };
  // TAGS ICON END

  // COLORS START
  const allSelectColors = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLColors) {
      colorsList?.map((item, ind) => {
        ListId.push({ id: item?.id });
        ListName.push(item?.name);
        setColorVarient(item?.id);
      });

      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData(colorsList);
    } else {
      setColorsID(ListId);
      setColorsName(ListName);
      setColorsData([]);
      setColorVarient("");
    }

    setSelectALLColors(!selectALLColors);
  };

  const onColorsSelect = (event, id, item, ind, type) => {
    let temp = [...colorsID];
    let temps = [...colorsName];
    let temx = [...colorsData];

    if (temp.find((i) => i.id == id)) {
      let finder = temp.filter((i) => i.id !== id);
      setColorsID(finder);
    } else {
      let obj = {
        id: id,
      };
      temp.push(obj);
      setColorsID(temp);
    }

    if (temps.includes(event)) {
      const listtemp = temps.indexOf(event);
      const list = [...temps];
      list.splice(listtemp, 1);
      setColorsName(list);
    } else {
      setColorsName([...temps, event]);
    }

    // if (temx.includes(item)) {
    //   const listtemp = temx.indexOf(item);
    //   const list = [...temx];
    //   list.splice(listtemp, 1);
    //   setColorsData(list);
    // } else {
    //   setColorsData([...temx, item]);
    // }

    if (temx.find((i) => i.id == id)) {
      let finder = temx.filter((i) => i.id !== id);
      setColorsData(finder);
    } else {
      temx.push(item);
      setColorsData(temx);
    }

    if (type !== "check") {
      setVarients(null);
    }

    setSearchColors("");
    setListColors(colorsList);

    setColorVarient(id);

    setVarientList([]);
  };

  // console.log("colorsData", colorsData);
  // console.log("colorsID", colorsID);

  const searchColorsFun = (event) => {
    let temp = [...colorsList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListColors(search);
    } else {
      setListColors(colorsList);
    }

    setSearchColors(event);
  };
  // COLORS END

  // SIZE START
  const handleAdd = () => {
    const abc = [...sizes];
    abc.push({ value: "" });
    setSizes(abc);
    setVarientList([]);
  };
  const handleChange = (event, ind, type) => {
    const inputdata = [...sizes];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setSizes(inputdata);
    setSizeVarient(event);
    setVarientList([]);
  };
  const handleDelete = (i) => {
    const deletVal = [...sizes];
    deletVal.splice(i, 1);
    setSizes(deletVal);
    setVarientList([]);
  };
  // SIZE END

  // MATERIAL START
  const handleMatAdd = () => {
    const abc = [...materials];
    abc.push({ value: "" });
    setMeterials(abc);
    setVarientList([]);
  };
  const handleMatChange = (event, ind, type) => {
    const inputdata = [...materials];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setMeterials(inputdata);
    setMatVarient(event);
    setVarientList([]);
  };
  const handleMatDelete = (i) => {
    const deletVal = [...materials];
    deletVal.splice(i, 1);
    setMeterials(deletVal);
    setVarientList([]);
  };
  // MATERIAL END

  // STYLES START
  const handleStyleAdd = () => {
    const abc = [...styles];
    abc.push({ value: "" });
    setStyles(abc);
    setVarientList([]);
  };
  const handleStyleChange = (event, ind, type) => {
    const inputdata = [...styles];
    let obj = {
      ...inputdata[ind],
      value: event,
    };
    inputdata[ind] = obj;
    setStyles(inputdata);
    setStyleVarient(event);
    setVarientList([]);
  };
  const handleStyleDelete = (i) => {
    const deletVal = [...styles];
    deletVal.splice(i, 1);
    setStyles(deletVal);
    setVarientList([]);
  };
  // STYLES END

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");

    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
  };

  // console.log("otherProInfo", otherProInfo);

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  const onColorSelect = (e) => {
    if (selColors.includes(e)) {
      const listtemp = selColors.indexOf(e);
      const list = [...selColors];
      list.splice(listtemp, 1);
      setSelColors(list);
    } else {
      setSelColors([...selColors, e]);
    }
  };

  const onClickShowVarient = () => {
    setShowVarient(!showVarient);
  };

  const onAttribute = (e) => {
    setAttributesName(e.target.value);
    if (decorativeName.length >= 2) {
      setAttributesShow(true);
    } else {
      if (decorativeName.length >= 0) setAttributesShow(false);
    }
  };
  const onAttributeClick = (e) => {
    if (attributesName?.includes(e)) {
      const listtemp = attributesName?.indexOf(e);
      const list = [...attributesName];
      list.splice(listtemp, 1);
      setAttributesName(list);

      if (e == "Size") {
        setSizes([{ value: "" }]);
      }

      if (e == "Color") {
        setColorsID([]);
        setColorsName([]);
        setColorsData([]);
        setColorVarient("");
      }

      if (e == "Materials") {
        setMeterials([{ value: "" }]);
      }

      if (e == "Style") {
        setStyles([{ value: "" }]);
      }
    } else {
      setAttributesName([...attributesName, e]);
    }

    if (e == addVarients) {
      setAddVarients("");
    } else {
      setAddVarients(e);
    }
    setVarientsCheck(e);
    setVarientList([]);
  };

  // TAGS START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
        setOtherProInfo(item?.id);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
      setOtherProInfo("");
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };
  // TAGS END

  // console.log("catogoryName", catogoryName);

  // console.log("allVarients", allVarients);

  const getView = () => {
    let temp = [];

    if (viewData?.colors?.length > 0) {
      temp.push("Color");
    }

    if (viewData?.sizes?.length > 0) {
      temp.push("Size");
    }

    if (viewData?.materials?.length > 0) {
      temp.push("Materials");
    }

    if (viewData?.styles?.length > 0) {
      temp.push("Style");
    }

    setAttributesName(temp);
    setSubcatogory(true);
  };

  useEffect(() => {
    if (location?.state?.data?.id && viewData?.id) {
      getView();
    }
  }, [viewData]);

  useEffect(() => {
    if (colorsID?.length == 0) {
      setColorVarient("");
    }
  }, [colorsID]);

  // console.log("subCategoryList", subCategoryList);
  // console.log("catogoryName", catogoryName);

  return (
    <div className="w-100">
      <div className="d-flex flex-wrap ac-jb flex-m-r">
        <div className="w-xl-48 position-relative w-lg-48 w-md-100 w-sm-100 w-xs-100 drop-dow">
          <p className="text-dark f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Category
          </p>
          <fieldset
            disabled={
              location?.state?.data?.id ? true : submitData?.id ? true : false
            }
          >
            <div className="position-relative z-3">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={catogory}
                onClick={(e) => {
                  setCatogoryShow(!catogoryShow);
                }}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setCatogoryShow(!catogoryShow);
                  setDropDown(null);
                  setAttributesShow(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
          </fieldset>
          {primaryCategoryId?.length == 0 && primaryCategoryIdErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Select Category</p>
            </div>
          )}
          {catogoryShow && (
            <div
              className="invisible-cont2 z-0"
              onClick={() => setCatogoryShow(!catogoryShow)}
            />
          )}
          <div
            className={`${
              catogoryShow && "submenu_1"
            } submenu_cont_1 overflow-scroll z-3`}
          >
            <div className="d-flex gap-1">
              <input
                className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                placeholder="Search..."
                onChange={(e) => searchCategory(e.target.value)}
                value={searchValueCategory}
              />
            </div>
            {categoryList?.map((item, ind) => {
              return (
                <div className="d-flex hover-cust" key={ind}>
                  <button
                    className="px-2 cust-btn text-start py-1 w-100"
                    onClick={() => {
                      onCategorySelct(item);
                      // setParentCategory(item?.id);
                    }}
                  >
                    {item?.name}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        {subcatogory && (
          <>
            {subCategoryList?.map((item, ind) => {
              return (
                <div
                  className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative"
                  key={ind}
                >
                  <fieldset
                    disabled={
                      location?.state?.data?.id
                        ? true
                        : submitData?.id
                        ? true
                        : false
                    }
                  >
                    <p
                      className={`black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ${
                        location?.state?.data?.id && "text-white"
                      }`}
                    >
                      {item?.category?.name}
                    </p>
                    <div className="position-relative">
                      <input
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                        value={catogoryName?.[item?.category?.id]}
                        onClick={() => setCatogoryShow2(ind)}
                        readOnly
                      />
                      <button
                        className="drop_down cust-btn"
                        onClick={() => {
                          setCatogoryShow2(ind);
                          setDropDown(null);
                          setCatogoryShow(false);
                          setAttributesShow(false);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </button>
                    </div>
                    {catogoryShow2 == ind && (
                      <div
                        className="invisible-cont2 z-0"
                        onClick={() => setCatogoryShow2(null)}
                      />
                    )}
                    <div
                      className={`${
                        catogoryShow2 == ind && "submenu_1"
                      } submenu_cont_1 overflow-scroll z-3`}
                    >
                      <div className="d-flex gap-1">
                        <input
                          className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                          placeholder="Search..."
                          onChange={(e) => searchSubCatFun(e.target.value, ind)}
                          value={searchValueSubCategory[ind]}
                        />
                      </div>
                      {item?.child_categories?.map((subItem, indx) => {
                        return (
                          <div className="d-flex hover-cust" key={indx}>
                            <button
                              className="px-2 cust-btn text-start py-1 w-100"
                              onClick={() => {
                                setCatogoryShow2(null);
                                onSubCategorySelect(subItem, indx, ind);
                              }}
                            >
                              {subItem?.name}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    {ind == 0 &&
                      secondaryCategoryId?.length == 0 &&
                      secondaryCategoryIdErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">
                            Select Sub Category 1
                          </p>
                        </div>
                      )}
                    {ind == 1 && categoryID?.length == 0 && categoryIDErr && (
                      <div className="d-flex gap-1 mt-2">
                        <ErrorIcon className="svg_log" />
                        <p className="err-input_log mt-1">
                          Select Sub Category 2
                        </p>
                      </div>
                    )}
                  </fieldset>
                </div>
              );
            })}

            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
              <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Sub Category Code
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  value={productSuplierCode}
                  onChange={(e) => setProductSuplierCode(e.target.value)}
                  type="text"
                  disabled
                />
              </div>
            </div>
          </>
        )}

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Name
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
            </div>
            {name?.length == 0 && nameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Name</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              MRP
            </p>
            <input
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setMrp(e.target.value)}
              value={mrp}
              type={"number"}
            />
            {mrp?.length == 0 && mrpErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter MRP</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Decoration Method
            </p>
            <div className="position-relative">
              <input
                placeholder="Decorative method"
                type="text"
                value={
                  decorativeName?.length > 0
                    ? decorativeName?.toString()
                    : "Select Decorative method"
                }
                onClick={() => {
                  setDropDown(0);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(0);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 0 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => {
                    setDropDown(null);
                  }}
                />
              )}
              <div
                className={`${
                  dropDown == 0 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchSub(e.target.value)}
                    value={searchValueSub}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      setSelectALLSub(!selectALLSub);
                      allSelectSub();
                    }}
                  >
                    All
                  </button>
                </div>
                {decorativeLists?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onSubSelect(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onSubSelect(item?.name, item?.id, "check");
                        }}
                      >
                        {decorativeName?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>

            {/* <SelectBox
              array_list={decorativeList} // array
              value_text={decorativeName} // array
              setvalue_text={setDecorativeName} // array
              setvalue_id={setDecorativeID} // array
              value_id={decorativeID} // array
              placeholder_text={"Select Decorative method"} // string
              setValidation={setDecorationMethod} // string
              isMulti={true} // boolean
              isTextArea={false}
            /> */}

            {decorationMethod?.length == 0 && decorationMethodErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Decoration Method</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Country of Origin
            </p>
            <div className="position-relative">
              <input
                placeholder="Country"
                type="text"
                value={
                  country?.length > 0 ? country?.toString() : "Select Country"
                }
                onClick={() => {
                  setDropDown(1);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(1);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 1 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 1 && "submenu_1 py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchCountry(e.target.value)}
                    value={searchValueCountry}
                  />
                </div>
                {listCountries?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        getCountry(item?.name);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {country?.length == 0 && countryErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Select Registered Address Country
                </p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Gender
            </p>
            <div className="position-relative">
              <input
                placeholder="Gender"
                type="text"
                value={
                  gendor?.length > 0 ? gendor?.toString() : "Select Gender"
                }
                onClick={() => {
                  setDropDown(11);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(11);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 11 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 11 && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchGendor(e.target.value)}
                    value={searchValueGendor}
                  />
                </div>
                {listGendor?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onSelectGendor(item);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {gendor?.length == 0 && gendorErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Gendor</p>
              </div>
            )}
          </fieldset>
        </div>

        {/* {(vendor === "Charles" || vendor === "Jake Adams") && ( */}
        <div className="w-100">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Product Description
            </p>
            <textarea
              placeholder="Product Description"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setProductDescription(e.target.value)}
              value={productDescription}
            />
            {productDescription?.length == 0 && productDescriptionErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Product Description</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-100 mt-3 mb-3">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 d-flex ac-jb">
              Points
              <span>
                <button
                  onClick={() => onAddPoints()}
                  className="  cust-btn addbtn ms-1 mt-0 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded"
                >
                  + Add
                </button>
              </span>
            </p>

            {pointsArr?.map((item, ind) => {
              return (
                <div className="d-flex mt-3 ac-jc">
                  <p className="f3 mx-2 fs-18 primary">{ind + 1}.</p>
                  <input
                    placeholder="Product Points"
                    className="editBtn w-80 rounded-3  p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                    value={item?.value}
                    onChange={(e) => handlePoints(e.target.value, ind)}
                  />

                  <DeleteIcon
                    onClick={() => onDeletePoints(ind)}
                    className="text-danger fs-24 mx-2"
                  />
                </div>
              );
            })}
          </fieldset>
        </div>

        {/* )} */}
        {(vendor === "John Rolph" || vendor === "Jake Adams") && (
          <div className="w-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1">
              Decoration Description
            </p>
            <textarea
              placeholder="Autogenerate"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
            />
          </div>
        )}

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              HSN Code
            </p>
            <div className="position-relative">
              <input
                placeholder="HSN Code"
                type="text"
                value={hsnName ? hsnName : "Select HSN Code"}
                onClick={() => {
                  setDropDown(2);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(2);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 2 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 2 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchHSNValue(e.target.value)}
                    value={searchHsn}
                  />
                </div>
                {listHSN?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        getHSN(item);
                      }}
                      key={ind}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.code}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {hsnCode?.length == 0 && hsnCodeErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select HSN Code</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Tags
            </p>
            <div className="position-relative">
              <input
                placeholder="Tags"
                type="text"
                value={
                  tagsName?.length > 0 ? tagsName?.toString() : "Select Tags"
                }
                onClick={() => {
                  setDropDown(3);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(3);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 3 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 3 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFun(e.target.value)}
                    value={searchTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTags();
                    }}
                  >
                    All
                  </button>
                </div>
                {listTags?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelect(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelect(item?.name, item?.id, "check");
                        }}
                      >
                        {tagsName?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {tagVal?.length == 0 && tagErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Tag</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Event Tags
            </p>
            <div className="position-relative">
              <input
                placeholder="Tags"
                type="text"
                value={
                  tagsEventName?.length > 0
                    ? tagsEventName?.toString()
                    : "Select Tags"
                }
                onClick={() => {
                  setDropDown(13);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(13);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 13 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 13 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFunEvent(e.target.value)}
                    value={searchEventTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTagsEvent();
                    }}
                  >
                    All
                  </button>
                </div>
                {listEventTags?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelectEvent(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelectEvent(item?.name, item?.id, "check");
                        }}
                      >
                        {tagsEventName?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {tagEventVal?.length == 0 && tagEventErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Event Tag</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 py-2 w-lg-48 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Icon Tags
            </p>
            <div className="position-relative">
              <input
                placeholder="Tags"
                type="text"
                value={
                  tagsIconName?.length > 0
                    ? tagsIconName?.toString()
                    : "Select Tags"
                }
                onClick={() => {
                  setDropDown(31);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setDropDown(31);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {dropDown == 31 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setDropDown(null)}
                />
              )}
              <div
                className={`${
                  dropDown == 31 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchTagsFunIcon(e.target.value)}
                    value={searchIconTags}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectTagsIcon();
                    }}
                  >
                    All
                  </button>
                </div>
                {listIconTags?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onTagsSelectIcon(item?.name, item?.id);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onTagsSelectIcon(item?.name, item?.id, "check");
                        }}
                      >
                        {tagsIconName?.includes(item?.name) ? (
                          <CheckCircleIcon className="primary" />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {tagIconVal?.length == 0 && tagIconErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Icon Tag</p>
              </div>
            )}
          </fieldset>
        </div>

        <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Product Packaging Size
            </p>
            <input
              placeholder="L*W*H"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
              onChange={(e) => setProductPackageSize(e.target.value)}
              value={productPackageSize}
              // type={"number"}
            />
            {productPackageSize?.length == 0 && productPackageSizeErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Enter Product Packaging Size
                </p>
              </div>
            )}
          </fieldset>
        </div>

        {/* DYNAMIC ATTRIBUTES */}
        <div className="mt-5 w-md-100 w-sm-100 w-xs-100 position-relative">
          <fieldset disabled={submitData?.id ? true : false}>
            <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Other Product Information
            </p>
            <div className="position-relative">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={dynamicAttr}
                onClick={(e) => setShowDynamicAttr(!showDynamicAttr)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setShowDynamicAttr(!showDynamicAttr);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {showDynamicAttr && (
              <div
                className="invisible-cont2"
                onClick={() => setShowDynamicAttr(!showDynamicAttr)}
              />
            )}
            <div
              className={`${
                showDynamicAttr && "submenu_1 "
              } submenu_cont_1 overflow-scroll z-3`}
            >
              <div className="d-flex gap-1">
                <input
                  className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                  placeholder="Search..."
                  onChange={(e) => searchProductInfoFun(e.target.value)}
                  value={searchProInfo}
                />
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    allSelectProductInfo();
                  }}
                >
                  All
                </button>
              </div>
              {listAttributes?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust" key={ind}>
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                        setShowDynamicAttr(false);
                      }}
                    >
                      {item?.name}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onDynamicAttributeSelect(item?.name, item?.id, ind);
                      }}
                    >
                      {dynamicAttr?.includes(item?.name) ? (
                        <CheckCircleIcon className="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="primary" />
                      )}

                      {dynamicAttr?.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
            {otherProInfo?.length == 0 && otherProInfoErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  Select Other Product Information
                </p>
              </div>
            )}
          </fieldset>
        </div>

        {productInformation?.map((item, ind) => {
          return (
            <div
              className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100"
              key={ind}
            >
              <fieldset disabled={submitData?.id ? true : false}>
                <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  {item?.title}
                </p>
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  onChange={(e) =>
                    handleProductInformation(e.target.value, ind)
                  }
                  value={item?.value}
                />

                {item?.value?.length == 0 && productInformationErr && (
                  <div className="d-flex gap-1 mt-2">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">fill Information</p>
                  </div>
                )}
              </fieldset>
            </div>
          );
        })}
        {submitBtn == 0 && (
          <div className="d-flex justify-content-end w-100">
            <button
              class={`cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                btn && "opacity-50"
              }`}
              onClick={() => submitHandle()}
              disabled={btn}
            >
              Store Product
            </button>
          </div>
        )}

        {/* ----- */}
        {location?.state?.type !== "edit" && submitBtn !== 0 && (
          <div className="mt-3 w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="f4 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Add Variants
            </p>
            <div className="position-relative  w-sm-100 w-xs-100 x-md-100">
              <input
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                value={attributesName}
                onClick={() => setAttributesShow(!attributesShow)}
                readOnly
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setAttributesShow(!attributesShow);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
            </div>
            {attributesShow && (
              <div
                className="invisible-cont2"
                onClick={() => setAttributesShow(!attributesShow)}
              />
            )}
            <div
              className={`${
                attributesShow && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
            >
              {Select_list?.map((item, ind) => {
                return (
                  <div className="d-flex as-jb hover-cust" key={ind}>
                    <button
                      className="px-2 cust-btn text-start py-1 w-100 "
                      onClick={() => {
                        onAttributeClick(item?.list);
                        setAttributesShow(false);
                      }}
                      disabled={item?.list == "Color" ? true : false}
                    >
                      {item?.list}
                    </button>
                    <button
                      className="px-2 cust-btn text-start"
                      onClick={() => {
                        onAttributeClick(item?.list);
                      }}
                      disabled={item?.list == "Color" ? true : false}
                    >
                      {attributesName?.includes(item?.list)
                        ? item?.check
                        : item.uncheck}
                    </button>
                  </div>
                );
              })}
            </div>
            {varientsCheck?.length == 0 && addVarientErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Variants</p>
              </div>
            )}
          </div>
        )}
        {submitBtn !== 0 && attributesName?.includes("Color") && (
          <div className=" w-md-100 w-sm-100 w-xs-100 position-relative">
            <p className="black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Color
            </p>
            <div className="position-relative">
              <input
                placeholder="Color"
                type="text"
                value={
                  colorsName?.length > 0
                    ? colorsName?.toString()
                    : "Select Color"
                }
                onClick={() => {
                  setVarients(0);
                }}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
              <button
                className="drop_down cust-btn"
                onClick={() => {
                  setVarients(0);
                }}
              >
                <KeyboardArrowDownIcon />
              </button>
              {varients == 0 && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setVarients(null)}
                />
              )}
              <div
                className={`${
                  varients == 0 && "submenu_1  py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchColorsFun(e.target.value)}
                    value={searchColors}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      allSelectColors();
                    }}
                  >
                    All
                  </button>
                </div>
                {listColors?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      key={ind}
                    >
                      <button
                        className="px-2 cust-btn text-start py-1 w-100 "
                        onClick={() => {
                          onColorsSelect(item?.name, item?.id, item, ind);
                        }}
                      >
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                        onClick={() => {
                          onColorsSelect(
                            item?.name,
                            item?.id,
                            item,
                            ind,
                            "check"
                          );
                        }}
                      >
                        {colorsName?.includes(item?.name) ? (
                          <CheckCircleIcon style={{ color: item?.hex_code }} />
                        ) : (
                          <RadioButtonUncheckedIcon className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
            {colorVarient?.length == 0 && colorVarientErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Select Color</p>
              </div>
            )}
          </div>
        )}

        {sizes?.length > 0 && attributesName?.includes("Size") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Size
            </p>
            <div className="d-flex justify-content-start me-3 ">
              <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <button
                  className="border-0 primary f3 "
                  onClick={() => handleAdd()}
                >
                  +Add New
                </button>
              </div>
            </div>
            <div className="d-flex flex-wrap w-100 gap-2">
              {sizes?.map((data, i) => {
                return (
                  <div
                    className="d-flex  w-md-100 w-sm-100 w-xs-100"
                    style={{ width: "30% " }}
                  >
                    <div className="position-relative add w-100">
                      <input
                        value={data?.value}
                        onChange={(e) =>
                          handleChange(e.target.value, i, "value")
                        }
                        placeholder="Required Field"
                        className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      />
                      {data?.value?.length == 0 && sizesErr && (
                        <div className="d-flex gap-1 mt-2">
                          <ErrorIcon className="svg_log" />
                          <p className="err-input_log mt-1">Fill Size</p>
                        </div>
                      )}
                    </div>
                    {sizes?.length > 1 && (
                      <button className="mx-2 d-flex ac-jc cp cust-btn">
                        <DeleteForever
                          className="primary"
                          onClick={() => handleDelete(i)}
                        />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
            {sizeVarient?.length == 0 && sizeVarientErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Size</p>
              </div>
            )}
          </div>
        )}

        {materials?.length > 0 && attributesName?.includes("Materials") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Materials
            </p>
            <div className="d-flex justify-content-start me-3 ">
              <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <button
                  className="border-0 primary f3 "
                  onClick={() => handleMatAdd()}
                >
                  +Add New
                </button>
              </div>
            </div>
            <div className="d-flex flex-wrap w-100 gap-2">
              {materials?.map((data, i) => {
                return (
                  <>
                    <div
                      className="d-flex  w-md-100 w-sm-100 w-xs-100"
                      style={{ width: "30% " }}
                    >
                      <div className="position-relative add w-100">
                        <input
                          value={data?.value}
                          onChange={(e) =>
                            handleMatChange(e.target.value, i, "value")
                          }
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                        {data?.value?.length == 0 && materialsErr && (
                          <div className="d-flex gap-1 mt-2">
                            <ErrorIcon className="svg_log" />
                            <p className="err-input_log mt-1">Fill Materials</p>
                          </div>
                        )}
                      </div>
                      {materials?.length > 1 && (
                        <button className="mx-2 d-flex ac-jc cp cust-btn">
                          <DeleteForever
                            className="primary"
                            onClick={() => handleMatDelete(i)}
                          />
                        </button>
                      )}
                    </div>
                  </>
                );
              })}
            </div>

            {matVarient?.length == 0 && matVarientErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Materials</p>
              </div>
            )}
          </div>
        )}

        {styles?.length > 0 && attributesName?.includes("Style") && (
          <div className=" w-md-100 w-sm-100 w-xs-100">
            <p className=" black f4 fs-xs-12 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 mt-2 ">
              Style
            </p>
            <div className="d-flex justify-content-start me-3 ">
              <div className="editBtn text-center  rounded-3 mt-2 p-2  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                <button
                  className="border-0 primary f3 "
                  onClick={() => handleStyleAdd()}
                >
                  +Add New
                </button>
              </div>
            </div>
            <div className="d-flex flex-wrap w-100 gap-2">
              {styles?.map((data, i) => {
                return (
                  <>
                    <div
                      className="d-flex  w-md-100 w-sm-100 w-xs-100"
                      style={{ width: "30% " }}
                    >
                      <div className="position-relative add w-100">
                        <input
                          value={data?.value}
                          onChange={(e) =>
                            handleStyleChange(e.target.value, i, "value")
                          }
                          placeholder="Required Field"
                          className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        />
                        {data?.value?.length == 0 && stylesErr && (
                          <div className="d-flex gap-1 mt-2">
                            <ErrorIcon className="svg_log" />
                            <p className="err-input_log mt-1">Fill Style</p>
                          </div>
                        )}
                      </div>
                      {styles?.length > 1 && (
                        <button className="mx-2 d-flex ac-jc cp cust-btn">
                          <DeleteForever
                            className="primary"
                            onClick={() => handleStyleDelete(i)}
                          />
                        </button>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            {styleVarient?.length == 0 && styleVarientErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Style</p>
              </div>
            )}
          </div>
        )}

        {submitBtn !== 0 ? (
          <button
            className={`cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={() => {
              varientCombination();
            }}
            disabled={btn}
          >
            Generate SKU
          </button>
        ) : location?.state?.type == "edit" ? (
          <button
            className={`cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={() => {
              varientCombination();
            }}
            disabled={btn}
          >
            Generate SKU
          </button>
        ) : null}

        {varientShow && (
          <div className="w-100 d-flex flex-wrap mt-4">
            {varientsList?.map((item, ind) => {
              return (
                <Col md={12} lg={12} xl={12} xxl={12}>
                  <table className="w-100 mt-2" key={ind}>
                    <tr className="mt-4">
                      <div className="">
                        <td>
                          <div className="d-flex w-100">
                            {/* <div className="mx-2">
                              <p
                                className=" f2 fs-xs-11 fs-sm-12 cp fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-center primary f3"
                                style={{ width: "50px" }}
                              >
                                {check === 1 ? (
                                  <CheckBoxOutlined
                                    onClick={() => setCheck(undefined)}
                                  />
                                ) : (
                                  <CheckBoxOutlineBlank
                                    onClick={() => setCheck(1)}
                                  />
                                )}
                              </p>
                            </div> */}
                            <div className="mx-2 d-flex gap-2 ac-jc">
                              <p className=" f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 text-start primary f3">
                                {item?.parent_name}{" "}
                              </p>
                              <div
                                className="d-flex ac-js"
                                onClick={() =>
                                  deleteVarient(ind, 0, item?.status, "parent")
                                }
                              >
                                {item?.status == 0 ? (
                                  <div>
                                    <img
                                      src={toggleOff}
                                      className="toggleOnDes"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src={toggleOn}
                                      className="toggleOnDes"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                    {item?.childs?.map((child, inx) => {
                      return (
                        <tr className="mt-2">
                          <div className="my-3">
                            <td>
                              <div className="d-flex w-100">
                                {/* <div
                                  className="d-flex ac-jc mx-2"
                                  style={{ width: "50px" }}
                                >
                                  <button className="mx-2 d-flex ac-jc cust-btn">
                                    {checkBox || check == 1 ? (
                                      <CheckBoxOutlined
                                        className="primary cp"
                                        onClick={() => setCheckBox(false)}
                                      />
                                    ) : (
                                      <CheckBoxOutlineBlank
                                        className="primary cp"
                                        onClick={() => setCheckBox(true)}
                                      />
                                    )}
                                  </button>
                                </div> */}
                                <div className="mx-2">
                                  <div
                                    className="num-cls-btn d-flex overflow-scroll p-2"
                                    style={{ width: "auto" }}
                                  >
                                    <p className="text-wrap px-3 f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark text-nowrap">
                                      {child?.sku_name}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="d-flex ac-js"
                                  onClick={() => {
                                    if (item?.status == 1) {
                                      deleteVarient(
                                        ind,
                                        inx,
                                        child?.status,
                                        "child"
                                      );
                                    }
                                  }}
                                >
                                  {child?.status == 0 ? (
                                    <div>
                                      <img
                                        src={toggleOff}
                                        className="toggleOnDes"
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <img
                                        src={toggleOn}
                                        className="toggleOnDes"
                                      />
                                    </div>
                                  )}
                                </div>
                                {/* <button className="mx-2 d-flex ac-jc cp cust-btn">
                                  <DeleteForever
                                    className="primary"
                                    onClick={() => deleteVarient(ind, inx)}
                                  />
                                </button> */}
                              </div>
                            </td>
                          </div>
                        </tr>
                      );
                    })}
                  </table>
                </Col>
              );
            })}
          </div>
        )}

        {/* ----- */}
      </div>
      <div className="d-flex as-jb w-100 h-50 mt-5">
        <button
          class="cust-btn addbtn mt-sm-3 mx-3 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded "
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        {submitBtn == 2 && varientsList?.length > 0 ? (
          <button
            className={`cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
            // onClick={() =>
            //   navigate("/vendor-details", {
            //     state: { form: "vendor", type: vendor },
            //   })
            // }
            onClick={
              () => submitVarient()
              // navigate("/product-library")
            }
            disabled={btn}
          >
            Store SKU
          </button>
        ) : location?.state?.type == "edit" && varientsList?.length > 0 ? (
          <button
            className={`cust-btn addbtn mt-sm-3 align-selfe-center mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
              btn && "opacity-50"
            }`}
            onClick={() => submitVarient()}
            disabled={btn}
          >
            Store SKU
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default FormComp;
